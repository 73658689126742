/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Text from "../../stories/Text";
import Ratings from "../../stories/Ratings";
import { User } from "@phosphor-icons/react";
import moment from "moment";
import CustomLink from "../../stories/Link";
import { getDirectoryMemberURL } from "../../util/getURLs";
import ImageComponent from "../../stories/ImageComponent";

const RatingsCard = ({
  id,
  profileSlug,
  userImage,
  rating,
  time,
  content,
  name,
}) => {
  return (
    <div className="flex justify-between w-full space-x-2" data-review-id={id}>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-2.5">
          <CustomLink
            textColor="text-blue-600"
            activeTextColor="text-blue-600"
            showUnderLine={false}
            underLineOnlyOnHover={false}
            fontSize="sm"
            target="_blank"
            href={getDirectoryMemberURL(profileSlug)}
            className="font-normal leading-5 tracking-tight h-fit"
          >
            <ImageComponent
              src={userImage}
              alt="user"
              errorComponent={
                <div className="flex items-center justify-center min-w-[32px] w-8 h-8 bg-gray-200 rounded-full">
                  <User weight="bold" className="w-6 h-6 text-gray-400" />
                </div>
              }
              className="object-cover w-8 h-8 rounded-full min-w-[32px]"
            />
          </CustomLink>
          <div>
            <CustomLink
              textColor="text-blue-600"
              activeTextColor="text-blue-600"
              showUnderLine={false}
              underLineOnlyOnHover={false}
              fontSize="sm"
              target="_blank"
              href={getDirectoryMemberURL(profileSlug)}
              className="font-normal leading-5 tracking-tight"
            >
              <Text
                type="text"
                size="text-base md:text-lg"
                content={name}
                className="text-theme-black-500 font-semibold"
              />
            </CustomLink>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <Text
            content={content}
            type="text"
            size="text-base md:text-lg"
            className="text-theme-black-300 "
          />
          <p className="text-xs leading-none font-normal text-theme-gray-500 md:text-sm">
            {moment(time).fromNow()}
          </p>
        </div>
      </div>

      <Ratings className="text-sm" ratings={rating} />
    </div>
  );
};

Text.defaultProps = {
  className: "",
};

RatingsCard.propTypes = {
  content: PropTypes.string,
  id: PropTypes.any,
  location: PropTypes.string,
  name: PropTypes.string,
  profileSlug: PropTypes.string,
  rating: PropTypes.number,
  time: PropTypes.string,
  userImage: PropTypes.string,
};

export default RatingsCard;
