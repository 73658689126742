import PropTypes from "prop-types";
import StyledButton from "../CommonComponents/StyledButton";
import { CheckCircle, XCircle } from "@phosphor-icons/react";
import { User } from "../../store";

export const ChoosePlan = ({
  title = "",
  background,
  handleOpenSignup,
  handleOpenProMember,
}) => {
  return (
    <div
      className={`flex items-center justify-center w-full py-10 px-5 ${background} bg-gradient-to-r from-theme-orange-opacity-70 to-theme-orange-opacity-90`}
    >
      <div className="flex flex-col justify-center items-center gap-8 max-w-7xl w-full sm:px-5 md:px-10">
        <div className="flex flex-col items-center justify-center gap-2 text-white text-center px-3">
          <h3 className="font-bold text-2xl text-white md:text-4xl">
            {title?.length ? title : "Upgrade to ProMembership"}
          </h3>
          <p className="text-xl text-white md:text-xl">
            Start with a Basic Account if you are new to Network FP. And if you
            are ready for more and better, become a ProMember.
          </p>
        </div>
        <div className="flex flex-col items-start md:flex-row md:items-stretch justify-center gap-6 w-full">
          <PlanCard
            background={"linear-gradient(180deg, #F4F6FD 0%, #FFFFFF 100%)"}
            price={null}
            validity={"Validity - lifetime"}
            whatIncluded={[
              { title: "", subtitle: "Lifetime access", isIncluded: true },
              {
                title: " NFP Courses",
                subtitle: "Limited access to",
                isIncluded: true,
                isTitleLast: true,
              },
              {
                title: " Toolbox",
                subtitle: "Limited access to",
                isIncluded: true,
                isTitleLast: true,
              },
              {
                title: " LIVE Sessions",
                subtitle: "Friday",
                isIncluded: true,
                isTitleLast: true,
              },
              {
                title: "Exchange",
                subtitle: " Forum (View Only access)",
                isIncluded: true,
              },
              {
                title: "Client Connect",
                subtitle: " Resources",
                isIncluded: true,
              },
              { title: "Basic", subtitle: " PROfile Setup", isIncluded: true },
              { title: "Public Directory Listing", isIncluded: false },
              {
                title: " QPFP/Events",
                subtitle: "Discounts on",
                isIncluded: false,
                isTitleLast: true,
              },
            ]}
            isBasicAccount={true}
            handleButtonClick={handleOpenSignup}
          />
          <PlanCard
            background={"linear-gradient(180deg, #F7F4E2 0%, #FFFFFF 136.25%)"}
            price={"16,000"}
            validity={"p.a."}
            whatIncluded={[
              {
                title: "All Basic Features",
                subtitle: " included, plus:",
                isIncluded: true,
              },
              {
                title: " NFP Courses",
                subtitle: "Access to All",
                isIncluded: true,
                isTitleLast: true,
              },
              {
                title: " All Toolbox",
                subtitle: "Access to",
                isIncluded: true,
                isTitleLast: true,
              },
              {
                title: " LIVE Sessions",
                subtitle: "Wednesday",
                isIncluded: true,
                isTitleLast: true,
              },
              {
                title: "Exchange",
                subtitle: " Forum (View & Post Questions)",
                isIncluded: true,
              },
              {
                title: " Client Connect Resources",
                subtitle: "Enhanced",
                isIncluded: true,
                isTitleLast: true,
              },
              {
                title: "Complete",
                subtitle: " Profile setup",
                isIncluded: true,
              },
              { title: "Public Directory Listing", isIncluded: true },
              {
                title: " QPFP/Events",
                subtitle: "Discounts on",
                isIncluded: true,
                isTitleLast: true,
              },
            ]}
            isBasicAccount={false}
            handleButtonClick={handleOpenProMember}
          />
        </div>
      </div>
    </div>
  );
};

const PlanCard = ({
  isBasicAccount,
  background,
  validity,
  price,
  whatIncluded,
  handleButtonClick,
}) => {
  return (
    <div
      className="flex flex-col justify-between w-full max-w-[628px] rounded-xl px-6 py-7 gap-4"
      style={{
        background: background,
      }}
    >
      <div>
        {isBasicAccount ? (
          <div className="flex flex-col gap-2">
            <p className="w-fit bg-theme-green text-white py-1 px-2 text-xs font-semibold rounded-full text-theme-white">
              Basic Account
            </p>
            <p className="text-lg text-theme-black-300">
              Join 11,559 Professionals learning & growing
            </p>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <p className="w-fit bg-theme-red text-white py-1 px-2 text-xs font-semibold rounded-full text-theme-white">
              ProMember
            </p>
            <p className="text-lg text-theme-black-300">
              Join 1,300 ProMembers across 100+ cities
            </p>
          </div>
        )}
        {price ? (
          <div className="my-4 flex items-end gap-1">
            <p className="text-theme-orange font-bold text-5xl">₹{price}</p>
            <p className="text-theme-black-300">{validity}</p>
          </div>
        ) : (
          <div className="my-4 flex items-end gap-1">
            <p className="text-theme-orange font-bold text-5xl">Free</p>
            <p className="text-theme-black-300">{validity}</p>
          </div>
        )}
        {whatIncluded && (
          <div className="flex flex-col gap-5">
            {whatIncluded.map((item, index) => (
              <div
                className="flex gap-2.5  w-full max-w-[380px]"
                key={`what-include-${item}-${index}`}
              >
                {item?.isIncluded ? (
                  <CheckCircle size={24} color={"#16A34A"} />
                ) : (
                  <XCircle size={24} color={"#E10000"} />
                )}
                <p className="text-theme-black-300 text-lg">
                  {!item?.isTitleLast && (
                    <span className="font-bold">{item?.title}</span>
                  )}
                  {item?.subtitle}
                  {item?.isTitleLast && (
                    <span className="font-bold">{item?.title}</span>
                  )}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      {isBasicAccount ? (
        User.getToken() ? (
          <div className="w-full flex justify-center px-1.5 py-1 rounded-full bg-theme-orange lg:w-1/2 lg:mx-auto">
            <p className="font-bold text-xl text-white">Current Plan</p>
          </div>
        ) : (
          <StyledButton
            title={"Join for free"}
            buttonStyle="outline-primary"
            handleClick={handleButtonClick}
          />
        )
      ) : (
        <StyledButton
          title={"Become a ProMember"}
          buttonStyle="primary"
          handleClick={handleButtonClick}
        />
      )}
    </div>
  );
};

PlanCard.propTypes = {
  background: PropTypes.string,
  price: PropTypes.string,
  validity: PropTypes.string,
  whatIncluded: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      isIncluded: PropTypes.bool,
    })
  ).isRequired,
  isBasicAccount: PropTypes.bool,
  handleButtonClick: PropTypes.func,
};
ChoosePlan.propTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  handleOpenSignup: PropTypes.func,
  handleOpenProMember: PropTypes.func,
};

export default ChoosePlan;
