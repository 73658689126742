import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import { Dialog, Transition } from "@headlessui/react";
import { Copy, X } from "@phosphor-icons/react";

import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";

import promember_logo from "../../assets/images/promember_logo.png";
import discount_icon from "../../assets/svgs/discount-icon-red.svg";
import { PopupType, useLoginModal } from "../../context/loginModalProvider";

const Feature = ({ text }) => {
  return (
    <div className="flex space-x-2">
      <div className="min-w-2 h-2 bg-theme-black-color/40 rounded-full mt-2" />
      <p>{text}</p>
    </div>
  );
};

Feature.propTypes = {
  text: PropTypes.string.isRequired,
};

const ProMemberRegisterModal = ({ open, onClose }) => {
  const { openProMemberModal, handleOpenPopup } = useLoginModal();
  const features = [
    "10+ NFP Courses",
    "20+ Toolbox",
    "100+ Client Connect Resources",
    "Post & View Questions on Exchange Forum",
    "LIVE Wednesday Sessions by Industry Experts",
    "Craft your Professional PROfile",
    "List yourself on Public Directory",
    "Exclusive Discounts on QPFP/ Events",
  ];
  return (
    <React.Fragment>
      <Transition.Root show={open ? open : openProMemberModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open ? open : openProMemberModal}
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block w-full p-4 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded-3xl shadow-xl sm:my-3 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="flex flex-col w-full">
                <div
                  className="mb-7 p-3 flex flex-col items-center rounded-2xl relative"
                  style={{
                    background:
                      "linear-gradient(96.04deg, #FF3632 10.73%, #FF8D4E 93.75%)",
                  }}
                >
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0 absolute right-3 top-3"
                    onClick={() => handleOpenPopup(PopupType.ALL)}
                  >
                    <span className="sr-only">Close</span>
                    <X className="w-6 h-6" aria-hidden="true" />
                  </button>

                  <img
                    src={promember_logo}
                    className="w-36 h-11 mb-3"
                    alt="promember"
                  />
                  <h3 className="font-semibold text-4xl text-white">
                    Become a ProMember
                  </h3>
                  <p className="mt-3 text-lg text-white text-center">
                    Register as a NFP ProMember to access full range of courses,
                    tools, resources, advanced profile, complete forum and
                    listing on Public Directory.
                  </p>
                  <p className="mt-5 font-semibold text-2xl text-white">
                    <span className="font-normal text-lg italic">at</span>{" "}
                    ₹16,000 p.a.
                  </p>
                  <p className="text-lg italic text-white">
                    Join 1,300 ProMembers from 100+ Cities
                  </p>
                </div>

                <p className="font-bold text-lg">What&apos;s included?</p>

                <div className="mt-4 grid grid-cols-1 justify-between gap-y-3 md:grid-cols-2">
                  {features?.map((feature, index) => (
                    <Feature
                      key={`pro-member-feature-${index}`}
                      text={feature}
                    />
                  ))}
                </div>

                <div className="mt-6 px-3 py-2 flex justify-between items-center bg-[#f5d8d7] rounded-xl">
                  <div className="h-full flex space-x-4 items-center">
                    <img
                      src={discount_icon}
                      className="w-7 h-7"
                      alt="Discount coupon"
                    />

                    <div className="border-r-[#d52b27]/40 border-r-2 border-dashed h-10" />

                    <div className="flex flex-col space-y-px">
                      <p className="font-bold text-xl text-[#d52b27]">
                        NFP2800NEW
                      </p>
                      <p className="text-sm text-theme-black-color">
                        Copy this code to get ₹2800 off
                      </p>
                    </div>
                  </div>

                  <div
                    className="px-3 py-2 flex items-center space-x-2 rounded-full bg-theme-black-color cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText("NFP2800NEW");
                      CustomToast.success("Copied to clipboard", {
                        duration: 30000,
                      });
                    }}
                  >
                    <Copy size={15} className="text-white" />
                    <p className="font-semibold text-sm text-white">Copy</p>
                  </div>
                </div>

                <Button
                  onClick={() => navigate("/members/pro_member/register")}
                  buttonStyle="primary"
                  size="xl"
                  width="100%"
                  className="mt-6"
                >
                  Register Now
                </Button>

                <Button
                  onClick={() => {
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href =
                      "https://nfp-assets.s3.ap-south-1.amazonaws.com/Pro+Membership+Brochure_2024.pdf";
                    a.target = "_blank";
                    document.body.appendChild(a);
                    a.click();
                  }}
                  buttonStyle="transparent-textonly-secondary"
                  size="xl"
                  width="100%"
                  className="mt-3"
                >
                  Download Brochure
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

ProMemberRegisterModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProMemberRegisterModal;
