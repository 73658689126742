import PropTypes from "prop-types";
import RelationManagerDetails from "./RelationManagerDetails";
import { Envelope, Phone, WhatsappLogo } from "@phosphor-icons/react";
import { useProfile } from "../../context/userProvider";

const YourRelationManager = ({
  name,
  email,
  phone,
  whatsAppLink,
  profileImg,
}) => {
  const { userDetails } = useProfile();
  return (
    <div className="w-full max-w-[623px] border border-theme-gray-75 rounded-[10px] bg-white">
      <div className="flex items-center justify-between px-4 sm:px-6 py-4 border-b border-theme-gray-75">
        <p className="">
          {userDetails?.is_pro_member
            ? "Your Relationship Manager"
            : "Contact for Registration Assistance"}
        </p>
      </div>
      <div className="flex flex-col gap-4 py-4 px-4 sm:px-6">
        <RelationManagerDetails profileImg={profileImg} name={name} />
        <ContactDetails
          icon={<Envelope weight="bold" color="#525252" size={24} />}
          value={email}
        />
        <ContactDetails
          icon={<Phone weight="bold" color="#525252" size={24} />}
          value={phone}
        />
        <div className="flex items-center justify-start gap-3">
          <WhatsappLogo weight="fill" color="#16A34A" size={24} />
          <a
            href={whatsAppLink}
            className="sm:text-xl text-theme-green font-medium"
          >
            WhatsApp
          </a>
        </div>
        <p className="text-xs sm:text-sm text-theme-black-300">
          Working Hours: Monday to Friday | 10 AM to 6PM
        </p>
      </div>
    </div>
  );
};

const ContactDetails = ({ icon, value }) => {
  return (
    <div className="flex items-center justify-start gap-3">
      {icon}
      <p className="sm:text-xl text-theme-black-300"> {value}</p>
    </div>
  );
};

ContactDetails.propTypes = {
  icon: PropTypes.any,
  value: PropTypes.string,
};

YourRelationManager.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  whatsAppLink: PropTypes.string,
  profileImg: PropTypes.string,
};

export default YourRelationManager;
