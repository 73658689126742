import { useEffect, useState } from "react";
import Breadcrumb from "../../stories/Breadcrumb";
import SearchBar from "../../stories/SearchBar";
import ProtoolsAndResourcesTable from "../CommonComponents/UpdatedTable";
import { ProToolTableColumns } from "../../Constants";
import Loader from "../Shimmer/Loader";
import NoDataFound from "../CommonComponents/NoDataFound";
import useProtoolsAndResources from "../../hooks/useProtoolsAndResources";
import AppSkeleton from "../AppSkeleton";

const ProToolsAndResources = () => {
  const entriesPerPage = 20;
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPageNumber] = useState(1);

  const breadCrumbData = [
    { name: "Dashboard", to: "/" },
    {
      name: "NFP Toolbox",
      to: "/toolbox",
      current: true,
    },
  ];

  const { loading, error, posts, pagination } = useProtoolsAndResources(
    "post_type[]=protool&post_type[]=resource",
    page,
    searchQuery,
    entriesPerPage
  );
  useEffect(() => {
    if (loading) {
      return (
        <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <Loader />
        </div>
      );
    }
  }, [loading]);
  return (
    <AppSkeleton openLoginModal={true}>
      <div>
        <div
          className={`flex justify-center w-full py-10 px-5 bg-theme-gray-25 min-h-screen`}
        >
          <div className="flex flex-col gap-5 max-w-7xl w-full">
            <div className="flex flex-col gap-1.5 justify-center h-fit w-full">
              <p className="text-2xl font-bold">NFP Toolbox</p>
              <Breadcrumb pages={breadCrumbData} type="chevron" />
            </div>
            <div className="flex items-center justify-end w-full">
              <div className="flex items-center justify-end w-full md:max-w-lg rounded-md overflow-hidden">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={"Search by name"}
                  searchTerm={searchQuery}
                  onSearch={(val) => setSearchQuery(val)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 text-theme-black-500">
              <p className="text-xl font-medium">Toolbox</p>
              <p className="text-sm">
                Learn, download and use these NFP ProTools and Resources which
                will help you in practice management and client engagement
              </p>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <p className="hidden 2lg:block text-xs font-medium text-theme-black-500">
                These tools are available only to QPFP current batch candidates
                & past certificants*
              </p>
              {posts?.length === 0 || error ? (
                <NoDataFound subTitle={""} />
              ) : (
                <ProtoolsAndResourcesTable
                  columns={ProToolTableColumns}
                  isLoading={loading}
                  totalEntries={pagination?.total_count}
                  onPageChange={(page) => {
                    setPageNumber(page);
                  }}
                  data={posts?.sort((a, b) => {
                    return a?.pro_member_visible === b?.pro_member_visible
                      ? 0
                      : b?.pro_member_visible
                      ? -1
                      : 1;
                  })}
                  entriesPerPage={entriesPerPage}
                  currentPage={page}
                  headerTitle={"NFP Toolbox"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};

export default ProToolsAndResources;
