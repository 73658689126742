import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactSlickSettings } from "../../Constants";

const ResponsiveCardEncloser = ({ children }) => {
  return <Slider {...ReactSlickSettings}>{children}</Slider>;
};

ResponsiveCardEncloser.propTypes = {
  children: PropTypes.any,
};

export default ResponsiveCardEncloser;
