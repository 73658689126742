import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import _ from "lodash";
import { useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "@phosphor-icons/react/dist/ssr";

import Button from "../../stories/Button";
import CustomLink from "../../stories/Link";
import AuthTextInput from "../../stories/AuthTextInput";
import { Validations } from "../../constants/validations";
import { useProfile } from "../../context/userProvider";
import { AuthService } from "../../services/AuthService";
import { CustomToast } from "../../stories/Toast";
import { User } from "../../store";
import AuthDropdown from "../../stories/AuthDropdown";
import FirmInviteRequestModal from "./FirmInviteRequestModal";
import { useLocationList } from "../../services/BasicDetails";
import OTPField from "../../stories/OTPField";
import ShowFormError from "../../stories/ShowFormError";
import { PopupType, useLoginModal } from "../../context/loginModalProvider";

const Form1 = ({ email, setEmail, setOtpSent, handleOpenPopup }) => {
  const navigate = useNavigate();
  const { locations, loading: loadingLocation } = useLocationList();
  const [showFirmInviteModal, setShowFirmInviteModal] = useState(false);
  const [firmData, setFirmData] = useState({});
  const { updateUserData } = useProfile();

  const initialValues = {
    fname: "",
    lname: "",
    email: email ?? "",
    password: "",
    mobileno: "",
    city: "",
  };

  const handleSignUp = async (data) => {
    try {
      const { fname, lname, mobileno, city, email, password } = data;
      const result = await AuthService.signup({
        first_name: fname,
        last_name: lname,
        phone_number: mobileno,
        city_id: city,
        email: email,
        password: password,
      });
      mixpanel.people.set({
        distinct_id: email,
        $email: email,
        $name: `${fname} ${lname}`,
        $city: locations.find((location) => location.id === city).name,
        "Phone number": mobileno,
      });
      mixpanel.identify(email);
      mixpanel.track("Sign Up", {
        distinct_id: email,
        $email: email,
        $name: `${fname} ${lname}`,
        $city: locations.find((location) => location.id === city).name,
        "Phone number": mobileno,
      });
      if (result.token) {
        User.setToken(result.token);
        const decodedData = JSON.parse(window.atob(result.token.split(".")[1]));
        window.fcWidget?.init({
          token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
          host: "https://wchat.in.freshchat.com",
          externalId: decodedData?.data?.unique_id ?? null,
        });
        updateUserData(true).then((response) => {
          mixpanel.people.set({
            distinct_id: email,
            $email: response["email"],
            $name: `${response["first_name"]} ${response["last_name"]}`,
            $city: locations.find((location) => location.id === city).name,
            "User ID": response["id"],
            "Phone number": response["phone_number"],
          });
        });
        if (result?.firm_user?.firm_user_id) {
          setFirmData({
            firm_id: result?.firm_user?.firm_user_id,
            firm_name: result?.firm_user?.firm_name,
          });
          setShowFirmInviteModal(true);
        } else {
          setTimeout(() => navigate("/"), 1000);
          CustomToast.success(result.message);
        }
        return;
      }
      if (result.message) {
        CustomToast.success(result.message);
        setEmail(email);
        setOtpSent(true);
      }
    } catch (e) {
      if (e.error_code === "duplicate_email") {
        CustomToast.error(e.error_messages[0]);
        handleOpenPopup(PopupType.LOGIN);
      } else {
        CustomToast.error(e.error_messages[0] ?? "Something went wrong");
      }
    }
  };

  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div className="inline-block w-full p-6 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded-2xl shadow-xl sm:my-2 sm:align-middle sm:max-w-lg sm:w-full sm:p-4">
        <div className="flex flex-col w-full">
          <div
            className="p-3 flex flex-col items-start space-y-1 rounded-xl"
            style={{
              background:
                "linear-gradient(96.04deg, #FF3632 10.73%, #FF8D4E 93.75%)",
            }}
          >
            <div className="self-stretch flex justify-between items-start">
              <div className="p-2 bg-theme-green rounded-full">
                <p className="font-semibold text-xs text-white">
                  Basic Account
                </p>
              </div>

              <button
                type="button"
                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                onClick={() => handleOpenPopup(PopupType.ALL)}
              >
                <span className="sr-only">Close</span>
                <X className="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
            <h3 className="font-bold text-4xl text-white">Join for free</h3>
            <p className="text-lg text-white">
              Set-up your NFP Basic Account for free to attend courses, download
              tools & resources, create your profile and access other benefits.
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={() =>
              Validations.onBoarding.signupValidationSchema
            }
            onSubmit={handleSignUp}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col w-full gap-2 mt-2.5">
                <AuthTextInput
                  name="fname"
                  id="fname"
                  type="text"
                  label="First Name"
                />
                <AuthTextInput
                  name="lname"
                  id="lname"
                  type="text"
                  label="Last Name"
                />
                <AuthTextInput
                  name="email"
                  id="email"
                  type="text"
                  label="Email"
                  autoComplete="email"
                />
                <AuthTextInput
                  name="password"
                  id="password"
                  type="password"
                  label="Password"
                  autoComplete="new-password"
                />
                <AuthTextInput
                  showLeading
                  name="mobileno"
                  id="mobileno"
                  type="text"
                  label="Mobile No."
                  leadingComponent={
                    <span className="inline-flex items-center px-4 mt-1 text-base text-gray-500 border border-r-0 border-gray-300 bg-gray-50 lg:text-lg">
                      +91
                    </span>
                  }
                />
                <AuthDropdown
                  name="city"
                  id="city"
                  type="text"
                  placeholder="City"
                  options={locations}
                  displayCurrentOption
                  displayKey="name"
                  idKey="id"
                />
                <div className="flex flex-col mt-2 gap-2 w-full">
                  <div className="text-sm">
                    By clicking Sign Up, you agree to our{" "}
                    <a
                      href="https://networkfp.com/terms-of-use/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-theme-black-color hover:underline"
                      alt="Terms and Conditions"
                    >
                      Terms and Conditions
                    </a>
                    &nbsp;and that you&apos;ve read our{" "}
                    <a
                      href="https://networkfp.com/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-theme-black-color hover:underline"
                      alt="Privacy Policy"
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>
                  <Button
                    type="submit"
                    buttonStyle="primary"
                    height="60px"
                    width="100%"
                    loading={loadingLocation || isSubmitting}
                    disabled={loadingLocation || isSubmitting}
                  >
                    SIGN UP
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="flex items-center justify-start w-full mt-3 text-sm leading-5">
            Already have an account? &nbsp;
            <CustomLink
              onClick={() => handleOpenPopup(PopupType.LOGIN)}
              textColor="text-theme-red"
              activeTextColor="text-theme-red"
              fontSize="md"
              showUnderLine={true}
              className="leading-5"
            >
              Login here
            </CustomLink>
          </div>
          {showFirmInviteModal && (
            <FirmInviteRequestModal
              open={showFirmInviteModal}
              onClose={() => {
                setShowFirmInviteModal(false);
                setFirmData({});
                CustomToast.success("Successfully logged in");
                navigate("/");
              }}
              firmDetails={firmData}
            />
          )}
        </div>
      </div>
    </Transition.Child>
  );
};

Form1.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
  setOtpSent: PropTypes.func,
  handleOpenPopup: PropTypes.func,
};

const Form2 = ({ email, handleOpenPopup }) => {
  const { updateUserData } = useProfile();
  const navigate = useNavigate();
  if (_.isEmpty(email)) {
    handleOpenPopup(PopupType.LOGIN);
  }
  const [seconds, setSeconds] = useState(0);

  const timeOutCallback = useCallback(
    () => setSeconds((currTimer) => currTimer - 1),
    []
  );
  const resetTimer = function () {
    if (!seconds) {
      setSeconds(120);
    }
  };
  useEffect(() => {
    seconds > 0 && setTimeout(timeOutCallback, 1000);
  }, [seconds, timeOutCallback]);

  const sendEmailVerificationLink = async (e) => {
    e.preventDefault();
    try {
      const result = await AuthService.sendEmailVerificationLink({ email });
      if (result.message) {
        resetTimer();
        CustomToast.success(result.message);
      } else {
        CustomToast.error(result.errors);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div className="inline-block w-full p-8 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded-3xl shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
        <Dialog.Title
          as="h3"
          className="flex justify-between text-5xl font-bold tracking-tight text-theme-black-color"
        >
          Email Verification
        </Dialog.Title>
        <div className="flex flex-col w-full">
          <div className="block mt-6 text-lg font-light leading-5 text-theme-black-300">
            Enter OTP sent to &nbsp;
            <b>
              *****
              {email
                ?.split("@")[0]
                ?.substring(email?.split("@")[0]?.length - 3)}
              @{email?.split("@")[1]}
            </b>
            .
          </div>
          <Formik
            initialValues={{ otp: Array(6).fill("") }}
            validationSchema={Validations.onBoarding.otpVerification}
            onSubmit={async (values, { setFieldError }) => {
              try {
                const otp = values.otp.join("");
                const result = await AuthService.verifyEmail(email, otp);
                if (result.message) {
                  User.setToken(result.token);
                  updateUserData(true).then((response) => {
                    mixpanel.people.set({
                      distinct_id: response["email"],
                      $email: response["email"],
                      $name: `${response["first_name"]} ${response["last_name"]}`,
                      $city: response["city"],
                      "User ID": response["id"],
                      "Phone number": response["phone_number"],
                      "Last Login": moment().format("YYYY-MM-DDTHH:MM:SS"),
                    });
                    mixpanel.identify(response["email"]);
                    mixpanel.track("Verify Email", {
                      distinct_id: response["email"],
                    });
                  });
                  CustomToast.success(result.message);
                  navigate("visitor/thank-you", { replace: true });
                } else {
                  CustomToast.error(result.errors);
                }
              } catch (e) {
                if (e.error_code === "invalid_otp") {
                  setFieldError("otp", "Invalid otp");
                  return;
                }
                if (e.error_code === "already_verified" && e.payload) {
                  User.setToken(e.payload.token);
                  updateUserData(true).then((response) => {
                    mixpanel.people.set({
                      distinct_id: response["email"],
                      $email: response["email"],
                      $name: `${response["first_name"]} ${response["last_name"]}`,
                      $city: response["city"],
                      "User ID": response["id"],
                      "Phone number": response["phone_number"],
                      "Last Login": moment().format("YYYY-MM-DDTHH:MM:SS"),
                    });
                    mixpanel.identify(response["email"]);
                    mixpanel.track("Verify Email", {
                      distinct_id: response["email"],
                    });
                  });
                  if (e.payload.src) {
                    const decodedData = JSON.parse(
                      window.atob(e.payload.src.split(".")[1])
                    );
                    if (decodedData.entity_type === "event") {
                      setTimeout(
                        () =>
                          navigate(`/events/${decodedData.entity_id}/register`),
                        1000
                      );
                    } else {
                      setTimeout(() => navigate("/"), 1000);
                    }
                  } else {
                    setTimeout(() => navigate("/"), 1000);
                  }
                  CustomToast.error(e.error_messages[0]);
                } else {
                  CustomToast.error(e.error_messages[0]);
                  // setTimeout(() => navigate("/login"), 1000);
                }
              }
            }}
          >
            <Form className="flex flex-col items-start w-full mt-4">
              <div className="flex flex-row items-center justify-center w-full gap-2">
                <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                  <OTPField first={true} last={false} index={0} />
                </div>
                <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                  <OTPField first={false} last={false} index={1} />
                </div>
                <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                  <OTPField first={false} last={false} index={2} />
                </div>
                <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                  <OTPField first={false} last={false} index={3} />
                </div>
                <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                  <OTPField first={false} last={false} index={4} />
                </div>
                <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                  <OTPField first={false} last={true} index={5} />
                </div>
              </div>
              <ShowFormError name="otp" />
              <div className="flex justify-end w-full mt-3">
                <Button
                  disabled={seconds > 0}
                  buttonStyle="transparent-textonly-secondary"
                  onClick={(e) => sendEmailVerificationLink(e)}
                >
                  {seconds === 0 ? (
                    <p className="font-normal text-gray-500">
                      Didn&apos;t recieve OTP?{" "}
                      <span className="text-theme-red-300">Resend</span>
                    </p>
                  ) : (
                    <p className="font-light text-gray-500">
                      {_.toInteger(seconds / 60) < 10
                        ? `0${_.toInteger(seconds / 60)}`
                        : _.toInteger(seconds / 60)}{" "}
                      : {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}{" "}
                      <span className="text-theme-red-300/30">Resend</span>
                    </p>
                  )}
                </Button>
              </div>
              <Button
                type="submit"
                buttonStyle="primary"
                height="60px"
                width="100%"
                className="mt-6"
                // loading={loadingLocation || isSubmitting}
                // disabled={loadingLocation || isSubmitting}
              >
                VERIFY EMAIL
              </Button>
            </Form>
          </Formik>
        </div>
      </div>
    </Transition.Child>
  );
};

Form2.propTypes = {
  email: PropTypes.string,
  handleOpenPopup: PropTypes.func,
};

const SignupWithOTP = ({
  email,
  setEmail,
  otpSent,
  setOtpSent,
  handleOpenPopup,
}) => {
  return (
    <>
      {!otpSent ? (
        <Form1
          email={email}
          setEmail={setEmail}
          setOtpSent={setOtpSent}
          handleOpenPopup={handleOpenPopup}
        />
      ) : (
        <Form2 email={email} handleOpenPopup={handleOpenPopup} />
      )}
    </>
  );
};

SignupWithOTP.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
  otpSent: PropTypes.bool,
  setOtpSent: PropTypes.func,
  handleOpenPopup: PropTypes.func,
};

const SignupUserModal = ({ open, onClose, prefill_data }) => {
  const { openSignupPopup, handleOpenPopup } = useLoginModal();

  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState(prefill_data.email);

  return (
    <React.Fragment>
      <Transition.Root show={open ? open : openSignupPopup} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open ? open : openSignupPopup}
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <SignupWithOTP
              email={email}
              setEmail={setEmail}
              otpSent={otpSent}
              setOtpSent={setOtpSent}
              handleOpenPopup={handleOpenPopup}
            />
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

SignupUserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  prefill_data: PropTypes.object.isRequired,
};

export default SignupUserModal;
