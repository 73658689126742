/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCurrentUserPublicProfile,
  useDashboardCoursesData,
  useLiveCourseData,
  useProfileManager,
} from "../../services/BasicDetails";
import NavBar from "../CommonComponents/Navbar";
import ProToolSection from "../CommonComponents/ProToolSection";
import HeroSection from "../Header/HeroSection";
import SideNavMenu from "../Header/SideNavMenu";
import ChoosePlan from "./ChoosePlan";
import ClientConnectSection from "./ClientConnectSection";
import CommunityAndRelations from "./CommunityAndRelation";
import CoursesSection from "./CoursesSection";
import LandScapeCoursesCard from "./LandScapeCoursesCardSection";
import usePosts from "../../hooks/usePost";
import { useProfile } from "../../context/userProvider";
import {
  useAllCourses,
  useCoursesComingSoon,
  useMyCourses,
} from "../../services/CourseDetails";
import useProtoolsAndResources from "../../hooks/useProtoolsAndResources";
import QPFPSection from "../CommonComponents/QPFPBanner";
import { useTourContext } from "../../context/tourProvider";
import QueryString from "qs";
import { useEffect, useState } from "react";
import { tourSteps } from "../../util/Tour";
import RenewalBar from "../../stories/RenewalBar";
import QPFPCertificateRenewalSection from "../../stories/QPFPCertificateRenewalSection";
import CorporateSection from "../Dashboard/CorporateSection";
import AdditionalDetailsModal from "../Modals/AdditionalDetailsModal";
import Loader from "../Shimmer/Loader";
import ProMemberRegisterModal from "../Modals/ProMemberRegisterModal";
import { navigate, useLocation } from "@reach/router";
import { PopupType, useLoginModal } from "../../context/loginModalProvider";
import ProUpgradeCard from "../../stories/ProUpgradeCard";
import StyledButton from "../CommonComponents/StyledButton";
import Sidebar from "../../stories/Sidebar";
import {
  ArrowsCounterClockwise,
  CalendarBlank,
  Certificate,
  Desktop,
  Files,
  Folders,
  Users,
} from "@phosphor-icons/react";
import ApplicationStatus from "../CommonComponents/ApplicationStatus";
import RegisteredNationalEventSection from "../Dashboard/RegisteredNationalEvent";

const Dashboard = () => {
  const { handleOpenPopup } = useLoginModal();
  const { userDetails, isProfileLoading } = useProfile();
  const { setState, state } = useTourContext();
  const location = useLocation();
  const [defaultState, setDefaultState] = useState("");
  const sidebarList = [
    {
      id: "dashboard",
      label: "Dashboard",
      normalIcon: <Desktop className="menu-icon" />,
      activeIcon: <Desktop className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/",
    },
    {
      id: "my_programs",
      label: "My Courses",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/programs",
    },
    {
      id: "qpfp_certificant",
      label: "My QPFP Certification",
      normalIcon: <Certificate className="menu-icon" />,
      activeIcon: <Certificate className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/qpfp-certificant/dashboard",
      hide: userDetails?.qpfp_certification_status === "inactive",
    },
    {
      id: "all_programs",
      label: "All Courses",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/all-courses",
    },
    {
      id: "nfp_toolbox",
      label: "NFP Toolbox",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/toolbox",
    },
    {
      id: "nfp-calendar",
      label: "My Calendar",
      normalIcon: <CalendarBlank className="menu-icon" />,
      activeIcon: <CalendarBlank className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/nfp-calendar",
    },
    {
      id: "payments",
      label: "My Payments",
      normalIcon: <Files className="menu-icon" />,
      activeIcon: <Files className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/payments",
    },
    {
      id: "public_profile",
      label: "Public Profile",
      normalIcon: <Users className="menu-icon" />,
      activeIcon: <Users className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/public-profile",
    },
    {
      id: "exchange-forum",
      label: "Exchange forum",
      normalIcon: (
        <ArrowsCounterClockwise className="menu-icon" weight="fill" />
      ),
      activeIcon: (
        <ArrowsCounterClockwise className="menu-icon" weight="fill" />
      ),
      dropdownList: [],
      path: "/exchange",
    },
  ];
  useEffect(() => {
    sidebarList.forEach((item) => {
      if (item.dropdownList?.length === 0) {
        if (item.path === window.location.pathname) {
          setDefaultState(item.id);
        }
      } else {
        item.dropdownList.forEach((e, i) => {
          if (e.path === window.location.pathname) {
            setDefaultState(e.id);
          }
        });
      }
    });
  }, [window?.location?.pathname]);

  const parsed = QueryString.parse(
    location.search[0] === "?" ? location.search.substring(1) : location.search
  );
  const [showAdditionalDetailsModal, setShowAdditionalDetailsModal] =
    useState(false);
  const {
    publicProfileData,
    // mutate: mutatePublicProfile,
  } = useCurrentUserPublicProfile({
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });

  const handleOpenProMember = () => {
    handleOpenPopup(PopupType.PROMEMBER);
  };

  useEffect(() => {
    if (
      !(publicProfileData?.is_profile_completed ?? true) &&
      parsed.guide_user !== "true"
    ) {
      setShowAdditionalDetailsModal(true);
    } else {
      setShowAdditionalDetailsModal(false);
    }
  }, [publicProfileData, state, location.search]);

  const { dashboardCoursesData } = useDashboardCoursesData(true, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });

  useEffect(() => {
    if (parsed.guide_user === "true" || state.tourActive === true) {
      setState((prev) => ({
        ...prev,
        run: true,
        tourActive: true,
        steps: tourSteps,
      }));
    }
  }, [location.search]);

  const { data: liveCourseData } = useLiveCourseData(
    userDetails?.is_pro_member
      ? "?course_type[]=pro_member_series&course_type[]=free_training"
      : "?course_type[]=free_training",
    { revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false } }
  );
  const {
    loading: allCourseLoading,
    error: allCourseError,
    courses: allCourseData,
  } = useAllCourses(
    {
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    },
    {},
    1,
    4
  );
  const {
    loading: proToolsLoading,
    error: proToolsError,
    posts: proToolsData,
  } = useProtoolsAndResources(
    "post_type[]=protool&post_type[]=resource",
    1,
    "",
    4
  );
  const {
    loading: clientConnectLoading,
    error: clientConnectError,
    posts: clientConnectData,
  } = usePosts("client_connect", "", 1, "", 4);
  const {
    loading: courseLoading,
    error: courseError,
    courses: courseData,
  } = useMyCourses({}, 1, 4);
  const {
    loading: courseComingSoonLoading,
    error: courseComingSoonError,
    courses: courseComingSoonData,
  } = useCoursesComingSoon({}, 1, 4);
  const { loading: profileManagerLoading, data: profileManagerData } =
    useProfileManager();

  if (isProfileLoading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <div className="relative flex flex-col w-full nfp-page-container">
      <NavBar
        mobileSideBarComponent={
          <Sidebar
            className={`fixed inset-0 z-50 flex lg:hidden bg-white transition-transform duration-300 ${"translate-x-0"}`}
            showRegisterCard={true}
            isLogoClickable={true}
            sidebarItems={sidebarList}
            onMenuClick={async (item) => {
              await navigate(item.path);
              setDefaultState(item.id);
              // setIsSidebarOpen(false); // Close sidebar on navigation
            }}
            infoBottomCard={
              userDetails?.side_bar_promotion_cta && (
                <ProUpgradeCard
                  className="bg-theme-gray-200"
                  heading={userDetails?.side_bar_promotion_cta?.heading}
                  description={userDetails?.side_bar_promotion_cta?.description}
                  cta={
                    <StyledButton
                      title={userDetails?.side_bar_promotion_cta?.cta_label}
                      handleClick={() => {
                        navigate(userDetails?.side_bar_promotion_cta?.cta_link);
                        // setIsSidebarOpen(false); // Close sidebar
                      }}
                    />
                  }
                  content_label={userDetails?.side_bar_promotion_cta?.title}
                  benefits={userDetails?.side_bar_promotion_cta?.content?.split(
                    "\n"
                  )}
                />
              )
            }
            defaultState={defaultState}
          />
        }
      />
      <div className="hidden h-max lg:flex flex-col">
        <SideNavMenu
          infoBottomCard={
            userDetails?.side_bar_promotion_cta && (
              <ProUpgradeCard
                className="bg-theme-gray-200"
                heading={userDetails?.side_bar_promotion_cta?.heading}
                description={userDetails?.side_bar_promotion_cta?.description}
                cta={
                  <StyledButton
                    title={userDetails?.side_bar_promotion_cta?.cta_label}
                    handleClick={() => {
                      navigate(userDetails?.side_bar_promotion_cta?.cta_link);
                    }}
                  />
                }
                content_label={userDetails?.side_bar_promotion_cta?.title}
                benefits={userDetails?.side_bar_promotion_cta?.content?.split(
                  "\n"
                )}
              />
            )
          }
        />
      </div>
      <div className="">
        <HeroSection />
        {userDetails?.has_national_event_registered?.has_registered &&
          userDetails?.has_national_event_registered?.is_paid && (
            <RegisteredNationalEventSection
              onClick={() => {
                window.open(
                  `/events/${userDetails?.has_national_event_registered?.national_event_id}/register`,
                  "_blank"
                );
              }}
            />
          )}
        <RenewalBar
          entity_type={"pro_member"}
          status={userDetails?.pm_signup_status}
        />
        <RenewalBar
          entity_type={"qpfp"}
          status={userDetails?.qpfp_signup_status}
        />
        <RenewalBar
          entity_type={"provisional_member"}
          status={userDetails?.provisonal_member_signup_status}
        />
        <QPFPCertificateRenewalSection
          status={userDetails?.qpfp_certification_status}
        />
        {dashboardCoursesData.pro_member?.registered &&
          !["approved"].includes(dashboardCoursesData.pro_member?.status) && (
            <ApplicationStatus
              data={{
                ...dashboardCoursesData.pro_member,
                course_name: "ProMembership Program",
              }}
              entity_type="pro_member"
              reason={dashboardCoursesData.pro_member.reason}
              program_title="ProMembership Program"
              program_description="Network FP's ProMember ACE (Annual Continuous Education) is a highly structured & a very practical education program for financial advisors in India to help them grow & succeed. Currently 1,000+ members in 100+ cities are benefiting from the program!"
            />
          )}
        {/* Provisional member status section */}
        {dashboardCoursesData.provisional_member?.registered &&
          ((!dashboardCoursesData.provisional_member?.course?.has_started &&
            dashboardCoursesData.provisional_member.status === "approved") ||
            dashboardCoursesData.provisional_member.status !== "approved") && (
            <ApplicationStatus
              data={dashboardCoursesData.provisional_member}
              entity_type="community_member"
              reason={dashboardCoursesData.provisional_member.reason}
              program_title="Network FP Community Membership"
              program_description="Take the first step to be part of Network FP by joining our Community Membership. Learn directly from practitioners each week as they talk about their practices and help you avoid the mistakes they have committed. You also get access to the NFP Exchange which is by the community for the community - Ask questions, exchange ideas and be part of an dynamic and driven set of personal finance professionals."
            />
          )}
        {/* QPFP membership status section */}
        {dashboardCoursesData.qpfp?.registered_course?.map((qpfp, index) => {
          if (
            qpfp?.registered &&
            ((!qpfp?.course?.has_started && qpfp.status === "approved") ||
              qpfp.status !== "approved")
          ) {
            return (
              <ApplicationStatus
                key={index}
                data={qpfp}
                reason={qpfp.reason}
                entity_type="qpfp"
                program_title="QPFP Certification"
                program_description="QPFP Certification is a highly practical foundation program for existing and aspiring personal finance professionals. Candidates completing the rigorous program over 6 months are awarded with prestigious QPFP Certification and designation of - Qualified Personal Finance Professional (QPFP)."
              />
            );
          }
          return null;
        })}
        <CorporateSection status={userDetails?.firm_signup_status} />
        {liveCourseData.length > 0 && (
          <LandScapeCoursesCard
            courseId={liveCourseData[0]?.id}
            title={"Latest Courses"}
            subTitle={"Explore our newly released courses"}
            background={"bg-theme-off-white-100"}
            liveCourses={liveCourseData}
          />
        )}
        {courseError || courseLoading || courseData.length === 0 ? null : (
          <CoursesSection
            title={"My Courses and Progress"}
            subTitle={
              "Please click on the “Start Course” button to unlock and begin the course."
            }
            background={"bg-theme-gray-25"}
            courses={courseData}
            navigationLink={"my-programs"}
            myCourseSection={true}
            id="my-programs"
          />
        )}
        {allCourseError ||
        allCourseLoading ||
        allCourseData.length === 0 ? null : (
          <CoursesSection
            title={"All courses"}
            subTitle={
              "Explore a diverse range of courses designed to empower your learning and growth"
            }
            courses={allCourseData?.filter(
              (course) => course?.application_status !== "under_review"
            )}
            navigationLink={"all-courses"}
            id={"all-programs"}
          />
        )}
        {courseComingSoonError ||
        courseComingSoonLoading ||
        courseComingSoonData?.length === 0 ? null : (
          <CoursesSection
            title={"Courses coming soon"}
            subTitle={"For new learning and growing opportunities"}
            background={"bg-theme-gray-300"}
            courses={courseComingSoonData}
            isComingSoon={true}
          />
        )}
        {proToolsError ||
        proToolsLoading ||
        proToolsData?.length === 0 ? null : (
          <ProToolSection
            title={"NFP Toolbox"}
            subTitle={
              "Download & Access ready-to-use ProTools (Excel Templates),  Resources, and calculators to simplify and elevate your practice."
            }
            className={"px-5"}
            tools={proToolsData}
          />
        )}
        {clientConnectError ||
        clientConnectLoading ||
        clientConnectData?.length === 0 ? null : (
          <ClientConnectSection
            title={"NFP Client Connect "}
            subTitle={
              "Download & Access shareable content to educate your clients effectively"
            }
            clientConnect={clientConnectData}
            background={"bg-theme-gray-300"}
          />
        )}
        {userDetails?.is_pro_member ||
        userDetails?.pm_signup_status === "overdue" ? null : (
          <ChoosePlan handleOpenProMember={handleOpenProMember} />
        )}
        {profileManagerLoading ? null : (
          <CommunityAndRelations
            name={profileManagerData?.fullname}
            email={profileManagerData?.email}
            phone={profileManagerData?.mobile_number}
            whatsAppPhone={profileManagerData?.whatsapp_number}
            img={profileManagerData?.profile_picture}
          />
        )}

        <QPFPSection />
      </div>
      {showAdditionalDetailsModal && (
        <AdditionalDetailsModal
          open={true}
          onClose={() => setShowAdditionalDetailsModal(false)}
          publicProfileData={publicProfileData}
        />
      )}
      {userDetails?.is_pro_member ? null : <ProMemberRegisterModal />}
    </div>
  );
};

export default Dashboard;
