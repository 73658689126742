import axios from "axios";
import { User } from "../store";
import { navigate } from "@reach/router";

/**
 * Create an Axios Client with defaults
 */
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_PROD_BASE_API_URL,
  timeout: 30000,
});

const Api = async (
  config = { method: "GET", data: {}, params: {}, url: "" },
  shouldAppendToken = true,
  customToken = null
) => {
  // Success
  const onSuccess = (response) => {
    return Promise.resolve(response?.data);
  };

  // Error
  const onError = (error) => {
    if (error?.response?.data.error_code === "invalid_token") {
      /**
       * If 422
       * Clear the token from offline store
       * and navigate to Initial Stack using Navigation Service
       */
      User.clearToken();
      navigate("/visitor");
    }

    if (error?.response) {
      // Request was made but server responded with something
      // other than 2xx
      return Promise.reject(error?.response?.data);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      return Promise.reject(error?.message);
    }
  };

  // Append headers
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  // Append auth token
  if (shouldAppendToken) {
    try {
      const token = await User.getToken();
      if (customToken) {
        headers["Authorization"] = customToken;
      } else if (token) {
        headers["Authorization"] = token;
      }
    } catch (error) {
      // Token is not found
      return Promise.reject(error?.message);
    }
  }

  // Set headers
  axiosClient.defaults.headers = headers;

  return axiosClient(config).then(onSuccess).catch(onError);
};

export default Api;
