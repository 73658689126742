import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Star } from "@phosphor-icons/react";
import { navigate } from "@reach/router";

import ImageComponent from "../../stories/ImageComponent";
import StyledButton from "../CommonComponents/StyledButton";
import { useProfile } from "../../context/userProvider";
import { User } from "../../store";
import { UserPlanTag } from "../../Constants";

const AllCourseCard = ({
  id,
  title,
  subTitle,
  category,
  image,
  rating,
  status,
  duration,
  level,
  courseType,
  hasRegistered = false,
  pricing,
  isLoggedIn = false,
}) => {
  const [navigationLink, setNavigationLink] = useState("");
  const { userDetails } = useProfile();
  const CourseTags = [
    "free_training",
    "community",
    "pro_member",
    "pro_member_series",
    "qpfp_member",
  ];

  const getCTATitle = () => {
    if (
      courseType === "free_training" ||
      courseType === "new_user" ||
      courseType === "demo"
    ) {
      if (User.getToken()) {
        return "Start Course";
      }
    } else if (courseType?.includes("pro_member")) {
      if (
        userDetails.is_pro_member &&
        ["approved", "expires_soon", "provisional"].includes(
          userDetails.pm_signup_status
        )
      ) {
        return "Start Course";
      }
    }

    return "Know More";
  };

  useEffect(() => {
    if (courseType === "qpfp") {
      if (hasRegistered) {
        setNavigationLink("/qpfp-certificant/courseware");
      } else {
        setNavigationLink(`/members/qpfp/register?course_id=${id}`);
      }
    } else {
      setNavigationLink("/programs/" + id);
    }
  }, [id, courseType, hasRegistered]);

  return (
    <div className="flex flex-col gap-4 min-h-[377px] h-fit w-full xxs:max-w-[308px] border border-black-opacity-10 rounded-xl p-4 bg-white">
      <div className="relative">
        <ImageComponent
          alt="Course Banner"
          src={image}
          errorComponent={
            <div className="border border-black-opacity-10 flex items-center justify-center rounded-md w-full xxs:max-w-[276px] h-[156px]"></div>
          }
          className="border border-black-opacity-10 object-cover rounded-md w-full xxs:max-w-[276px] h-[156px]"
        />
        {/* {status === "Free" || status === "Event" ? (
          <div className="absolute top-2 right-2 rounded-full px-2 py-1 text-white text-[10px] bg-theme-green">
            {status}
          </div>
        ) : (
          courseType !== "pro_member_series" && (
            <div className="absolute top-2 right-2 rounded-full px-2 py-1 text-white text-[10px] bg-theme-yellow">
              {status}
            </div>
          )
        )} */}
      </div>
      <div className="flex items-center justify-between">
        {category ? (
          <p className="bg-theme-off-white px-1.5 py-1 text-theme-yellow text-xs">
            {category}
          </p>
        ) : (
          <div className="h-6"></div>
        )}
        {rating && (
          <div className="flex items-center justify-center gap-1">
            <Star weight="fill" color="#FFCC47" />
            <p className="color-theme-black-color font-bold text-sm">
              {rating}
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col items-start justify-start gap-1 h-20">
        <p className="line-clamp-1 w-11/12 text-xl font-bold tracking-tight">
          {title}
        </p>
        <p className="w-full line-clamp-2">{subTitle}</p>
      </div>

      <div className="flex items-center justify-between text-sm">
        <p className="text-theme-gray-500">{duration} </p>
        <p className="text-theme-black-color">
          {level === "beginner"
            ? "Beginner"
            : level === "advance"
            ? "Advanced"
            : level === "intermediate"
            ? "Intermediate"
            : ""}
        </p>
      </div>

      {pricing > 0 ? (
        <div className="flex justify-between">
          <div className="flex flex-col w-full max-w-52">
            {courseType === "event" || !CourseTags.includes(courseType) ? (
              <p className="text-lg font-bold text-theme-orange">
                {pricing && `₹ ${pricing}`}
              </p>
            ) : (
              CourseTags.includes(courseType) && (
                <p className="text-xs flex items-center gap-1 font-bold text-theme-orange">
                  Included with <UserPlanTag plan={courseType} isSmall={true} />
                </p>
              )
            )}
            {courseType === "event" ? (
              <p className="text-[10px] text-theme-black-300">*{status}</p>
            ) : (
              CourseTags.includes(courseType) && (
                <p className="text-[10px] text-theme-black-300">
                  Free worth {pricing && `₹ ${pricing}`}
                </p>
              )
            )}
          </div>
          <StyledButton
            buttonStyle={"outline-primary"}
            handleClick={() => {
              navigate(navigationLink);
            }}
            padding={"px-3 py-2"}
            customClasses={"w-40 rounded-md"}
            title={getCTATitle()}
          />
        </div>
      ) : (
        <div className="flex">
          <StyledButton
            buttonStyle={"outline-primary"}
            handleClick={() => {
              navigate(navigationLink);
            }}
            padding={"px-3 py-2"}
            // customClasses={"w-36 rounded-md"}
            title={getCTATitle()}
          />
        </div>
      )}
    </div>
  );
};

AllCourseCard.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  status: PropTypes.string,
  category: PropTypes.string,
  rating: PropTypes.number,
  duration: PropTypes.string,
  level: PropTypes.oneOf(["beginner", "intermediate", "advanced"]),
  courseType: PropTypes.string,
  hasRegistered: PropTypes.bool,
  pricing: PropTypes.any,
  isLoggedIn: PropTypes.bool,
};

export default AllCourseCard;
