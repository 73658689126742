import PropTypes from "prop-types";
import React from "react";
import ImageComponent from "../../stories/ImageComponent";
import { User as UserIcon } from "@phosphor-icons/react";

const CircularProgressBar = ({ imageSrc, percentage }) => {
  const radius = 36;
  const circumference = 2 * Math.PI * radius;
  // Adjust for clockwise direction, starting from the top
  const offset = circumference - (percentage / 100) * circumference; // Show progress based on percentage
  return (
    <div className="relative flex items-center justify-center w-28 h-28">
      {/* SVG Circular Progress Bar */}
      <svg className="absolute w-full h-full -rotate-90" viewBox="0 0 80 80">
        <circle
          cx="40"
          cy="40"
          r={radius}
          stroke="currentColor"
          strokeWidth="4"
          className="text-gray-300"
          fill="none"
        />
        <circle
          cx="40"
          cy="40"
          r={radius}
          stroke="currentColor"
          strokeWidth="4"
          className="text-orange-500"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>

      {/* Image in the center */}
      <div className="absolute w-20 h-20 rounded-full overflow-hidden bg-white">
        {imageSrc ? (
          <ImageComponent
            alt="profile-icon"
            src={imageSrc}
            errorComponent={
              <div className="flex items-center justify-center w-full h-full bg-gray-200">
                <UserIcon size={82} weight="bold" className="text-gray-400" />
              </div>
            }
            className="object-cover w-full h-full"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full bg-gray-200">
            <UserIcon size={82} weight="bold" className="text-gray-400" />
          </div>
        )}
      </div>

      {/* <div className="absolute bottom-[-15px] bg-orange-500 text-white rounded-full px-1 py-2 flex items-center justify-center text-xs font-medium">
        {percentage}%
      </div> */}
    </div>
  );
};

CircularProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  imageSrc: PropTypes.string,
};

export default CircularProgressBar;
