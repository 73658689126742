/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useNavigate } from "@reach/router";

import { confettiAnimation } from "../../util/confettiAnimation";
import { useProfile } from "../../context/userProvider";
import { Actions } from "./NationalEventRegistrationThankyouScreen";

import networkfp_logo from "../../assets/svgs/NFP_logo.svg";
import visitor_thank_you from "../../assets/svgs/visitor_thank_you.svg";

const EventRegistrationThankyouScreen = (props) => {
  const navigate = useNavigate();
  const confettiCanvas = useRef();
  const [, setPropData] = useState({});
  const { userDetails } = useProfile();

  const benefits = [
    "Recommend right MF products to your clients",
    "Scale your practice with team members",
    "Deliver high-quality service to your clients",
    "Be compliant with SEBI & AMFI guidelines",
    "Increase efficiency i.e. do more with same time",
  ];

  useEffect(() => {
    if (_.isEmpty(props?.location?.state)) {
      navigate(-1);
      return;
    } else {
      setPropData(
        props?.location?.state ?? {
          eventDetails: {},
          formData: {},
        }
      );
    }
  }, []);

  useEffect(() => {
    if (confettiCanvas.current) {
      confettiAnimation(confettiCanvas.current);
    }
  }, [confettiCanvas]);

  return (
    <>
      <div className="flex flex-row items-center justify-between px-5 py-4 lg:px-20 bg-white">
        <div>
          <img
            alt="network fp logo"
            src={networkfp_logo}
            className="h-9 lg:h-12"
          />
        </div>
      </div>
      <div className="min-h-[calc(100vh-115px)] flex bg-theme-gray-200">
        <div className="relative flex flex-col items-center w-full max-w-5xl p-8 mx-auto my-12 bg-white z-[60]">
          <img
            src={visitor_thank_you}
            alt="Static img"
            className="mb-8 w-20 h-20"
          />
          <p className="font-extrabold text-2xl text-theme-orange">
            Hello {userDetails?.first_name}
          </p>
          <h1 className="mt-4 font-extrabold text-4xl text-theme-black-500 text-center">
            Thank you for enrolling this course.
          </h1>
          <p className="mt-2 w-full text-theme-black-300 text-center md:w-2/4">
            You can now access the course.
          </p>

          <div className="mt-10 p-6 w-full bg-[#f5f7fb]">
            <div className="grid grid-cols-1 justify-between gap-y-3 lg:grid-cols-2">
              {benefits?.map((benefit, index) => (
                <div
                  key={`benefit-${index}`}
                  className="flex items-center space-x-2"
                >
                  <div className="h-2 w-2 bg-theme-orange rounded-full" />
                  <p className="font-medium">{benefit}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-4 grid justify-between grid-cols-1 gap-6 md:grid-cols-2">
            <Actions
              label="Your Courses"
              description="Check out list of all your courses"
              link="/my-programs"
              btnLabel="Go to Your Courses"
            />
            <Actions
              label="Go to Your Dashboard"
              description="Login, explore & access your NFP programs and benefits here"
              link="/"
              btnLabel="Go to Dashboard"
            />
          </div>

          <canvas
            ref={confettiCanvas}
            className="absolute z-50 w-full h-full"
          />
        </div>
      </div>
    </>
  );
};

EventRegistrationThankyouScreen.propTypes = {
  eventDetails: PropTypes.shape({
    description: PropTypes.string,
    short_title: PropTypes.string,
    title: PropTypes.string,
    whatNextContent: PropTypes.array,
    whenContent: PropTypes.array,
    whereContent: PropTypes.array,
  }),
};

export default EventRegistrationThankyouScreen;
