import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CrossIcon from "../../assets/images/X.png";
import AllCoursesFilter from "./AllCoursesFilters";

const PopupAllCoursesFilter = ({
  children,
  handleFilterChange,
  filters,
  courseCategoryFilters,
  courseDurationFilters,
  handleApplyFilters,
  handleClearAllFilters,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  return (
    <>
      <div onClick={openModal}>{children}</div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl p-6  text-left transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex justify-between items-top">
                    <img
                      className="absolute z-10 w-6 h-6 cursor-pointer right-6 focus:outline-none"
                      size="xs"
                      src={CrossIcon}
                      onClick={closeModal}
                      alt="close"
                    />
                  </div>
                  <div className="mt-2 h-full">
                    <AllCoursesFilter
                      handleFilterChange={handleFilterChange} // Update temporary filters
                      filters={filters}
                      courseCategoryFilters={courseCategoryFilters}
                      courseDurationFilters={courseDurationFilters}
                      handleApplyFilters={handleApplyFilters}
                      handleClearAllFilters={() => {
                        handleClearAllFilters();
                        closeModal();
                      }}
                    />
                  </div>

                  <div className="mt-4" hidden>
                    <button
                      type="button"
                      className="flex items-center justify-center w-16 px-4 py-2 mx-auto text-xs font-medium leading-3 text-white capitalize rounded bg-red-color"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

PopupAllCoursesFilter.propTypes = {
  children: PropTypes.element,
  handleFilterChange: PropTypes.func,
  filters: PropTypes.object,
  courseCategoryFilters: PropTypes.array,
  courseDurationFilters: PropTypes.array,
  handleApplyFilters: PropTypes.func,
  handleClearAllFilters: PropTypes.func,
  // nestedChildren: PropTypes.element,
};
export default PopupAllCoursesFilter;
