/* eslint-disable react/prop-types */
import {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
const LoginModalContext = createContext();

export const useLoginModal = () => {
  return useContext(LoginModalContext);
};

export const PopupType = Object.freeze({
  LOGIN: "login",
  SIGNUP: "signup",
  PROMEMBER: "pro-member",
  ALL: "all",
});

export const LoginModalProvider = ({ children }) => {
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [openSignupPopup, setOpenSignupPopup] = useState(false);
  const [openProMemberModal, setOpenProMemberModal] = useState(false);

  const handleOpenPopup = useCallback((type) => {
    switch (type) {
      case PopupType.LOGIN:
        setOpenLoginPopup(true);
        setOpenSignupPopup(false);
        setOpenProMemberModal(false);
        break;
      case PopupType.SIGNUP:
        setOpenLoginPopup(false);
        setOpenSignupPopup(true);
        setOpenProMemberModal(false);
        break;
      case PopupType.PROMEMBER:
        setOpenLoginPopup(false);
        setOpenSignupPopup(false);
        setOpenProMemberModal(true);
        break;
      case PopupType.ALL:
        setOpenLoginPopup(false);
        setOpenSignupPopup(false);
        setOpenProMemberModal(false);
        break;
      default:
        setOpenLoginPopup(false);
        setOpenSignupPopup(false);
        setOpenProMemberModal(false);
    }
  }, []);

  const value = useMemo(
    () => ({
      openLoginPopup,
      openSignupPopup,
      openProMemberModal,
      handleOpenPopup,
    }),
    [openLoginPopup, openSignupPopup, openProMemberModal, handleOpenPopup]
  );

  return (
    <>
      <LoginModalContext.Provider value={value}>
        {children}
      </LoginModalContext.Provider>
    </>
  );
};
