/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import TextInput from "../../stories/TextInput";
import { Validations } from "../../constants/validations";
import { AuthService } from "../../services/AuthService";
import { LockKey, UserCircle } from "@phosphor-icons/react";
import Dropdown from "../../stories/Dropdown";
import PaymentForm from "../Payments/PaymentForm";
import Button from "../../stories/Button";
import LoginUserModal from "../Modals/LoginUserModal";
import PaymentRequestSentModal from "../Modals/PaymentRequestSentModal";
import { PaymentServices } from "../../services/PaymentServices";
import _ from "lodash";
import { PromemberService } from "../../services/PromemberService";
import { CustomToast } from "../../stories/Toast";
import { navigate } from "@reach/router";

const PaymentScreen = ({
  isNewUser = false,
  currentMembershipDetails = {},
  publicProfileData = {},
  stepProps = {},
  userTempTokenRef = undefined,
  entityProps = {},
  loading = true,
  prefillData = {},
  course_id,
  onUpdate,
}) => {
  const [initialValues, setInitialValues] = useState({
    membership: {
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
      city_id: "",
      referrer_name: "",
      referrer_contact: "",
    },
    part_payment_order: entityProps.entity_type === "qpfp" ? 1 : 0,
    firmName: "",
    wantGST: false,
    gstin: "",
    gstFirmName: "",
    gstCommunicationAddress: "",
    gstPincode: "",
    gstCity_id: "",
    gstState: "",
  });
  const [cityOptions, setCityOptions] = useState([]);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [forceLoginUser, setForceLoginUser] = useState(false);
  const [newLoginUser, setNewLoginUser] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [openPaymentRequestedModal, setOpenPaymentRequestedModal] =
    useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [membershipDetails, setMembershipDetails] = useState({});
  const submitRef = React.useRef();

  const fetchPaymentData = async () => {
    try {
      await fetchGSTDetails();
      await fetchPricing({
        part_payment_order: initialValues.part_payment_order,
        discount_codes: [],
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchGSTDetails = async () => {
    if (isNewUser) {
      setInitialValues((prev) => ({ ...prev, wantGST: true }));
      return null;
    }
    try {
      const response = await PaymentServices.fetchPreviousGSTDetails();

      setInitialValues((prevState) => {
        if (response.gst_invoice_check === false)
          return { ...prevState, wantGST: true };
        return {
          ...prevState,
          wantGST: response.gst_invoice_check,
          gstin: response.gst_number,
          gstFirmName: response.firm_name,
          gstCommunicationAddress: response.address.line_1,
          gstPincode: response.address.pin,
          gstCity_id: response.address.city_id,
          gstCity: response.address.city,
          gstState_id: response.address.state_id,
        };
      });
    } catch (e) {
      console.log("no previous details found");
    }
  };
  const fetchPricing = async ({
    part_payment_order = initialValues.part_payment_order,
    discount_codes = [],
  }) => {
    const response = await PaymentServices.fetchPricingDetails(
      {
        entity_type: entityProps.entity_type,
        entity_id: course_id,
        part_payment_order: part_payment_order,
        discount_codes: discount_codes,
      },
      userTempTokenRef.current
    );
    setPriceDetails(response);
  };

  const registerAndPayForMembership = async (values, paymentType) => {
    const { membership, ...paymentDetails } = values;
    let membershipRegistrationDetails = membershipDetails;
    if (_.isEmpty(membershipRegistrationDetails)) {
      membershipRegistrationDetails = await registerMembership(membership);
    }
    if (!_.isEmpty(currentMembershipDetails.payment)) {
      if (isNewUser) {
        navigate("thank-you", {
          state: {
            email: membership?.email,
            first_name: membership?.first_name,
          },
        });
        return;
      } else {
        stepProps.handleNext();
        return;
      }
    }

    if (paymentType === 0) {
      await makePayment({
        entity_id: membershipRegistrationDetails.entity_id,
        membership,
        ...paymentDetails,
      });
    } else if (paymentType === 1) {
      await sendPaymentRequestToAdmin(membershipRegistrationDetails.entity_id);
    }
  };

  const sendPaymentRequestToAdmin = async (entity_id) => {
    try {
      const response = await PaymentServices.sendPaymentRequestToAdmin({
        entity_type: "Membership",
        entity_id: entity_id,
      });
      CustomToast.success(response.message);
      setOpenPaymentRequestedModal(true);
    } catch (e) {
      CustomToast.error(e.error_messages);
    }
  };

  const registerMembership = async (values) => {
    const {
      city_id,
      referrer_name,
      entity_id,
      entity_type,
      referrer_contact,
      mobile_number,
      ...userValues
    } = values;
    const postBody = {
      ...userValues,
      mobile_number,
      referrer_name,
      referrer_contact,
      entity_id,
      entity_type,
    };
    if (entityProps.entity_type === "qpfp") {
      postBody["course_id"] = course_id;
    }
    if (city_id !== currentMembershipDetails?.address?.city_id) {
      postBody["address_attributes"] = {
        line_1: "-",
        city_id: city_id,
        pin: "-",
      };
    }
    const result =
      isNewUser &&
      _.isEmpty(currentMembershipDetails) &&
      _.isEmpty(userTempTokenRef.current)
        ? await PromemberService.updatePromemberDirectRegister(
            postBody,
            entity_type,
            entity_id ?? null,
            { ...userValues, phone_number: mobile_number, city_id }
          )
        : await PromemberService.updatePromember(
            postBody,
            entity_type,
            entity_id ?? null,
            { ...userValues, city_id },
            userTempTokenRef.current
          );
    if (isNewUser && _.isEmpty(currentMembershipDetails)) {
      userTempTokenRef.current = result.token;
    }
    setMembershipDetails(result);
    onUpdate();
    CustomToast.success(result.message ?? "");
    return result;
  };

  const makePayment = async (values) => {
    try {
      const { entity_id, membership, ...paymentValues } = values;
      let postBody = {
        pricing_id: priceDetails.pricing_id,
        coupon_ids: priceDetails.coupons?.map((e) => e.code),
        payment_gateway: "razorpay",
        course_fees: priceDetails.course_fees,
        gst: priceDetails.gst,
        to_be_paid: priceDetails.to_be_paid,
      };
      if (paymentValues.wantGST) {
        postBody = {
          ...postBody,
          gst_invoice_check: paymentValues.wantGST,
          gst_number: paymentValues.gstin,
          firm_name: paymentValues.gstFirmName,
          address_attributes: {
            line_1: paymentValues.gstCommunicationAddress,
            city_id: paymentValues.gstCity_id,
            pin: paymentValues.gstPincode,
          },
        };
      }

      const orderDetails = await PaymentServices.createOrder(
        {
          purchase: postBody,
          ...entityProps,
          entity_id: entity_id,
        },
        userTempTokenRef.current
      );
      if (orderDetails && orderDetails.payment_status === "initiated") {
        await PaymentServices.makeRazorpayPayment(
          {
            name: membership.first_name + " " + membership.last_name,
            email: membership.email,
            contact: membership.mobile_number,
          },
          orderDetails,
          () => {
            CustomToast.success("Payment Successful" ?? "");
            if (isNewUser) {
              navigate("thank-you", {
                state: {
                  email: membership?.email,
                  first_name: membership?.first_name,
                },
              });
              return;
            } else {
              stepProps.handleNext();
              return;
            }
          },
          userTempTokenRef.current
        );
      } else if (orderDetails && orderDetails.payment_status === "success") {
        CustomToast.info(orderDetails.message);
        stepProps.handleNext();
      } else {
        CustomToast.error(orderDetails.message);
      }
    } catch (e) {
      console.log("errr");
    }
  };

  const fetchInitialData = () => {
    let gstDetails = {};
    if (currentMembershipDetails.payment) {
      setPriceDetails(currentMembershipDetails.payment);
      if (!_.isEmpty(currentMembershipDetails.payment.gst_details)) {
        gstDetails = {
          wantGST: true,
          gstin: currentMembershipDetails.payment.gst_details.gst_number,
          gstFirmName: currentMembershipDetails.payment.gst_details.firm_name,
          gstCommunicationAddress:
            currentMembershipDetails.payment.gst_details.address.line_1,
          gstPincode: currentMembershipDetails.payment.gst_details.address.pin,
          gstCity_id:
            currentMembershipDetails.payment.gst_details.address.city_id,
          gstCity: currentMembershipDetails.payment.gst_details.address.city,
          gstState: currentMembershipDetails.payment.gst_details.address.state,
        };
      } else {
        gstDetails = { wantGST: false };
      }
    } else {
      fetchPaymentData();
    }
    setInitialValues((prev) => ({
      ...prev,
      membership: {
        first_name:
          currentMembershipDetails?.first_name ??
          publicProfileData?.first_name ??
          prefillData?.first_name ??
          "",
        last_name:
          currentMembershipDetails?.last_name ??
          publicProfileData?.last_name ??
          prefillData?.last_name ??
          "",
        email:
          currentMembershipDetails?.email ??
          publicProfileData?.email ??
          prefillData?.email ??
          "",
        mobile_number:
          currentMembershipDetails?.mobile_number ??
          publicProfileData?.phone_number ??
          prefillData?.phone_number ??
          "",
        city_id:
          currentMembershipDetails?.address?.city_id ??
          publicProfileData?.city?.id ??
          "",
        referrer_name: currentMembershipDetails?.referrer_name ?? "",
        referrer_contact: currentMembershipDetails?.referrer_contact ?? "",
        entity_type:
          currentMembershipDetails?.entity_type ?? entityProps.entity_type,
        entity_id: currentMembershipDetails?.entity_id ?? entityProps.entity_id,
      },
      ...gstDetails,
    }));
  };
  const fetchCities = async () => {
    try {
      const result = await AuthService.getCities("cities");
      if (result.locations) {
        setCityOptions(result.locations);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!loading) {
      fetchInitialData();
    }
  }, [loading]);

  useEffect(() => {
    fetchCities();
  }, []);

  return (
    <div>
      <div className={classNames("flex flex-col w-full items-start")}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            Validations.membership.paymentScreenValidationSchema
          }
          onSubmit={async (values) => {
            if (forceLoginUser) {
              setOpenLoginPopup(true);
              return;
            }
            await registerAndPayForMembership(values, 0);
          }}
        >
          {({ values, ...formProps }) => {
            return (
              <Form className="w-full">
                <div className="flex flex-col w-full gap-6 mt-2 bg-white rounded-sm shadow-sm lg:shadow-none">
                  <div className="grid grid-cols-2 gap-5">
                    <div className="">
                      <TextInput
                        name="membership.first_name"
                        label="First Name"
                        placeholder="Rahul"
                        id="first_name"
                        type="text"
                        disable={false}
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="membership.last_name"
                        label="Last Name"
                        placeholder="Subramanian"
                        id="last_name"
                        type="text"
                        disable={false}
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="membership.email"
                        label="Email Address"
                        placeholder="rahul@xyz.com"
                        id="email"
                        type="text"
                        disable={!isNewUser}
                        isRequired
                        onBlur={async (event) => {
                          const currentEmail = event.target.value;
                          formProps.setFieldTouched(
                            "membership.email",
                            true,
                            true
                          );
                          if (
                            (_.isEmpty(currentEmail) ||
                              !_.isEmpty(formProps.errors.membership.email)) &&
                            (_.isEmpty(values.membership.mobile_number) ||
                              !_.isEmpty(
                                formProps.errors.membership.mobile_number
                              )) &&
                            !forceLoginUser
                          ) {
                            setNewLoginUser(false);
                            setForceLoginUser(false);
                            return;
                          }
                          setUpdatedEmail(currentEmail);
                          setForceLoginUser(false);
                          try {
                            await AuthService.validateEmail({
                              email: currentEmail,
                              phone_number:
                                values.membership.mobile_number.toString(),
                            });
                            setForceLoginUser(false);
                            setNewLoginUser(true);
                          } catch (e) {
                            // Email already present
                            // ask user to login
                            setNewLoginUser(false);
                            setForceLoginUser(true);
                          }
                        }}
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="membership.mobile_number"
                        label="Mobile No. (10 digits)"
                        placeholder="9876543210"
                        id="mobile_number"
                        type="number"
                        disable={!isNewUser}
                        leadingComponent={
                          <span className="inline-flex items-center px-4 text-base text-gray-500 border-r border-gray-200 rounded rounded-r-none bg-gray-50">
                            +91
                          </span>
                        }
                        isRequired
                        onBlur={async (event) => {
                          const currentPhoneNumber = event.target.value;
                          formProps.setFieldTouched(
                            "membership.mobile_number",
                            true,
                            true
                          );
                          if (
                            (_.isEmpty(currentPhoneNumber) ||
                              !_.isEmpty(formProps.errors.membership.email)) &&
                            (_.isEmpty(values.membership.email) ||
                              !_.isEmpty(formProps.errors.membership.email)) &&
                            !forceLoginUser
                          ) {
                            setNewLoginUser(false);
                            setForceLoginUser(false);
                            return;
                          }
                          try {
                            await AuthService.validateEmail({
                              email: values.membership.email,
                              phone_number: currentPhoneNumber,
                            });
                            setForceLoginUser(false);
                            setNewLoginUser(true);
                          } catch (e) {
                            // Email already present
                            // ask user to login
                            setNewLoginUser(false);
                            setForceLoginUser(true);
                          }
                        }}
                      />
                    </div>
                    {isNewUser && forceLoginUser && (
                      <div className="flex items-start text-sm text-gray-900 col-span-full">
                        <LockKey
                          size={16}
                          className="my-1 mr-2 text-theme-red-300"
                        />
                        <div className="block">
                          Your Network FP account already exists.&nbsp;
                          <span
                            onClick={() => setOpenLoginPopup(true)}
                            className="underline cursor-pointer text-theme-red-300"
                          >
                            Please login
                          </span>
                        </div>
                      </div>
                    )}
                    {isNewUser && newLoginUser ? (
                      <div className="flex items-start text-sm text-gray-900 col-span-full">
                        <UserCircle
                          size={16}
                          className="mt-0.5 mr-2 text-theme-red-300"
                        />
                        <div className="block">
                          New Login will be created on this ID.&nbsp;
                        </div>
                      </div>
                    ) : null}
                    <div className="w-full">
                      <Dropdown
                        name="membership.city_id"
                        id="city"
                        type="text"
                        label="City"
                        placeholder="Select City"
                        options={cityOptions ?? []}
                        displayCurrentOption={true}
                        displayKey="name"
                        idKey="id"
                        isRequired
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="w-full text-sm font-medium col-span-full text-theme-black-color">
                      Who referred you to this program?
                    </div>
                    <div className="">
                      <TextInput
                        name="membership.referrer_name"
                        label="Full Name"
                        placeholder="Subramanian"
                        id="referrer_name"
                        type="text"
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="membership.referrer_contact"
                        label="Mobile No. (10 digits)"
                        placeholder="9876543210"
                        id="referrer_contact"
                        type="number"
                        leadingComponent={
                          <span className="inline-flex items-center px-4 text-base text-gray-500 border-r border-gray-200 rounded rounded-r-none bg-gray-50">
                            +91
                          </span>
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
                      Payment
                    </div>
                    <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
                      Make payment for membership.
                    </div>
                  </div>
                  <PaymentForm
                    programTitle={
                      entityProps.entity_type === "pro_member"
                        ? "ProMembership for 1 year"
                        : entityProps.entity_type === "provisional_member"
                        ? "Community Membership for 1 year"
                        : "QPFP Application Fee (Includes Module 1 Fee)"
                    }
                    allowPartPayment={false}
                    entityProps={{
                      entity_type: entityProps.entity_type,
                      entity_id: course_id,
                    }}
                    priceDetails={priceDetails}
                    setPriceDetails={setPriceDetails}
                    submitRef={submitRef}
                    formikProps={{ values, ...formProps }}
                    className="!mt-0"
                    isReadOnly={!_.isEmpty(currentMembershipDetails.payment)}
                    cta={
                      !_.isEmpty(currentMembershipDetails.payment) ? (
                        <Button
                          buttonStyle="primary"
                          height="40px"
                          width="100%"
                          className="mt-3"
                          onClick={async () => {
                            await registerAndPayForMembership(values, 0);
                          }}
                        >
                          NEXT
                        </Button>
                      ) : (
                        <React.Fragment>
                          <Button
                            buttonStyle="primary"
                            height="40px"
                            width="100%"
                            className="mt-3"
                            disabled={formProps.isSubmitting}
                            onClick={async () => {
                              await submitRef.current?.click();
                            }}
                          >
                            PAY NOW
                          </Button>
                          {publicProfileData.firm?.status === "approved" && (
                            <Button
                              buttonStyle="secondary"
                              height="40px"
                              width="100%"
                              className="mt-3"
                              disabled={formProps.isSubmitting}
                              onClick={async () => {
                                const response = await formProps.validateForm(
                                  values
                                );
                                if (_.isEmpty(response)) {
                                  await registerAndPayForMembership(values, 1);
                                } else {
                                  formProps.setTouched({
                                    membership: {
                                      first_name: true,
                                      last_name: true,
                                      mobile_number: true,
                                      email: true,
                                      city_id: true,
                                      referrer_name: true,
                                      referrer_contact: true,
                                    },
                                    part_payment_order: true,
                                    firmName: true,
                                    wantGST: false,
                                    gstin: true,
                                    gstFirmName: true,
                                    gstCommunicationAddress: true,
                                    gstPincode: true,
                                    gstCity_id: true,
                                    gstState: true,
                                  });
                                }
                              }}
                            >
                              SEND PAYMENT DETAILS TO ADMIN
                            </Button>
                          )}
                        </React.Fragment>
                      )
                    }
                  />
                  <button
                    disabled={formProps.isSubmitting}
                    className="hidden"
                    ref={submitRef}
                    type="submit"
                    onClick={() => {}}
                  >
                    submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {openLoginPopup && (
        <LoginUserModal
          prefill_data={{ email: updatedEmail }}
          className=""
          open={openLoginPopup}
          onClose={() => setOpenLoginPopup(false)}
        />
      )}
      {openPaymentRequestedModal && (
        <PaymentRequestSentModal
          open={openPaymentRequestedModal}
          onClose={() => setOpenPaymentRequestedModal(false)}
        />
      )}
    </div>
  );
};

PaymentScreen.propTypes = {
  course_id: PropTypes.any,
  currentMembershipDetails: PropTypes.object,
  entityProps: PropTypes.object,
  isNewUser: PropTypes.bool,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  prefillData: PropTypes.object,
  publicProfileData: PropTypes.object,
  stepProps: PropTypes.object,
  userTempTokenRef: PropTypes.shape({
    current: PropTypes.any,
  }),
};

export default PaymentScreen;
