import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { confettiAnimation } from "../../util/confettiAnimation.js";
import { useProfile } from "../../context/userProvider.js";

import networkfp_logo from "../../assets/svgs/NFP_logo.svg";
import visitor_thank_you from "../../assets/svgs/visitor_thank_you.svg";
import visitor_courses from "../../assets/svgs/visitor_courses.svg";
import visitor_resources from "../../assets/svgs/visitor_resources.svg";
import visitor_live_sessions from "../../assets/svgs/visitor_live_sessions.svg";
import visitor_exchange_forum from "../../assets/svgs/visitor_exchange_forum.svg";
import visitor_client_connect from "../../assets/svgs/visitor_client_connect.svg";
import visitor_basic_profile from "../../assets/svgs/visitor_profile.svg";
import { Actions } from "../Events/NationalEventRegistrationThankyouScreen.js";

const NFPFeature = ({ index, highlight, text }) => {
  const getImage = () => {
    switch (index) {
      case 0:
        return visitor_courses;
      case 1:
        return visitor_resources;
      case 2:
        return visitor_live_sessions;
      case 3:
        return visitor_exchange_forum;
      case 4:
        return visitor_client_connect;
      case 5:
        return visitor_basic_profile;
      default:
        return visitor_courses;
    }
  };

  return (
    <div className="flex space-x-2 items-center">
      <img src={getImage()} alt="NFP Basic Account Feature" />
      <p>
        <span className="font-bold">{highlight}</span> {text}
      </p>
    </div>
  );
};

NFPFeature.propTypes = {
  index: PropTypes.number.isRequired,
  highlight: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const VisitorRegistrationThankYouScreen = () => {
  const confettiCanvas = useRef();

  const { userDetails } = useProfile();
  const { first_name } = userDetails;

  const features = [
    {
      highlight: "4",
      text: "NFP Courses",
    },
    {
      highlight: "6",
      text: "ProTools and Resources",
    },
    {
      highlight: "Friday",
      text: "Live Sessions",
    },
    {
      highlight: "View Only",
      text: "Exchange Forum",
    },
    {
      highlight: "20 Images",
      text: "Client Connect",
    },
    {
      highlight: "Basic",
      text: "PROfile",
    },
  ];

  useEffect(() => {
    if (confettiCanvas.current) {
      confettiAnimation(confettiCanvas.current);
    }
  }, [confettiCanvas]);

  return (
    <>
      <div className="flex flex-row items-center justify-between px-5 py-4 lg:px-20 bg-white">
        <div>
          <img
            alt="network fp logo"
            src={networkfp_logo}
            className="h-9 lg:h-12"
          />
        </div>
      </div>
      <div className="min-h-[calc(100vh-115px)] flex bg-theme-gray-200">
        <div className="relative flex flex-col items-center w-full max-w-5xl p-8 mx-auto my-12 bg-white z-[60]">
          <img
            src={visitor_thank_you}
            alt="Static img"
            className="mb-8 w-20 h-20"
          />
          <p className="font-extrabold text-2xl text-theme-orange">
            Hello {first_name}
          </p>
          <h1 className="mt-4 font-extrabold text-4xl text-theme-black-500 text-center">
            Congratulations and Welcome!
          </h1>
          <p className="mt-2 w-full text-theme-black-300 text-center md:w-2/4">
            You are now officially part of Network FP community and access all
            Basic Account benefits and courses.
          </p>

          <div className="mt-10 p-6 w-full bg-[#f5f7fb]">
            <p className="font-semibold text-theme-black-500">
              What you get in NFP Basic Account?
            </p>

            <div className="mt-6 grid justify-between grid-cols-1 gap-y-6 md:grid-cols-2">
              {features?.map((feature, index) => (
                <NFPFeature
                  key={`nfp-feature-${index}`}
                  index={index}
                  highlight={feature?.highlight}
                  text={feature?.text}
                />
              ))}
            </div>
          </div>

          <div className="mt-4 grid justify-between grid-cols-1 gap-6 md:grid-cols-3">
            <Actions
              label="Start Your First Course"
              description="Attend The Making of Successful Personal Finance Professional (PFP)"
              link="/my-programs"
              btnLabel="Go to my course"
            />
            <Actions
              label="Create Your NFP Profile"
              description="Build and use your NFP Profile to position yourself as an expert and generate leads."
              link="/public-profile"
              btnLabel="Create Profile"
            />
            <Actions
              label="Go to Your Dashboard"
              description="Login, explore & access your NFP programs and benefits here"
              link="/"
              btnLabel="Go to Dashboard"
            />
          </div>

          <canvas
            ref={confettiCanvas}
            className="absolute z-50 w-full h-full"
          />
        </div>
      </div>
    </>
  );
};

export default VisitorRegistrationThankYouScreen;
