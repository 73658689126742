import PropTypes from "prop-types";
import ImageComponent from "../../stories/ImageComponent";
import { User as UserIcon } from "@phosphor-icons/react";

const RelationManagerDetails = ({ profileImg, name }) => {
  return (
    <div className="flex gap-1.5 items-center justify-start ">
      <ImageComponent
        alt="profile-icon"
        src={profileImg}
        errorComponent={
          <div className="flex items-center justify-center rounded-full sm:w-24 sm:h-24 overflow-hidden bg-gray-200">
            <UserIcon
              size={52}
              weight="bold"
              className="text-gray-400 sm:w-24 sm:h-24"
            />
          </div>
        }
        className="object-cover rounded-full sm:w-24 sm:h-24"
      />
      <div className="flex flex-col items-start justify-center gap-2">
        <p className="font-bold text-xl sm:text-2xl text-theme-black-color">
          {name}
        </p>
        <p className="font-medium text-sm sm:text-base text-theme-black-300">
          Your Relationship Manager
        </p>
      </div>
    </div>
  );
};

RelationManagerDetails.propTypes = {
  name: PropTypes.string,
  profileImg: PropTypes.string,
};

export default RelationManagerDetails;
