export const timeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (seconds < 60) {
    return seconds === 1 ? "a second ago" : `${seconds} seconds ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return hours === 1 ? "an hour ago" : `${hours} hours ago`;
  }

  const days = Math.floor(hours / 24);
  if (days < 7) {
    return days === 1 ? "a day ago" : `${days} days ago`;
  }

  const weeks = Math.floor(days / 7);
  if (weeks < 4) {
    return weeks === 1 ? "a week ago" : `${weeks} weeks ago`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return months === 1 ? "a month ago" : `${months} months ago`;
  }

  const years = Math.floor(days / 365);
  return years === 1 ? "a year ago" : `${years} years ago`;
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { weekday: "short", day: "2-digit", month: "short" };
  return date.toLocaleDateString("en-US", options);
};

// // Example usage
// const formattedDate = formatDate("2025-01-02");
// console.log(formattedDate); // Output: Thu, 02 Jan
