import React from "react";
import PropTypes from "prop-types";
import Button from "../../../stories/Button";
import { CustomToast } from "../../../stories/Toast";
import CheckMark from "../../../assets/svgs/Green-Check.svg";
import EmailIcon from "../../../assets/svgs/qpfp-email-icon.svg";
import PhoneIcon from "../../../assets/svgs/qpfp-phone-icon.svg";
import WebsiteIcon from "../../../assets/svgs/qpfp-profile-website.svg";
import LinkedinIcon from "../../../assets/svgs/qpfp-profile-linkedin.svg";
import EducationIcon from "../../../assets/svgs/qpfp-profile-education.svg";
import OathIcon from "../../../assets/svgs/qpfp-profile-oath.svg";
import { useProfile } from "../../../context/userProvider";
import UserProfileAvatar from "../../../stories/UserProfileAvatar";
import { useCurrentUserPublicProfile } from "../../../services/BasicDetails";
import { getDirectoryMemberURL } from "../../../util/getURLs";
import { CircleNotch, Copy } from "@phosphor-icons/react";
import { useNavigate } from "@reach/router";
import _ from "lodash";

/**
 * Component for Text with Green Check Mark
 * @return {React.ReactElement} Text with Green Check Mark
 */
export function TextWithCheckMark({ label }) {
  return (
    <div className="flex items-center space-x-3">
      <img src={CheckMark} alt="Checkmark" className="h-4 w-4" />
      <p className="font-normal text-xs">{label}</p>
    </div>
  );
}

TextWithCheckMark.propTypes = {
  label: PropTypes.string,
};

/**
 * Component for Manage Your Profile Dashboard Element
 * @return {React.ReactElement} Manage Your Profile Dashboard Element
 */
function ManageProfile() {
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  const { loading: publicProfileLoading, publicProfileData } =
    useCurrentUserPublicProfile({
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    });
  const profileLink = getDirectoryMemberURL(publicProfileData?.profile_slug);

  const possibleUpdates = [
    "Link on Social Media",
    "Link in your emails",
    "Link in Client Communications",
    "Generate Referrals from Clients",
    "QR Code on Business Card",
    "Offer complimentary services",
    "Share with NFP ClientConnect",
    "Generate Appointments",
  ];

  if (publicProfileLoading) {
    return (
      <CircleNotch className="mx-auto animate-spin" size={20} weight="fill" />
    );
  }

  return (
    <div className="flex flex-col items-start space-y-3">
      <div className="self-stretch flex items-center space-x-3">
        <UserProfileAvatar
          imageUrl={userDetails.profile_picture?.signed_url}
          isLoggedInUser
          sizeClass="w-20 h-20 rounded-full min-w-[80px]"
          errorIconSize={36}
          showBadge={false}
        />

        <div className="flex flex-col gap-2">
          <p className="font-medium text-lg">{userDetails?.display_name}</p>
          {!_.isEmpty(publicProfileData?.firm?.name) && (
            <p className="font-light text-base text-[#525252]">
              {publicProfileData?.firm?.name}
            </p>
          )}
          <p className="font-medium text-sm">{userDetails.city_name}</p>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-1">
          <img src={EducationIcon} alt="Occupation Icon" />
          <p className="font-light text-sm">
            {
              publicProfileData?.user_educational_details?.[0]?.field_of_study
                ?.value
            }
          </p>
        </div>

        {publicProfileData?.oath_taken ? (
          <>
            <div className="w-2 h-2 rounded-full bg-[#D9D9D9]" />

            <div className="flex items-center space-x-1">
              <img src={OathIcon} alt="Oath Icon" />
              <p className="font-light text-sm">Ethics at heart - The Oath</p>
            </div>
          </>
        ) : (
          <div />
        )}
      </div>

      <div className="flex items-center space-x-8">
        {publicProfileData?.firm?.website && (
          <a
            className="flex items-center space-x-1"
            href={publicProfileData?.firm?.website}
            target="_blank"
            rel="noreferrer"
          >
            <img src={WebsiteIcon} alt="Website Icon" />
            <p className="font-medium text-sm">Website</p>
          </a>
        )}

        {publicProfileData?.profile_detail?.linkedin_link && (
          <a
            className="flex items-center space-x-1"
            href={publicProfileData?.profile_detail?.linkedin_link}
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedinIcon} alt="Linkedin Icon" />
            <p className="font-medium text-sm">LinkedIn</p>
          </a>
        )}
      </div>

      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <img src={EmailIcon} alt="Email Icon" />
          <p className="font-light text-sm text-[#525252]">
            {publicProfileData?.profile_detail?.public_email}
          </p>
        </div>

        <div className="flex items-center space-x-2">
          <img src={PhoneIcon} alt="Phone Icon" />
          <p className="font-light text-sm text-[#525252]">
            +91 {publicProfileData?.profile_detail?.public_mobile_number}
          </p>
        </div>
      </div>

      <p className="font-light text-sm text-[#525252]">
        Update &amp; use your NFP PROfile as your professional micro-site
      </p>

      <div className="flex flex-col space-y-2">
        {possibleUpdates?.map((label, index) => (
          <TextWithCheckMark key={`profile-update-${index}`} label={label} />
        ))}
      </div>

      <div className="self-stretch flex justify-between items-center cursor-pointer gap-3">
        <a
          href={profileLink}
          className="font-light text-sm text-theme-blue-200"
          target="_blank"
          rel="noreferrer"
        >
          {profileLink}
        </a>
        <div
          className="flex items-center space-x-2"
          onClick={() => {
            navigator.clipboard.writeText(profileLink);
            CustomToast.success("Copied to clipboard", { duration: 30000 });
          }}
        >
          <p className="font-semibold text-sm">Copy</p>
          <Copy size={24} className="text-theme-red min-w-[24px]" />
        </div>
      </div>

      <Button
        onClick={() => navigate("/public-profile")}
        className="!rounded-md"
      >
        Manage
      </Button>
    </div>
  );
}

export default ManageProfile;
