import PropTypes from "prop-types";
import { DownloadSimple, Files } from "@phosphor-icons/react";
import StyledButton from "./StyledButton";
import { ContentBox } from "../../stories/CCContent";
const ClientConnectCard = ({
  title,
  subTitle,
  category,
  fileUrl,
  fileType,
  downloadLink,
  attachmentFileType,
}) => {
  return (
    <div className="flex flex-col gap-4 min-h-[377px] w-full xxs:max-w-[308px] border border-black-opacity-10 rounded-xl p-4">
      <div className="flex items-center gap-2.5">
        <div className="rounded-full bg-theme-orange-400 p-3">
          <Files weight={"fill"} color="#fff" size={17} />
        </div>
        <p className="line-clamp-1 w-11/12 text-xl font-bold tracking-tight">
          {title}
        </p>
      </div>
      <div className="border-b border-black-opacity-10"></div>
      <ContentBox type={fileType} src={fileUrl} newCard={true} />
      <div className="flex items-center justify-between">
        <p className="bg-theme-off-white px-1.5 py-1 text-theme-yellow text-xs">
          {category}
        </p>
        <div className="flex items-center justify-center gap-1 text-theme-black-300 text-xs">
          <p>File Type :</p>
          <p className="first-letter:capitalize">{attachmentFileType}</p>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start gap-1">
        <p className="line-clamp-2 text-black-opacity-60 h-12">{subTitle}</p>
      </div>
      <div className="flex justify-between gap-4">
        <StyledButton
          padding={"px-2.5 py-3"}
          handleClick={() => {
            window.open(downloadLink, "_blank");
          }}
          title={"Download"}
          buttonStyle="primary"
          icon={<DownloadSimple size={20} />}
        />
      </div>
    </div>
  );
};

ClientConnectCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fileUrl: PropTypes.string,
  category: PropTypes.string,
  fileType: PropTypes.string,
  attachmentFileType: PropTypes.string,
  downloadLink: PropTypes.string,
};

export default ClientConnectCard;
