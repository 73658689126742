import classNames from "classnames";
import PropTypes from "prop-types";

const StyledButton = ({
  buttonStyle,
  title,
  isDisabled = false,
  handleClick,
  padding,
  icon,
  textSize,
  customClasses,
}) => {
  const style = () => {
    switch (buttonStyle) {
      case "transparent-primary":
        return "text-black bg-white";
      case "transparent-secondary":
        return "text-theme-red";
      case "disabled":
        return "bg-gray-500 text-gray-200 cursor-not-allowed hover:bg-gray-500 opacity-50";
      case "outline-primary":
        return "bg-white text-theme-red border border-theme-red hover:bg-theme-red hover:text-white";
      case "outline-white":
        return "bg-transparent text-white border border-white hover:bg-white hover:text-theme-red";
      default:
        return "bg-theme-red text-white hover:text-white hover:bg-theme-red";
    }
  };
  return (
    <button
      className={classNames(
        customClasses ??
          "flex items-center justify-center gap-0.5 w-full font-medium rounded-md min-w-24",
        style(),
        padding ?? "px-2 sm:px-6 py-3",
        textSize ?? "text-xs sm:text-sm"
      )}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {icon ?? <></>}
      <p>{title}</p>
    </button>
  );
};

StyledButton.propTypes = {
  buttonStyle: PropTypes.oneOf([
    "primary",
    "secondary",
    "disabled",
    "outline-primary",
    "outline-white",
    "transparent-primary",
  ]),
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  handleClick: PropTypes.func,
  padding: PropTypes.string,
  icon: PropTypes.element,
  textSize: PropTypes.string,
  customClasses: PropTypes.string,
};

export default StyledButton;
