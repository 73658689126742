import PropTypes from "prop-types";

import full_star from "../../assets/svgs/filled-star.svg";
import half_star from "../../assets/svgs/half-star.svg";
import empty_star from "../../assets/svgs/empty-star.svg";

const StarRatings = ({ rating }) => {
  const decimal = rating % 1;
  let filledCount = 0;
  let halfCount = 0;
  let emptyCount = 0;

  filledCount = rating - decimal;

  if (decimal > 0) {
    halfCount = 1;
  }

  emptyCount = 5 - (filledCount + halfCount);

  const final = {
    filled: filledCount,
    half: halfCount,
    empty: emptyCount,
  };

  return (
    <div className="flex space-x-1 items-start">
      {[...Array(final.filled)].map((_, index) => (
        <img
          key={`full-star-${index}`}
          src={full_star}
          alt="full star rating"
          className="w-5 h-5"
        />
      ))}
      {[...Array(final.half)].map((_, index) => (
        <img
          key={`half-star-${index}`}
          src={half_star}
          alt="half star rating"
          className="w-5 h-5"
        />
      ))}
      {[...Array(final.empty)].map((_, index) => (
        <img
          key={`empty-star-${index}`}
          src={empty_star}
          alt="empty star rating"
          className="w-5 h-5"
        />
      ))}
    </div>
  );
};

StarRatings.propTypes = {
  rating: PropTypes.number,
};

export default StarRatings;
