import { useAPI } from "./SWRService";
import _ from "lodash";
import ApiConfig from "../api/config";
import qs from "qs";
import { User } from "../store";

export const useLocationList = (config = {}) => {
  const key = ApiConfig.urls.auth.getCities("cities");
  const { data, error, loading, mutate, isValidating } = useAPI(key, config);
  return {
    locations: data?.locations || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useDescriptionOptionList = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.auth.getDescriptionOptions,
    config
  );
  const optionList = data ? { ..._.merge({}, ...data?.values) } : {};
  return {
    optionList: optionList || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useOptions = (optionKeyList = [], config = {}) => {
  const key = `${ApiConfig.urls.public_profile.fetch_dropdowns}?${qs.stringify(
    {
      sections: optionKeyList,
    },
    { arrayFormat: "brackets", encode: false }
  )}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, config);

  const optionList = data ? { ..._.merge({}, ...data?.values) } : {};
  return {
    options: optionList || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useCurrentUserPublicProfile = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    User.getToken() ? ApiConfig.urls.public_profile.fetch_profile : null,
    config
  );
  return {
    publicProfileData: data?.user || {},
    error,
    loading: User.getToken() ? loading : false,
    mutate,
    isValidating,
  };
};
export const useCorporateProfile = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    User.getToken() ? ApiConfig.urls.corporate_profile.get_firm_details : null,
    config
  );
  return {
    corporateProfileData: data || {},
    error,
    loading: User.getToken() ? loading : false,
    mutate,
    isValidating,
  };
};

export const useMembershipDetails = (
  entity_type,
  config = {},
  token,
  course_id
) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    [ApiConfig.urls.promember.fetch_profile(entity_type, course_id), token],
    config
  );
  return {
    membership: data?.member ?? {},
    error,
    loading: token || User.getToken() ? loading : false,
    mutate,
    isValidating,
  };
};

export const useDashboardData = (state, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    state ? ApiConfig.urls.user.dashboard : null,
    config
  );
  return {
    dashboardData: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useDashboardCoursesData = (state, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    state ? ApiConfig.urls.user.dashboard_courses : null,
    config
  );
  return {
    dashboardCoursesData: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useUserDetails = (state, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    state ? ApiConfig.urls.user.user_details : null,
    config
  );
  return {
    userDetails: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useSubscriptions = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.purchases.subscriptions,
    config
  );
  return {
    subscriptions: data?.subscriptions || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useCorporateSubscriptions = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.purchases.corporate_subscriptions,
    config
  );
  return {
    subscriptions:
      data?.length > 0
        ? _.concat(...(data?.map((e) => e.subscription) ?? []))
        : [],
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useCorporatePaymentRequest = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.purchases.get_admin_payment_requests,
    config
  );
  return {
    requests: data?.get_payment_requests || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useCorporateInvoices = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.purchases.corporate_invoices,
    config
  );
  return {
    invoices: [...(data?.invoices || []), ...(data?.voluntary_invoices || [])],
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useInvoices = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.purchases.invoices,
    config
  );
  return {
    invoices: [...(data?.invoices || []), ...(data?.voluntary_invoices || [])],
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useAllPrograms = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.user.all_programs,
    config
  );
  return {
    programs: data || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useAllVoluntary = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.user.all_voluntary,
    config
  );
  return {
    voluntary_contributions: data?.voluntary_contributions || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useCertificates = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.user.certificates,
    config
  );
  return {
    certificates: data?.certificates || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useAcePoints = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.ace_points.fetch,
    config
  );
  return {
    acePoints: data?.ace_points || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useLiveCourseData = (
  courseType = "free_training",
  config = {}
) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.fetch_live_courses + courseType,
    config
  );
  return {
    data: data?.courses || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useProfileManager = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.user.user_relation_manager,
    config
  );
  return {
    data: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
