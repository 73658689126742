import PropTypes from "prop-types";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

const Pagination = ({
  totalEntries,
  entriesPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pages = [];
    let startPage;
    let endPage;

    if (totalPages <= 6) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 6;
      } else if (currentPage > totalPages - 3) {
        startPage = totalPages - 5;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 3;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handleClick(i)}
          className={`px-3 py-1 ${
            currentPage === i
              ? "pt-4 px-3 border-t-2 border-theme-orange "
              : "pt-4 px-3 text-gray-700"
          }`}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="flex justify-between">
      <button
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        className={`flex items-center text-theme-black-300 border-t pt-4 gap-3 px-3${
          currentPage === 1 ? "text-gray-300 border-gray-300" : "text-gray-700"
        }`}
      >
        <ArrowLeft size={20} color="#525252" weight={"bold"} />
        <p>Previous</p>
      </button>
      <div className="flex items-start justify-center h-full w-full border-t text-xl">
        {/* <div className="pt-4 px-3 border-t-2 border-theme-orange ">1</div>
        <div className="pt-4 px-3 border-t-2 border-theme-orange ">2</div>
        <div className="pt-4 px-3 border-t-2 border-theme-orange ">3</div>
        <div className="pt-4 px-3 border-t-2 border-theme-orange ">4</div> */}
        {renderPageNumbers()}
      </div>
      <button
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`flex items-center text-theme-black-300 border-t pt-4 gap-3 px-3${
          currentPage === totalPages ? "text-gray-300" : "text-gray-700"
        }`}
      >
        <p>Next</p>
        <ArrowRight size={20} color="#525252" weight={"bold"} />
      </button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalEntries: PropTypes.number,
  entriesPerPage: PropTypes.number,
};

export default Pagination;
