import PropTypes from "prop-types";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Envelope, Link, ShareNetwork } from "@phosphor-icons/react";
import { Fragment } from "react";
import LinkedInOrange from "../../assets/svgs/LinkedInOrange.svg";
import XLogo from "../../assets/svgs/XLogo.svg";
import WhatsappLogo from "../../assets/svgs/WhatsappLogo.svg";
import FacebookLogo from "../../assets/svgs/FacebookLogo.svg";
import _ from "lodash";
import { CustomToast } from "../../stories/Toast";

const ShareOptionPopup = ({ className = "", name = "", profileLink }) => {
  const links = [
    {
      icon: <img src={WhatsappLogo} height={24} width={24} alt="whatsapp" />,
      label: "Whatsapp",
      link: `https://api.whatsapp.com/send?text=${profileLink}`,
    },
    {
      icon: (
        <div className="bg-theme-orange-400 w-6 h-6 min-w-[24px] rounded-full flex items-center justify-center">
          <Envelope
            size={14}
            className="min-w-[14px] text-white"
            weight="fill"
          />
        </div>
      ),
      label: "Email",
      link: `mailto:?body=I%20found%20interesting%20profile%20on%20India%27s%20Top%20Directory%20for%20Personal%20Finance%20Professionals%20-%20Network%20FP%20${encodeURIComponent(
        profileLink
      )}&subject=Have a look at the profile of ${name}`,
    },
    {
      icon: <img src={LinkedInOrange} height={24} width={24} alt="Linkedin" />,
      label: "LinkedIn",
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        profileLink
      )}`,
    },
    {
      icon: <img src={XLogo} height={24} width={24} alt="X - Twitter" />,
      label: "X - Twitter",
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        profileLink
      )}&text=I%20found%20interesting%20profile%20on%20India%27s%20Top%20Directory%20for%20Personal%20Finance%20Professionals%20-%20Network%20FP.`,
    },
    {
      icon: <img src={FacebookLogo} height={24} width={24} alt="Facebook" />,
      label: "Facebook",
      link: `https://www.facebook.com/dialog/share?app_id=1380969479497797&display=popup&href=${encodeURIComponent(
        profileLink
      )}&redirect_uri=${encodeURIComponent(profileLink)}`,
    },
    {
      icon: (
        <div className="bg-theme-orange-400 w-6 h-6 min-w-[24px] rounded-full flex items-center justify-center">
          <Link size={14} className="min-w-[14px] text-white" weight="bold" />
        </div>
      ),
      label: "Copy Link",
      onClick: () => {
        navigator.clipboard.writeText(profileLink);
        CustomToast.success("Copied to clipboard", { duration: 2000 });
      },
    },
  ];
  return (
    <Menu
      as="div"
      className={classNames(
        "relative inline-flex items-center  text-left",
        className
      )}
    >
      <Menu.Button className="inline-flex justify-center text-sm font-semibold leading-5 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <div className="flex items-center justify-center w-10 xs:w-12 h-10 xs:h-12 px-2 py-[7px] rounded-full border border-theme-gray-100 cursor-pointer">
          <ShareNetwork
            weight="fill"
            fill="#F36C04"
            className="text-[20px] xs:text-[30px]"
          />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg top-full ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            {links.map((link, index) => {
              return (
                <Fragment key={index}>
                  <Menu.Item as="div">
                    {({ active }) => {
                      if (!_.isEmpty(link.link)) {
                        return (
                          <a
                            href={link.link}
                            target="_blank"
                            rel="noreferrer"
                            className={`${
                              active
                                ? "bg-theme-orange-400 text-white"
                                : "text-black-color"
                            } group flex gap-2 w-full items-center rounded-md px-2 py-1 my-1 text-sm`}
                          >
                            {link.icon}
                            {link.label}
                          </a>
                        );
                      }
                      return (
                        <button
                          className={`${
                            active
                              ? "bg-theme-orange-400 text-white"
                              : "text-black-color"
                          } group flex gap-2 w-full items-center rounded-md px-2 py-1 my-1 text-sm`}
                          onClick={link.onClick}
                        >
                          {link.icon}
                          {link.label}
                        </button>
                      );
                    }}
                  </Menu.Item>
                </Fragment>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

ShareOptionPopup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  profileLink: PropTypes.string,
};
export default ShareOptionPopup;
