/* eslint-disable no-unused-vars */
import {
  CheckCircle,
  HourglassMedium,
  ThumbsUp,
  WarningCircle,
} from "@phosphor-icons/react";
import { useNavigate } from "@reach/router";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import StyledButton from "./StyledButton";
import { Fragment } from "react";

const ApplicationStatus = ({
  program_title,
  data = {},
  entity_type,
  reason,
}) => {
  const navigate = useNavigate();
  const next_step = data.next_step;
  const SuggestionMessage = () => {
    const getSuggestionMessage = () => {
      if (["rejected", "approved", "provisional"].includes(data.status))
        return reason;
      if (next_step === 1) {
        return "Make a payment and continue with your application";
      }
      if (next_step < 3) return "Please continue with your application";
      if (_.isEmpty(reason) && !data.has_uploaded_documents) {
        return "Please upload your documents to complete the registration.";
      }
      return null;
    };

    if (
      next_step === 3 &&
      data.has_uploaded_documents &&
      !["rejected", "approved", "provisional"].includes(data.status)
    )
      return null;

    return (
      <div className="flex items-start w-full">
        <WarningCircle
          size={16}
          className="w-4 mx-0 min-w-[16px] mt-1 text-theme-orange"
        />
        <div className="block">
          <span className="ml-1 text-sm font-semibold leading-5 tracking-tight text-theme-black-color">
            {getSuggestionMessage()}
          </span>
          <span
            className="ml-3.5 text-sm leading-5 font-semibold tracking-tight text-theme-red cursor-pointer"
            onClick={() =>
              navigate(`/members/${entity_type}/register`, {
                state: {
                  course_id: data?.course_id,
                },
              })
            }
          >
            {next_step === 1
              ? "PAY NOW"
              : next_step < 3
              ? "VIEW APPLICATION"
              : data.status === "rejected"
              ? "RE-SUBMIT"
              : !data.has_uploaded_documents && "UPLOAD"}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="px-4">
      <div className="flex flex-col gap-3 w-full max-w-7xl border border-theme-gray-175 px-3 sm:px-5 pt-2 sm:pt-3 pb-4 rounded-2xl sm:mx-auto mt-10">
        <div className="flex flex-col xxs:flex-row lg:items-center justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-base xxs:text-lg sm:text-2xl font-bold">
              Your application status
            </p>
            <div className="flex gap-2 text-xs xxs:text-sm sm:text-lg font-semibold">
              Course name:{" "}
              <p className="text-theme-red-600">
                {data?.course_name ?? program_title}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <Stepper
            currentStep={
              ["rejected", "approved", "provisional"].includes(data.status)
                ? 4
                : next_step
            }
            steps={[
              data.status === "initiated" ? "Initiated" : "Payment",
              "Application",
              data.status === "rejected"
                ? "Rejected"
                : data.status === "provisional"
                ? "Provisional Member"
                : data.status === "approved"
                ? "Accepted"
                : "Accepted/Rejected",
            ]}
          />
        </div>
        {data.status !== "approved" ? <SuggestionMessage /> : null}
      </div>
    </div>
  );
};

const Stepper = ({ currentStep, steps = [] }) => {
  return (
    <>
      <div className="sm:hidden">
        {steps.map((step, index) => {
          return (
            <div key={"application-status-" + index} className="flex">
              <div className="flex flex-col items-center">
                <div className="relative">
                  <StatusIcon
                    status={
                      currentStep === index + 1
                        ? "inProgress"
                        : currentStep > index + 1
                        ? "approved"
                        : "pending"
                    }
                  />
                  <div
                    className={classNames(
                      "absolute top-0 left-14 text-sm xs:text-base"
                    )}
                  >
                    <div className="flex items-center sm:items-start w-fit sm:absolute">
                      <p className="font-medium w-14">Step {index + 1}</p>
                      <div className="flex flex-col">
                        <p className="text-gray-600">{step}</p>
                        {currentStep === index + 1 ? (
                          <span className="min-w-[95px]  w-fit inline-block mt-1 px-2 py-1 text-xs font-semibold text-blue-700 bg-blue-100 rounded-full">
                            {" "}
                            IN PROGRESS
                          </span>
                        ) : currentStep > index + 1 ? (
                          <span className=" w-fit inline-block mt-1 px-2 py-1 text-xs font-semibold text-green-700 bg-green-100 rounded-full">
                            COMPLETED
                          </span>
                        ) : (
                          <span className=" w-fit inline-block mt-1 px-2 py-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded-full">
                            PENDING
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {steps.length - 1 > index && (
                  <div
                    className={classNames(
                      "w-1 sm:w-full h-12 sm:h-1 rounded-full ",
                      index + 1 < currentStep
                        ? "bg-theme-green"
                        : " bg-theme-gray-225"
                    )}
                  ></div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="hidden sm:flex flex-col sm:flex-row gap-2 items-center mx-auto sm:mx-0 w-24 sm:w-full sm:pb-18">
        {steps.map((step, index) => {
          return (
            <Fragment key={"application-status-" + index}>
              <div
                className={classNames(
                  steps.length - 1 === index ? "sm:mr-20 pb-0" : "sm:pb-0",
                  "flex flex-col items-center sm:block sm:relative"
                )}
              >
                <StatusIcon
                  status={
                    currentStep === index + 1
                      ? "inProgress"
                      : currentStep > index + 1
                      ? "approved"
                      : "pending"
                  }
                />
                <div className="flex flex-col items-center sm:items-start w-fit sm:absolute">
                  <p className="font-medium">Step {index + 1}</p>
                  <p className="text-gray-600">{step}</p>

                  {currentStep === index + 1 ? (
                    <span className="min-w-[95px]  w-fit inline-block mt-1 px-2 py-1 text-xs font-semibold text-blue-700 bg-blue-100 rounded-full">
                      {" "}
                      IN PROGRESS
                    </span>
                  ) : currentStep > index + 1 ? (
                    <span className=" w-fit inline-block mt-1 px-2 py-1 text-xs font-semibold text-green-700 bg-green-100 rounded-full">
                      COMPLETED
                    </span>
                  ) : (
                    <span className=" w-fit inline-block mt-1 px-2 py-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded-full">
                      PENDING
                    </span>
                  )}
                </div>
              </div>
              {steps.length - 1 > index && (
                <div
                  className={classNames(
                    "w-1 sm:w-full h-12 sm:h-1 rounded-full ",
                    index + 1 < currentStep
                      ? "bg-theme-green"
                      : " bg-theme-gray-225"
                  )}
                ></div>
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

const StatusIcon = ({ status }) => {
  return status === "approved" ? (
    <div className="flex items-center justify-center w-12 min-h-12 rounded-full bg-theme-green-low-opacity">
      <CheckCircle weight="fill" size={24} className="text-theme-green" />
    </div>
  ) : status === "pending" ? (
    <div className="flex items-center justify-center w-12 min-h-12 rounded-full bg-theme-blue-opacity-10">
      <ThumbsUp weight="fill" size={24} className="text-theme-gray-600" />
    </div>
  ) : (
    <div className="flex items-center justify-center w-12 min-h-12 rounded-full bg-theme-blue-opacity-10">
      <HourglassMedium
        weight="fill"
        size={24}
        className="text-theme-blue-300"
      />
    </div>
  );
};
StatusIcon.propTypes = {
  status: PropTypes.string,
};

Stepper.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.array,
};

ApplicationStatus.propTypes = {
  data: PropTypes.object,
  entity_type: PropTypes.string,
  reason: PropTypes.string,
  program_title: PropTypes.string,
};
export default ApplicationStatus;
