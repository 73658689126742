import AccordionComponent from "./AccordionComponent";
import classNames from "classnames";
import PropTypes from "prop-types";
import ImageComponent from "./ImageComponent";
import moment from "moment";
import { useNavigate } from "@reach/router";

const SubscriptionAccordian = ({
  item,
  isDesktop,
  onPayNowClick,
  className,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col h-full p-2 bg-white md:p-4",
        className
      )}
    >
      <div className="flex h-full p-4 border">
        {isDesktop ? (
          <div className="flex flex-col w-full h-full">
            <AccordianHeading data={item} />
            <AccordianChildren
              data={item}
              onPayNowClick={() => onPayNowClick()}
            />
          </div>
        ) : (
          <AccordionComponent
            Heading={() => <AccordianHeading data={item} />}
            Children={(open) => (
              <AccordianChildren
                data={item}
                onPayNowClick={() => onPayNowClick()}
              />
            )}
            CloseButton={
              <button className="w-full px-6 pt-2 text-sm font-semibold text-center border-t text-theme-black-color">
                HIDE DETAILS
              </button>
            }
          />
        )}
      </div>
      <div className="hidden mt-1 text-xs leading-none tracking-tight max-h-3 md:flex text-theme-gray-500">
        {item.payment_status_hint}
      </div>
    </div>
  );
};

const AccordianHeading = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center text-base leading-none tracking-tight text-theme-black-color ">
      <ImageComponent
        errorComponent={
          <div className="w-8 min-w-[32px] h-8 bg-gray-200 mr-4"></div>
        }
        alt="program_logo"
        src={data.course_logo}
        className="object-cover w-8 min-w-[32px] h-8 mr-4"
      />
      <div>
        <div
          className={data?.course_type !== "pro_member" && "cursor-pointer"}
          onClick={
            data?.course_type === "pro_member"
              ? null
              : () => navigate("/programs/" + data.course_id + "/")
          }
        >
          {data.name}
        </div>
        <div className="flex-row items-center hidden mt-1 text-xs leading-5 tracking-tight md:flex text-theme-black-300">
          {data.subscription && (
            <div className="flex text-xs leading-none tracking-tight text-theme-gray-500">
              {data.subscription}
            </div>
          )}
          {data.subscription !== "Free" && (
            <span className="w-1 h-1 mx-1 rounded-full bg-theme-gray-400"></span>
          )}
          {data.subscription !== "Free" && (
            <div
              className={classNames(
                "flex text-xs leading-none tracking-tight",
                data.payment_status === "Paid"
                  ? "text-theme-gray-500"
                  : data.payment_status === "Overdue"
                  ? "text-theme-red-300"
                  : "text-yellow-500"
              )}
            >
              {data.payment_status}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const AccordianChildren = ({ data, onPayNowClick }) => {
  return (
    <div className="flex flex-col justify-between h-full py-4 md:pb-0">
      <div className="grid grid-cols-2 gap-4 pb-4 xl:grid-cols-3">
        <DataComponent title="STATUS" data={data.subscription_status} />
        {data.payment_status !== "Free" && (
          <DataComponent
            title="AMOUNT"
            data={`₹ ${
              data.amount
                ? data.amount === 0
                  ? data.amount
                  : data.amount.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      minimumIntegerDigits: 2,
                    })
                : 0
            }`}
          />
        )}
        {/* {data.start_date && (
          <DataComponent
            title="START DATE"
            data={moment(data.start_date, "DD-MM-YYYY").format("DD-MMM-YYYY")}
          />
        )} */}
        {data.due_date && (
          <DataComponent
            title="NEXT DUE DATE"
            data={moment(data.due_date, "DD-MM-YYYY").format("DD-MMM-YYYY")}
          />
        )}
      </div>
      {data.show_pay_now_btn && (
        // Condition to show button as well as Show Download Invoice instead of PAY NOW if the Table is Invoice
        <button
          onClick={() => onPayNowClick()}
          className="w-full px-6 py-2 text-sm font-semibold text-center text-white bg-theme-red"
        >
          PAY NOW
        </button>
      )}
    </div>
  );
};

const DataComponent = ({ title, data }) => {
  return (
    <div className="items-center">
      <div className="text-sm font-medium sm:text-xs text-neutral-600">
        {title}
      </div>
      <div className="text-base font-medium sm:text-sm text-theme-black-color">
        {data}
      </div>
    </div>
  );
};

SubscriptionAccordian.defaultProps = {
  isDesktop: true,
};

SubscriptionAccordian.propTypes = {
  MobileViewCard: PropTypes.element,
  className: PropTypes.any,
  isDesktop: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onPayNowClick: PropTypes.func.isRequired,
};
AccordianHeading.propTypes = {
  data: PropTypes.object.isRequired,
};
AccordianChildren.propTypes = {
  data: PropTypes.object.isRequired,
  onPayNowClick: PropTypes.func.isRequired,
};
DataComponent.propTypes = {
  data: PropTypes.string,
  title: PropTypes.string,
};
export default SubscriptionAccordian;
