import useSWR from "swr";

import { FETCHER } from "./APIService";
import { User } from "../store";

const getError = (error) => {
  if (!error) return error;
  const { response } = error;
  if (response) {
    const { data } = response;

    if (data.status === "500" || response?.status === 500) {
      return {
        error_messages:
          "Unable to connect with server. Try again after sometime",
      };
    } else if (data.error_code === "invalid_token") {
      User.clearToken();
      window.location.href = "/visitor";
      return {
        error_messages: "Unauthorized user",
      };
    } else {
      return {
        error_messages: data.error_messages,
        error_code: data.error_code,
      };
    }
  } else {
    const { message } = error;
    return { message };
  }
};

const swrConfig = {
  onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
    const { response } = error;
    if (retryCount >= 3) return;
    if (response && response.status === 404) return;
    setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
  },
};

export const useAPI = (url, { revalidateOption = {}, showError = true }) => {
  const { data, error, mutate, isValidating } = useSWR(url, FETCHER, {
    ...revalidateOption,
    ...swrConfig,
  });
  const customError = getError(error);

  return {
    data,
    error: customError,
    loading: !error && !data,
    mutate,
    isValidating,
  };
};
