import PropTypes from "prop-types";
import { Timer, Warning } from "@phosphor-icons/react";
import React from "react";
import classNames from "classnames";
import { useNavigate } from "@reach/router";

const RenewalBar = ({ entity_type, status }) => {
  const navigate = useNavigate();
  const getMessage = () => {
    const title =
      entity_type === "qpfp"
        ? "QPFP"
        : entity_type === "pro_member"
        ? "ProMembership"
        : "Community Membership";
    return status === "overdue"
      ? `Your ${title} has expired. Please pay now to resume your programs`
      : `Your ${title} next due date is coming soon. Please pay before the due date to avoid hindrance in your programs`;
  };
  const icon =
    status === "overdue" ? (
      <Timer
        size={20}
        weight="fill"
        className={"mr-3 min-w-[20px] text-red-300"}
      />
    ) : (
      <Warning
        size={20}
        weight="fill"
        className={"mr-3 min-w-[20px] text-amber-300"}
      />
    );

  if (!["overdue", "expires_soon"].includes(status)) return null;
  return (
    <React.Fragment>
      <div
        className={classNames(
          status === "overdue" ? "bg-red-50" : "bg-amber-50",
          "flex items-center justify-center"
        )}
      >
        <div className="flex flex-col justify-between px-4 py-3 rounded md:items-center md:flex-row max-w-7xl w-full">
          <div
            className={classNames(
              "flex items-start text-base font-medium leading-5 tracking-tight",
              status === "overdue" ? "text-red-800" : "text-amber-800"
            )}
          >
            {icon}
            {getMessage()}
          </div>
          <button
            onClick={() => navigate("/payments")}
            className={classNames(
              "h-8 mt-4 text-sm text-white rounded-sm md:mt-0 md:min-w-[128px]",
              status === "overdue" ? "bg-theme-red" : "bg-amber-400"
            )}
          >
            RENEW
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

RenewalBar.propTypes = {
  entity_type: PropTypes.string,
  prefillData: PropTypes.any,
  status: PropTypes.string,
};

export default RenewalBar;
