/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from "react";
import FileViewer from "react-file-viewer";
import { PDFTron } from "./PDFComponents/PDFTron";
import classNames from "classnames";
const ProToolView = (props) => {
  const url = props.url?.split("?")[0];
  const type = url?.split(".").pop();

  if (!props.url) {
    return (
      <div
        className={classNames(
          "flex w-full h-full items-center justify-center",
          props.className
        )}
      >
        No protool present
      </div>
    );
  }
  return (
    <div className={classNames("flex w-full h-full", props.className)}>
      {["pdf", "xls", "docx", "xlsx", "pptx", "ppt", "doc"].includes(type) ? (
        <PDFTron {...props} />
      ) : (
        <div className="w-full h-full">
          <FileViewer fileType={type} filePath={props.url} />
        </div>
      )}
    </div>
  );
};

export default ProToolView;
