import React from "react";
import PropTypes from "prop-types";

const RegisteredNationalEventSection = ({
  onClick,
  message = "You are registered for NFP National Summit 2025 happening on January 23-24, 2025 at Sahara Star, Mumbai!",
  ctaLabel = "Know more",
}) => {
  return (
    <div className="flex flex-col items-center justify-between md:items-center bg-red-50">
      <div className="flex flex-col justify-between py-2 px-4 md:items-center md:flex-row max-w-7xl w-full">
        <div className="flex items-center text-base font-semibold leading-5 tracking-tight text-theme-black-color">
          {message}
        </div>
        <button
          onClick={onClick}
          className="h-8 mt-4 text-sm text-white bg-black rounded-sm md:mt-0 md:w-32"
        >
          {ctaLabel}
        </button>
      </div>
    </div>
  );
};

RegisteredNationalEventSection.defaultProps = {
  onClick: () => {},
};

RegisteredNationalEventSection.propTypes = {
  ctaLabel: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
};

export default RegisteredNationalEventSection;
