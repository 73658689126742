/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import networkfp_logo from "../assets/svgs/NFP_logo.svg";
import _ from "lodash";
import { ArrowRight, CaretDown } from "@phosphor-icons/react";
import { useNavigate } from "@reach/router";
import PropTypes from "prop-types";

const Sidebar = ({
  className,
  isLogoClickable = true,
  sidebarItems,
  defaultState,
  infoBottomCard,
  onMenuClick,
}) => {
  const isActiveItem = (item) => {
    if ((item.dropdownList ?? []).length === 0) return defaultState === item.id;
    else {
      return (
        item.dropdownList.filter((e) => e.id === defaultState).length === 1
      );
    }
  };
  const navigate = useNavigate();
  const [currentDropdownOpenId, setCurrentDropdownOpenId] =
    useState(defaultState);

  useEffect(() => {
    sidebarItems.forEach((item) => {
      if ((item.dropdownList ?? []).length > 0) {
        if (item.dropdownList.filter((e) => e.id === defaultState).length > 0)
          setCurrentDropdownOpenId(item.id);
        return;
      } else {
        if (item.id === defaultState) {
          setCurrentDropdownOpenId(item.id);
        }
      }
    });
  }, [defaultState]);

  const renderLogo = () => (
    <div className="flex items-center justify-between w-full h-0 lg:h-12 lg:w-72">
      <img
        alt="networkFP-Logo"
        src={networkfp_logo}
        onClick={isLogoClickable ? () => navigate("/") : null}
        className="h-0 cursor-pointer lg:h-12"
      />
    </div>
  );
  if (_.isEmpty(sidebarItems)) {
    return renderLogo();
  }
  return (
    <div
      className={classNames(
        "bg-white flex flex-col relative h-[calc(100vh-70px)] lg:h-screen transition-all duration-300 lg:shadow-xl overflow-x-hidden w-full lg:w-72 lg:min-w-72 p-6",
        className
      )}
    >
      <div className="flex flex-col justify-start">
        {renderLogo()}
        <div className="flex flex-col justify-start mt-0 lg:mt-16">
          <ul>
            {sidebarItems.map((item, index) => {
              const { activeIcon, normalIcon } = item;
              const icon = isActiveItem(item) ? activeIcon : normalIcon;
              if (item.hide) return null;
              if (item.render)
                return (
                  <item.render
                    key={item.id}
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                        return;
                      }
                      setCurrentDropdownOpenId((prevState) =>
                        prevState === item.id ? null : item.id
                      );
                      if (onMenuClick && item.dropdownList.length === 0) {
                        onMenuClick(item);
                      }
                    }}
                  />
                );
              return (
                <React.Fragment key={item.id}>
                  <li
                    className={classNames(
                      "flex items-center mb-5 sidebar-list-item cursor-pointer p-3 justify-start",
                      isActiveItem(item) ? "menu-active" : ""
                    )}
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                        return;
                      }
                      setCurrentDropdownOpenId((prevState) =>
                        prevState === item.id ? null : item.id
                      );
                      if (onMenuClick && item.dropdownList.length === 0) {
                        onMenuClick(item);
                      }
                    }}
                  >
                    <div className="mr-2 min-w-[24px] min-h-[24px]">{icon}</div>
                    <div className="text-sm font-semibold tracking-tight ">
                      {item.label}
                    </div>
                    {item.dropdownList.length > 0 && (
                      <div className="flex justify-end ml-2 grow">
                        <CaretDown
                          weight="fill"
                          className={classNames(
                            "w-6 h-6 min-w-[24px] duration-150 transition-all",
                            currentDropdownOpenId === item.id && "rotate-180"
                          )}
                        />
                      </div>
                    )}
                  </li>
                  {(item.dropdownList ?? []).length > 0 && (
                    <div className="flex flex-col">
                      {currentDropdownOpenId === item.id &&
                        item.dropdownList?.map((subItem, index1) => {
                          if (subItem.hide) return null;
                          return (
                            <li
                              key={index1}
                              className={classNames(
                                "flex items-center mb-5 sidebar-list-item cursor-pointer p-3 justify-start",
                                defaultState === subItem.id
                                  ? "text-theme-black-color"
                                  : "text-theme-black-300"
                              )}
                              onClick={() =>
                                onMenuClick ? onMenuClick(subItem) : null
                              }
                            >
                              <div className="ml-8 mr-2 min-w-[16px] min-h-[16px]">
                                <ArrowRight size={16} weight="bold" />
                              </div>
                              <div
                                className={classNames(
                                  "text-sm leading-4 tracking-tight",
                                  defaultState === subItem.id
                                    ? "font-bold"
                                    : "font-semibold"
                                )}
                              >
                                {subItem.label}
                              </div>
                            </li>
                          );
                        })}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
      <div
        className={classNames("flex items-center", !infoBottomCard && "hidden")}
      >
        {infoBottomCard && infoBottomCard}
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  sidebarItems: [],
  className: "",
  isLogoClickable: true,
  onMenuClick: () => {},
};
Sidebar.propTypes = {
  className: PropTypes.string,
  isLogoClickable: PropTypes.bool,
  sidebarItems: PropTypes.array,
  defaultState: PropTypes.any,
  infoBottomCard: PropTypes.any,
  onMenuClick: PropTypes.func,
};
export default Sidebar;
