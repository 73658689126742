import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Star } from "@phosphor-icons/react";
import { navigate } from "@reach/router";

import ImageComponent from "../../stories/ImageComponent";
import LinearProgressBar from "./LinearProgressBar";
import StyledButton from "./StyledButton";
import { useProfile } from "../../context/userProvider";
import { NoneMemberStatus } from "../../Constants";

const CourseCard = ({
  id,
  title,
  subTitle,
  category,
  image,
  status,
  progress,
  rating,
  lastActivity,
  courseType,
  courseSection,
  duration,
  level,
  visitor = false,
  isComingSoon = false,
}) => {
  const { userDetails } = useProfile();
  const [navigationLink, setNavigationLink] = useState("/programs/" + id);

  const getCourseLevel = () => {
    switch (level) {
      case "beginner":
        return "Beginner";
      case "intermediate":
        return "Intermediate";
      case "advance":
        return "Advanced";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (courseType === "qpfp") {
      if (courseSection === "my-programs") {
        const qpfp =
          Object.keys(userDetails).length === 0
            ? false
            : !NoneMemberStatus.includes(userDetails?.qpfp_signup_status);
        if (qpfp) {
          setNavigationLink("/programs/" + id);
        }
      } else {
        setNavigationLink(`/members/qpfp/register?course_id=${id}`);
      }
    }
  }, [courseSection, courseType, id, userDetails]);

  return (
    <div
      className={classNames(
        "flex flex-col justify-between w-full border border-black-opacity-10 rounded-xl p-4 bg-white",
        (visitor || status === "inProgress") && "cursor-pointer",
        isComingSoon ? "min-h-[350px] sm:max-w-[276px]" : "min-h-[400px]"
      )}
      style={{
        boxShadow: "0px 0px 8px 0px #0000000F",
      }}
      onClick={
        (visitor || status === "inProgress") && !isComingSoon
          ? () => navigate(navigationLink)
          : () => {}
      }
    >
      <div className="flex flex-col space-y-4">
        <ImageComponent
          alt="Course Banner"
          src={image}
          errorComponent={
            <div className="border border-black-opacity-10 flex items-center justify-center rounded-md w-full xxs:max-w-[276px] h-[156px]"></div>
          }
          className="border border-black-opacity-10 object-cover rounded-md w-full xxs:max-w-[276px] h-[156px]"
        />
        <div className="mt-5 flex items-center justify-between">
          {category?.length > 0 ? (
            <p className="bg-theme-off-white px-1.5 py-1 text-theme-yellow text-xs">
              {category}
            </p>
          ) : (
            <div className="h-6"></div>
          )}
          {rating && (
            <div className="flex items-center justify-center gap-1">
              <Star weight="fill" color="#FFCC47" />
              <p className="color-theme-black-color font-bold text-sm">
                {rating}
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-1.5">
          <p className="text-lg font-bold line-clamp-1">{title}</p>
          <p className="line-clamp-3 min-h-18">{subTitle}</p>
        </div>
        <div
          className={`${
            visitor ? "hidden" : "flex"
          } justify-between items-center`}
        >
          <p className="text-sm text-black-300">{duration}</p>
          <p className="text-sm text-black-300">{getCourseLevel()}</p>
        </div>
      </div>
      {!isComingSoon && (
        <div
          className={`${
            visitor ? "flex" : "hidden"
          } justify-between items-center`}
        >
          <p className="text-sm text-black-300">{duration}</p>
          <p className="text-sm text-black-300">{getCourseLevel()}</p>
        </div>
      )}
      {visitor || isComingSoon ? null : status === "inProgress" ? (
        <div className="flex flex-col w-full gap-2">
          <LinearProgressBar lastActivity={lastActivity} progress={progress} />
          <StyledButton
            handleClick={() => {
              if (false) navigate(`/events/${id}/register`);
              navigate(navigationLink);
            }}
            title={"Continue Course"}
          />
        </div>
      ) : status === "complete" ? (
        <div className="flex flex-col gap-2.5">
          <div className="px-3 py-2 rounded-full bg-theme-green-low-opacity w-fit text-theme-green text-sm font-medium">
            COMPLETE
          </div>
          <StyledButton
            customClasses={"rounded-md w-full"}
            handleClick={() => {
              navigate(navigationLink);
            }}
            title={"Visit Again"}
          />
        </div>
      ) : status === "notStarted" ? (
        <StyledButton
          customClasses={"mt-9 rounded-md w-full"}
          handleClick={() => {
            if (false) navigate(`/events/${id}/register`);
            navigate(navigationLink);
          }}
          title={"Start Course"}
        />
      ) : (
        <StyledButton
          customClasses={"mt-9 rounded-md w-full"}
          handleClick={() => {
            navigate(navigationLink);
          }}
          title={"Know more"}
        />
      )}
    </div>
  );
};

CourseCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  status: PropTypes.string,
  progress: PropTypes.number,
  category: PropTypes.string,
  rating: PropTypes.any,
  lastActivity: PropTypes.any,
  courseType: PropTypes.string,
  courseSection: PropTypes.string,
  duration: PropTypes.string,
  level: PropTypes.oneOf(["beginner", "intermediate", "advance"]),
  visitor: PropTypes.bool,
  isComingSoon: PropTypes.bool,
};

export default CourseCard;
