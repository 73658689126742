import { useNavigate } from "@reach/router";

import StyledButton from "./StyledButton";
import { NoneMemberStatus } from "../../Constants";
import { useProfile } from "../../context/userProvider";

import qpfp_cert_image from "../../assets/images/QPFP-cert-image-temp.png";
import qpfp_camera from "../../assets/svgs/qpfp-video.svg";
import qpfp_tools from "../../assets/svgs/qpfp-tools.svg";
import qpfp_trainers from "../../assets/svgs/qpfp-trainers.svg";
import qpfp_coaching from "../../assets/svgs/qpfp-coaching.svg";
import qpfp_questions from "../../assets/svgs/qpfp-questions.svg";
import qpfp_batch from "../../assets/svgs/qpfp-batch.svg";
import banner from "../../assets/images/qpfp-banner.png";

const QPFPSection = () => {
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  const qpfp =
    Object.keys(userDetails).length === 0
      ? false
      : !NoneMemberStatus.includes(userDetails?.qpfp_signup_status);

  return (
    <div className="px-5 py-4 lg:px-20 lg:py-10 lg:pb-20">
      <div className="dashboard-banner-gradience relative p-10 flex flex-col items-stretch space-x-0 space-y-10 xl:flex-row xl:items-center xl:space-x-0 xl:space-y-0 max-w-7xl w-full mx-auto">
        <img
          src={banner}
          alt="Hero Banner"
          className="hidden md:block absolute top-0 left-0 w-full h-full object-cover -z-10"
        />
        <div className="w-full h-full flex justify-center items-center mr-0 xl:w-3/4 xl:mr-10">
          <img
            src={qpfp_cert_image}
            alt="QPFP Cert Sample"
            className="h-auto xl:h-80"
          />
        </div>

        <div className="flex flex-col justify-center space-y-5">
          <div className="flex flex-col space-y-3">
            <h3 className="font-bold text-3xl text-white xl:text-4xl">
              {qpfp ? "Your QPFP Program" : "Join QPFP® Certification Program"}
            </h3>
            <p className="text-xl text-white">
              {qpfp
                ? "You are on your journey to become a Qualified Personal Finance Professional (QPFP). Go to your QPFP Program dashboard to start, continue and complete the program."
                : "QPFP Certification Program is 6 month / 100 hours highly practical and structured education program for financial advisors and distributors. Explore and join the next batch staring on May 30th, 2024"}
            </p>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
              <div className="flex space-x-2 items-center">
                <img src={qpfp_camera} alt="qpfp feature" />
                <p className="text-xl text-white">100+ Training Hours</p>
              </div>
              <div className="flex space-x-2 items-center">
                <img src={qpfp_tools} alt="qpfp feature" />
                <p className="text-xl text-white">50+ Tools & Resources</p>
              </div>
              <div className="flex space-x-2 items-center">
                <img src={qpfp_trainers} alt="qpfp feature" />
                <p className="text-xl text-white">30+ Practitioner Trainers</p>
              </div>
              <div className="flex space-x-2 items-center">
                <img src={qpfp_coaching} alt="qpfp feature" />
                <p className="text-xl text-white">1-1 Coaching Call</p>
              </div>
              <div className="flex space-x-2 items-center">
                <img src={qpfp_questions} alt="qpfp feature" />
                <p className="text-xl text-white">1000+ Practical Questions</p>
              </div>
              <div className="flex space-x-2 items-center">
                <img src={qpfp_batch} alt="qpfp feature" />
                <p className="text-xl text-white">200+ Learners Batch</p>
              </div>
            </div>
          </div>

          {userDetails?.qpfp_certification_status === "active" ? (
            <StyledButton
              buttonStyle="outline-primary"
              title="Go to QPFP Dashboard"
              customClasses="font-medium rounded-md sm:max-w-[300px]"
              padding="px-6 py-3"
              handleClick={() => {
                navigate("/qpfp-certificant/dashboard");
              }}
            />
          ) : userDetails?.qpfp_signup_status === "under_review" ? (
            <p className="text-xl text-white font-bold">
              Your application is under review
            </p>
          ) : qpfp ? (
            userDetails.qpfp_certification_status === "inactive" ? (
              <StyledButton
                buttonStyle="outline-primary"
                title="Go to QPFP Program"
                customClasses="font-medium rounded-md sm:max-w-[300px]"
                padding="px-6 py-3"
                handleClick={() => {
                  navigate("/my-programs");
                }}
              />
            ) : (
              <StyledButton
                buttonStyle="outline-primary"
                title="Go to QPFP Dashboard"
                customClasses="font-medium rounded-md sm:max-w-[300px]"
                padding="px-6 py-3"
                handleClick={() => {
                  navigate("/qpfp-certificant/dashboard");
                }}
              />
            )
          ) : userDetails?.qpfp_certification_status === "not_registered" ? (
            <StyledButton
              buttonStyle="outline-primary"
              title="Apply for QPFP"
              customClasses="font-medium rounded-md sm:max-w-[300px]"
              padding="px-6 py-3"
              handleClick={() => {
                navigate(
                  "/qpfp-certificant/registration/" +
                    userDetails?.qpfp_certification_course_id
                );
              }}
            />
          ) : (
            <div className="flex flex-col items-stretch space-x-0 space-y-6 xl:flex-row xl:space-x-6 xl:space-y-0">
              <StyledButton
                buttonStyle="outline-primary"
                title="Apply for QPFP"
                customClasses="font-medium rounded-md"
                padding="px-6 py-3"
                // INFO: Temp redirection
                handleClick={() =>
                  navigate("/all-courses", { state: { course_type: "qpfp" } })
                }
              />

              <StyledButton
                buttonStyle="outline-white"
                title="Download Brochure"
                customClasses="font-medium rounded-md"
                padding="px-6 py-3"
                handleClick={() => {
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href =
                    "https://nfp-assets.s3.ap-south-1.amazonaws.com/QPFP+Brochure_2024_V2.pdf";
                  a.target = "_blank";
                  document.body.appendChild(a);
                  a.click();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QPFPSection;
