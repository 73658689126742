/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import networkfp_logo from "../../assets/svgs/NFP_logo.svg";
import { useNavigate } from "@reach/router";
import { User as UserData } from "../../store";
import { useState } from "react";
import { List, SignOut, UserGear, X } from "@phosphor-icons/react";
import HeaderNotificationIcon from "../../stories/HeaderNotificationIcon";
import HeaderProfileIcon from "../../stories/HeaderProfileIcon";
import AccountCredentialModal from "../Modals/AccountCredentialModal";
import ConfirmLogoutModal from "../Modals/ConfirmLogoutModal";
import { useSWRConfig } from "swr";
import { UserService } from "../../services/UserService";
import StyledButton from "../CommonComponents/StyledButton";
import LoginUserModal from "../Modals/LoginUserModal";
import SignupUserModal from "../Modals/SignupUserModal";
import { PopupType, useLoginModal } from "../../context/loginModalProvider";
import classNames from "classnames";

const NavBar = ({ mobileSideBarComponent }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { handleOpenPopup } = useLoginModal();

  const isLoggedIn = !!UserData.getToken();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [openAccountCreedPopup, setOpenAccountCreedPopup] = useState(false);

  const { cache } = useSWRConfig();
  const SidebarToggleIcon = ({ isSidebarOpen }) => {
    if (isSidebarOpen)
      return (
        <X className="w-6 h-6" onClick={() => setIsOpen((value) => !value)} />
      );
    else
      return (
        <List
          className="w-6 h-6"
          onClick={() => setIsOpen((value) => !value)}
        />
      );
  };

  SidebarToggleIcon.propTypes = {
    isSidebarOpen: PropTypes.bool,
  };

  return (
    <div className="relative">
      <div className="flex flex-row items-center justify-between px-5 py-4 xl:px-20 bg-white shadow-nav-shadow">
        <div className="flex items-center gap-2">
          {isLoggedIn && (
            <div
              className={classNames(
                "flex items-center justify-center w-6 h-6 mr-3",
                "lg:hidden"
              )}
            >
              <SidebarToggleIcon isSidebarOpen={isOpen} />
            </div>
          )}
          <img
            alt="network fp logo"
            src={networkfp_logo}
            className="h-9 sm:h-12 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        {isLoggedIn ? (
          <div className="flex items-center justify-center space-x-2">
            <div className="flex items-center justify-center flex-shrink-0 mr-3">
              <HeaderNotificationIcon />
            </div>
            <div className="flex items-center self-center flex-shrink-0">
              <HeaderProfileIcon
                tabs={[
                  {
                    name: "Edit Profile",
                    icon: () => <UserGear size={24} />,
                    onClick: () => {
                      navigate("/public-profile");
                    },
                  },
                  {
                    name: "Edit Account",
                    icon: () => <UserGear size={24} />,
                    onClick: () => {
                      setOpenAccountCreedPopup(true);
                    },
                  },
                  {
                    name: "Logout",
                    href: "login",
                    icon: () => <SignOut size={24} />,
                    onClick: async () => {
                      setShowLogoutModal(true);
                    },
                  },
                ]}
                openLoginModal={true}
              />
            </div>
          </div>
        ) : (
          <div className="flex gap-3 lg:gap-6">
            <StyledButton
              buttonStyle="transparent-primary"
              title="Login"
              customClasses="font-medium"
              padding="p-0"
              handleClick={() => handleOpenPopup(PopupType.LOGIN)}
            />
            <StyledButton
              buttonStyle="outline-primary"
              title="Join for Free"
              customClasses="font-medium rounded-md"
              padding="px-6 py-3"
              handleClick={() => handleOpenPopup(PopupType.SIGNUP)}
            />
          </div>
        )}

        {openAccountCreedPopup && (
          <AccountCredentialModal
            open={openAccountCreedPopup}
            onClose={() => setOpenAccountCreedPopup(false)}
          />
        )}
        {showLogoutModal && (
          <ConfirmLogoutModal
            open={showLogoutModal}
            onConfirm={async () => {
              setShowLogoutModal(false);
              const fcm_token = localStorage.getItem("fcm_token");
              if (fcm_token) {
                try {
                  await UserService.deleteUserDevice(fcm_token);
                } catch (e) {
                } finally {
                  localStorage.removeItem("fcm_token");
                }
              }
              navigate("/visitor");
              window.fcWidget.user
                .get()
                .then(async (res) => {
                  window.fcWidget.user.clear();
                })
                .catch((e) => console.log(e));
              // await window.fcWidget.destroy();
              window.fcWidget.init({
                token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
                host: "https://wchat.in.freshchat.com",
              });
              UserData.clearToken();
              cache.clear();
            }}
            onClose={() => setShowLogoutModal(false)}
          />
        )}

        <LoginUserModal
          prefill_data={{ email: "" }}
          onLoginSuccess={() => {
            setTimeout(() => {
              handleOpenPopup(PopupType.LOGIN);
            }, 1000);
          }}
        />
        <SignupUserModal prefill_data={{}} />
      </div>
      {isOpen && isLoggedIn && mobileSideBarComponent}
    </div>
  );
};
NavBar.propTypes = {
  mobileSideBarComponent: PropTypes.element,
};

export default NavBar;
