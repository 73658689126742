import classNames from "classnames";
import React from "react";
import Card from "./Card";
import PropTypes from "prop-types";

const ProUpgradeCard = ({
  headingClassName,
  descriptionClassName,
  heading,
  description,
  content_label = "What you will get",
  benefits,
  className,
  cta,
}) => {
  return (
    <Card
      className={className}
      header={
        <div
          className={classNames(
            "flex items-center text-base font-semibold leading-5 text-left text-theme-black-color tracking-tighter mb-3",
            headingClassName
          )}
        >
          {heading}
        </div>
      }
      cta={cta}
    />
  );
};
ProUpgradeCard.defaultProps = {
  heading: "",
  description: "",
  benefits: [],
};
ProUpgradeCard.propTypes = {
  benefits: PropTypes.array,
  className: PropTypes.string,
  content_label: PropTypes.string,
  cta: PropTypes.element,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  heading: PropTypes.string,
  headingClassName: PropTypes.string,
};

export default ProUpgradeCard;
