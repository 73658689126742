import PropTypes from "prop-types";

const CurriculumDetails = ({ points }) => {
  return (
    <div className="flex-1 border border-black-opacity-10 w-full p-5 flex flex-col gap-5">
      <p className="text-3xl text-theme-black-500 font-bold">
        Course Structure
      </p>

      {points?.map((point, index) => (
        <div
          className="flex items-center gap-2 theme-black-300 text-xl"
          key={"curriculum-point-" + index}
        >
          <div className="h-1.5 w-1.5 rounded-full bg-theme-orange"></div>
          <p>{point?.name}</p>
        </div>
      ))}
    </div>
  );
};

CurriculumDetails.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default CurriculumDetails;
