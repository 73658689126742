import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import progressSvg from "../../../assets/svgs/progress.svg";
import Button from "../../../stories/Button";
import { useNavigate } from "@reach/router";

import SessionTracker from "./SessionTracker";
import { CertificateBadgesCard, LeaderboardCard } from "./Cards";
import { QPFPProgressBar } from "../../../stories/ProgressBar";
import { CircleNotch } from "@phosphor-icons/react";
import _ from "lodash";
import { romanNumbers } from "../../../util/romanNumbers";
import { useProfile } from "../../../context/userProvider";

const QPFPProgressTracker = ({
  curriculum,
  isLoading,
  qpfpQuizTracker,
  qpfpProgressTracker,
  lastViewContentDetails,
  onLockedClick,
}) => {
  const navigate = useNavigate();
  const [showQuizTracker, setShowQuizTracker] = useState(false);
  const [onTrackStatus, setOnTrackStatus] = useState("");
  const { userDetails } = useProfile();

  useEffect(() => {
    if (_.isEmpty(qpfpProgressTracker)) {
      return;
    }
    let avgBatchPer = 0;
    let avgUserPer = 0;
    qpfpProgressTracker.modules.forEach((module) => {
      avgBatchPer += module.batch_percentage ?? 0;
      avgUserPer += module.module_complete_percentage ?? 0;
    });
    if (avgUserPer / avgBatchPer > 0.8) {
      setOnTrackStatus("On Track");
      return;
    }
    if (avgUserPer / avgBatchPer > 0.4) {
      setOnTrackStatus("Progressing");
      return;
    }
    setOnTrackStatus("Off Track");
    return;
  }, [qpfpProgressTracker]);

  if (curriculum.course_type !== "qpfp")
    return <React.Fragment></React.Fragment>;

  if (isLoading) {
    return (
      <div className="flex flex-col mt-2">
        <CircleNotch className="mx-auto animate-spin" size={32} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5 rounded-sm p-3 lg:p-6 bg-white">
      <div className="w-full p-4 bg-theme-orange/10 rounded md:rounded-2xl flex flex-col md:flex-row gap-4 items-center">
        <div className="hidden md:flex w-6 h-6 min-w-[24px] md:w-14 md:h-14 md:min-w-[56px] rounded-full bg-theme-orange/70 justify-center items-center">
          <img
            src={progressSvg}
            className="h-4 w-4 md:h-8 md:w-8"
            alt="Progress tracker"
          />
        </div>
        <div className="flex grow flex-col gap-1 lg:gap-3 text-theme-black-color">
          <div className="text-sm flex items-center gap-3 font-bold leading-4 lg:leading-5 tracking-tight lg:text-xl md:text-base">
            <div className="flex md:hidden w-6 h-6 min-w-[24px] md:w-14 md:h-14 md:min-w-[56px] rounded-full bg-theme-orange/70 justify-center items-center">
              <img
                src={progressSvg}
                className="h-4 w-4 md:h-8 md:w-8"
                alt="Progress tracker"
              />
            </div>
            My Progress
          </div>
          <div className="text-xs md:text-sm leading-4 lg:leading-5 tracking-tight lg:text-base md:flex">
            QPFP Candidates should attempt / clear 36 topic tests, 3 Level Exams
            & 1 Final Exam to get awarded with QPFP Certification.
          </div>
        </div>
        <Button
          className="md:max-w-[250px] w-full !h-8 lg:!h-10"
          onClick={() =>
            navigate(
              `/programs/${curriculum?.id}/learn/${lastViewContentDetails?.session_content_id}`
            )
          }
        >
          <div className="line-clamp-1 overflow-ellipsis">
            Continue: {lastViewContentDetails?.session_content_title}
          </div>
        </Button>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex w-full gap-4 items-center">
          <QPFPModuleWiseProgressBar
            modules={qpfpProgressTracker?.modules ?? []}
            courseCompletionPercentage={
              qpfpProgressTracker?.course_completion_percentage ?? 0
            }
            isFinalExamCompleted={
              userDetails.qpfp_certification_status !== "inactive"
            }
          />
          <button
            className="max-w-[106px] lg:flex hidden w-full items-center justify-center h-9 rounded-full bg-theme-black-color text-white"
            onClick={() => {
              setShowQuizTracker((prev) => !prev);
            }}
          >
            {showQuizTracker ? "Minimize" : "Expand All"}
          </button>
        </div>
        <button
          className="flex mt-3 lg:hidden w-full h-7 text-xs font-medium items-center justify-center rounded-full bg-theme-black-color/10 text-theme-black-color"
          onClick={() => {
            setShowQuizTracker((prev) => !prev);
          }}
        >
          {showQuizTracker ? "Minimize" : "Expand All"}
        </button>
      </div>
      {showQuizTracker &&
        qpfpQuizTracker?.map((module, index) => (
          <SessionTracker
            module={module}
            index={index}
            key={module.id}
            courseId={curriculum.id}
            onLockedClick={(e) => {
              onLockedClick(e);
            }}
          />
        ))}
      <div className="flex gap-5 flex-col lg:flex-row">
        <CertificateBadgesCard
          moduleStatuses={
            qpfpProgressTracker?.modules?.map(
              (m) => m.module_status === "Complete"
            ) ?? []
          }
          certificateStatus={
            userDetails?.qpfp_certification_status !== "inactive"
          }
          onClick={() =>
            navigate(`/programs/${curriculum?.id}/certificates`, {
              state: {
                course_id: curriculum.id,
                course_name: curriculum.title,
              },
            })
          }
        />
        <LeaderboardCard
          completionPercentage={
            qpfpProgressTracker?.course_completion_percentage ?? 0
          }
          trackStatus={onTrackStatus}
          onClick={() => {
            navigate(`/programs/${curriculum?.id}/leaderboard`, {
              state: {
                course_id: curriculum.id,
                course_name: curriculum.title,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

QPFPProgressTracker.propTypes = {
  curriculum: PropTypes.object,
  isLoading: PropTypes.bool,
  lastViewContentDetails: PropTypes.shape({
    session_content_id: PropTypes.any,
    session_content_title: PropTypes.any,
  }),
  qpfpProgressTracker: PropTypes.any,
  qpfpQuizTracker: PropTypes.any,
  onLockedClick: PropTypes.func,
};

export const QPFPModuleWiseProgressBar = ({
  modules,
  courseCompletionPercentage,
  showPercentage = true,
  isFinalExamCompleted = false,
}) => {
  return (
    <div className="flex flex-col w-full items-start lg:h-16">
      <div className="flex flex-col gap-6 lg:flex-row lg:gap-3 items-start w-full">
        {modules?.map((module, index) => (
          <QPFPProgressBar
            key={index}
            label={`Level ${romanNumbers[index]}`}
            userPercent={
              ((module?.module_complete_percentage ?? 0) * 100) /
              (module?.total_percentage ?? 1)
            }
            batchPercent={
              modules[index + 1]?.batch_percentage > 0
                ? 0
                : ((module?.batch_percentage ?? 0) * 100) /
                  (module?.total_percentage ?? 1)
            }
            level={index + 1}
          />
        ))}
        <QPFPProgressBar
          label="Final Exam"
          userPercent={isFinalExamCompleted ? 100 : 0}
          batchPercent={0}
          level={4}
          className="lg:max-w-186"
        />
      </div>
      {showPercentage && (
        <div className="lg:text-base text-xs flex w-full justify-between items-center mt-2">
          <div className="">
            {_.round(courseCompletionPercentage ?? 0, 1)}% Completed
          </div>
          <div className="">
            {_.round(100 - (courseCompletionPercentage ?? 0), 1)}% Remaining
          </div>
        </div>
      )}
    </div>
  );
};

QPFPModuleWiseProgressBar.propTypes = {
  courseCompletionPercentage: PropTypes.number,
  modules: PropTypes.array,
  isFinalExamCompleted: PropTypes.bool,
  showPercentage: PropTypes.bool,
};

export default QPFPProgressTracker;
