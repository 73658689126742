import PropTypes from "prop-types";
import React, { useState } from "react";
import networkfp_logo from "../../assets/svgs/NFP_logo.svg";
import QPFPFullOrangeLogoTransparent from "../../assets/images/QPFPFullOrangeLogoTransparent.png";
import { useProfile } from "../../context/userProvider";
import { getDirectoryMemberURL } from "../../util/getURLs";
import ProfileStats from "../ProfileStats";
import { useNavigate } from "@reach/router";
import AccountCredentialModal from "../../components/Modals/AccountCredentialModal";
import ConfirmLogoutModal from "../../components/Modals/ConfirmLogoutModal";
import { UserService } from "../../services/UserService";
import HeaderNotificationIcon from "../HeaderNotificationIcon";
import HeaderProfileIcon from "../HeaderProfileIcon";
import { List, SignOut, UserGear, X } from "@phosphor-icons/react";
import { User } from "../../store";
import { useSWRConfig } from "swr";
import classNames from "classnames";
import QPFPSidebar from "./QPFPSidebar";

const NetworkFPLogo = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex items-center justify-start h-12 cursor-pointer"
      onClick={() => {
        navigate("/");
      }}
    >
      <img
        alt="network fp logo"
        src={networkfp_logo}
        className="h-9 lg:h-12 cursor-pointer"
      />
    </div>
  );
};

const QPFPLogo = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex items-center justify-start h-15"
      onClick={() => {
        navigate("/qpfp-certificant/");
      }}
    >
      <img
        alt="network fp orange logo"
        src={QPFPFullOrangeLogoTransparent}
        className="h-9 lg:h-15 cursor-pointer"
      />
    </div>
  );
};

const SidebarToggleIcon = ({ isSidebarOpen, onToggle }) => {
  if (isSidebarOpen)
    return <X className="w-6 h-6" onClick={() => onToggle(false)} />;
  else return <List className="w-6 h-6" onClick={() => onToggle(true)} />;
};

SidebarToggleIcon.propTypes = {
  isSidebarOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

const QPFPHeader = ({ className }) => {
  const { userDetails } = useProfile();
  const navigate = useNavigate();
  const { cache } = useSWRConfig();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openAccountCreedPopup, setOpenAccountCreedPopup] = useState(false);

  return (
    <>
      <div
        className={classNames(
          "py-2.5 px-6 flex justify-between bg-white z-20",
          className
        )}
      >
        <div className="flex items-center gap-5">
          <div
            className={classNames(
              "flex items-center justify-center w-6 h-6 mr-3 lg:hidden"
            )}
          >
            <SidebarToggleIcon
              isSidebarOpen={isOpen}
              onToggle={(val) => setIsOpen(val)}
            />
          </div>
          <NetworkFPLogo />
          <div className="h-full w-[1px] bg-neutral-300" />
          <QPFPLogo />
        </div>
        <div className="flex items-center justify-center space-x-2">
          {["active", "expires_soon", "overdue"].includes(
            userDetails.qpfp_certification_status
          ) && (
            <ProfileStats
              className="hidden cursor-pointer md:flex"
              type="points"
              stats={`${
                userDetails?.pro_member_attributes?.ace_points ?? 0
              } ACE Points`}
              onClick={() => navigate("/qpfp-certificant/ace-points-tracker")}
            />
          )}
          {userDetails.is_pro_member &&
            ["approved", "expires_soon"].includes(
              userDetails.pm_signup_status
            ) && (
              <a
                href={getDirectoryMemberURL(userDetails?.profile_slug)}
                target="_blank"
                rel="noreferrer"
              >
                <ProfileStats
                  className="hidden cursor-pointer md:flex"
                  type="views"
                  stats={`${
                    userDetails?.pro_member_attributes?.profile_views ?? 0
                  } Profile Views`}
                />
              </a>
            )}

          <div className="flex items-center justify-center flex-shrink-0 mr-3">
            <HeaderNotificationIcon />
          </div>
          <div className="flex items-center self-center flex-shrink-0">
            <HeaderProfileIcon
              tabs={[
                {
                  name: "Edit Profile",
                  icon: () => <UserGear size={24} />,
                  onClick: () => {
                    navigate("/public-profile");
                  },
                },
                {
                  name: "Edit Account",
                  icon: () => <UserGear size={24} />,
                  onClick: () => {
                    setOpenAccountCreedPopup(true);
                  },
                },
                {
                  name: "Logout",
                  href: "login",
                  icon: () => <SignOut size={24} />,
                  onClick: async () => {
                    setShowLogoutModal(true);
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="lg:hidden w-full block">
          <QPFPSidebar />
        </div>
      )}
      {openAccountCreedPopup && (
        <AccountCredentialModal
          open={openAccountCreedPopup}
          onClose={() => setOpenAccountCreedPopup(false)}
        />
      )}
      {showLogoutModal && (
        <ConfirmLogoutModal
          open={showLogoutModal}
          onConfirm={async () => {
            setShowLogoutModal(false);
            const fcm_token = localStorage.getItem("fcm_token");
            if (fcm_token) {
              try {
                await UserService.deleteUserDevice(fcm_token);
              } catch (e) {
              } finally {
                localStorage.removeItem("fcm_token");
              }
            }
            navigate("/visitor");
            window.fcWidget.user
              .get()
              .then(async (res) => {
                window.fcWidget.user.clear();
              })
              .catch((e) => console.log(e));
            // await window.fcWidget.destroy();
            window.fcWidget.init({
              token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
              host: "https://wchat.in.freshchat.com",
            });
            User.clearToken();
            cache.clear();
          }}
          onClose={() => setShowLogoutModal(false)}
        />
      )}
    </>
  );
};

QPFPHeader.propTypes = {
  className: PropTypes.string,
};

export default QPFPHeader;
