import { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
import _ from "lodash";

import StyledButton from "../CommonComponents/StyledButton";
import ImageComponent from "../../stories/ImageComponent";
import StarRatings from "../StarRatings";
import { PopupType, useLoginModal } from "../../context/loginModalProvider";
import { User } from "../../store";
import { useProfile } from "../../context/userProvider";
import { CourseService } from "../../services/CourseService";
import { CustomToast } from "../../stories/Toast";
import parseAmount from "../../util/parseAmount";

const CourseDetailsHeroSection = ({
  id,
  title,
  subTitle,
  category,
  ratings,
  duration,
  level,
  thumbnail,
  lastViewContentTitle,
  courseType,
  hasStarted,
  hasRegistered,
  contents,
  pricing,
  eventId,
}) => {
  const { userDetails } = useProfile();
  const { handleOpenPopup } = useLoginModal();
  const generalPaidCourseCategories = [
    "general_paid",
    "event",
    "webinar",
    "workshop",
    "regional_event",
    "national_event",
  ];

  const getCourseLevel = () => {
    switch (level) {
      case "beginner":
        return "Beginner";
      case "intermediate":
        return "Intermediate";
      case "advance":
        return "Advanced";
      default:
        return "";
    }
  };

  const [coursePrice, setCoursePrice] = useState(null);

  useEffect(() => {
    setCoursePrice(pricing);
  }, [pricing]);

  return (
    <div
      className="min-h-[411px] bg-gradient-to-r flex items-center justify-center w-full"
      style={{
        background: "linear-gradient(96.04deg, #FF3632 10.73%, #FF8D4E 93.75%)",
      }}
    >
      <div className="w-full flex flex-col items-center justify-center lg:flex-row lg:justify-between px-5 py-4 lg:px-20">
        <div className="flex flex-col items-start justify-center text-white space-y-6 w-full max-w-[822px]">
          <p className="font-bold text-4xl">{title}</p>
          <p className="text-xl font-medium">{subTitle}</p>
          <div className="flex items-center justify-between w-full max-w-[320px] space-x-2">
            <p className="bg-theme-off-white px-1.5 py-1 text-theme-yellow text-xs">
              {category?.value}
            </p>
            {_.isNull(ratings) ? null : (
              <div className="flex items-center justify-center space-x-1">
                <StarRatings rating={ratings} />
                <p className="color-theme-black-color font-bold text-sm">
                  {ratings}
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center w-full space-x-4 text-lg font-semibold">
            <p className="">{duration}</p>
            <div className="w-1.5 h-1.5 rounded-full bg-white"></div>
            <p className="color-theme-black-color">{getCourseLevel()}</p>
          </div>
        </div>
        <div className="min-w-auto relative z-50 lg:min-w-96">
          <div
            className="static flex flex-col space-y-6 w-full max-w-[412px] border border-black-opacity-10 rounded-md mt-10 bg-white pb-2.5 lg:mt-0 lg:absolute lg:-top-28"
            style={{
              boxShadow: "0px 4px 4px 0px #00000026",
            }}
          >
            {/* <div className="w-full h-[232px] border-b border-black-opacity-10"></div> */}
            <ImageComponent
              alt="Course Banner"
              src={thumbnail}
              errorComponent={
                <div className="w-full h-[232px] border-b border-black-opacity-10"></div>
              }
              className="w-full h-[232px] border-b border-black-opacity-10 rounded-md"
            />
            {courseType === "free_training" ||
            courseType === "new_user" ||
            courseType === "demo" ? (
              <div className="flex flex-col space-y-4 px-4">
                <div className="flex flex-col space-y-1">
                  <div className="flex items-center space-x-1">
                    <p className="font-bold text-theme-orange">Included with</p>
                    <div className="flex justify-center items-center px-[5px] py-[3px] bg-theme-green rounded-full">
                      <p className="font-medium text-xxs text-white">
                        Basic Account
                      </p>
                    </div>
                  </div>
                  <p className="text-sm text-theme-black-300">
                    {coursePrice
                      ? `*Free worth ₹${parseAmount(coursePrice)}`
                      : null}
                  </p>
                </div>
                {contents?.map((feature) => (
                  <div
                    key={`feature-${feature?.id}`}
                    className="flex space-x-2 items-center"
                  >
                    <div className="w-2 h-2 rounded-full bg-theme-orange" />
                    <p className="text-sm text-theme-black-300">
                      {feature?.name}
                    </p>
                  </div>
                ))}
                <StyledButton
                  title={
                    User.getToken()
                      ? hasStarted
                        ? "Continue Course"
                        : "Start Course"
                      : "Join for Free to Start"
                  }
                  handleClick={async () => {
                    if (User.getToken()) {
                      try {
                        const response = await CourseService.createUserCourse(
                          id
                        );
                        CustomToast.success(
                          response.message ?? "Course Added Successfully"
                        );
                        navigate(`/programs/${id}/learn`);
                      } catch (e) {
                        if (
                          e?.error_messages[0] ===
                          "Course is already assigned for this user"
                        ) {
                          navigate(`/programs/${id}/learn`);
                        } else {
                          console.log(e);
                          CustomToast.error(e.error_messages[0]);
                        }
                      }
                    } else {
                      handleOpenPopup(PopupType.SIGNUP);
                    }
                  }}
                />
                {User.getToken() && !hasStarted && (
                  <p className="text-sm leading-4 font-medium">
                    *Please click on the “Start Coufrse” button to unlock and
                    begin the course.
                  </p>
                )}
              </div>
            ) : courseType?.includes("pro_member") ? (
              <div className="flex flex-col space-y-4 px-4">
                <div className="flex flex-col space-y-1">
                  <div className="flex items-center space-x-1">
                    <p className="font-bold text-theme-orange">Included with</p>
                    <div className="flex justify-center items-center px-[5px] py-[3px] bg-theme-red rounded-full">
                      <p className="font-medium text-xxs text-white">
                        Pro Account
                      </p>
                    </div>
                  </div>
                  <p className="text-sm text-theme-black-300">
                    {coursePrice
                      ? `*Free worth ₹${parseAmount(coursePrice)}`
                      : null}
                  </p>
                </div>
                {contents?.map((feature) => (
                  <div
                    key={`feature-${feature?.id}`}
                    className="flex space-x-2 items-center"
                  >
                    <div className="w-2 h-2 rounded-full bg-theme-orange" />
                    <p className="text-sm text-theme-black-300">
                      {feature?.name}
                    </p>
                  </div>
                ))}
                <StyledButton
                  title={
                    userDetails?.is_pro_member
                      ? hasStarted
                        ? "Continue Course"
                        : "Start Course"
                      : userDetails?.pm_signup_status === "overdue"
                      ? "Renew ProMembership"
                      : "Become a ProMember to Start"
                  }
                  handleClick={async () => {
                    if (userDetails?.is_pro_member) {
                      try {
                        const response = await CourseService.createUserCourse(
                          id
                        );
                        CustomToast.success(
                          response.message ?? "Course Added Successfully"
                        );
                        navigate(`/programs/${id}/learn`);
                      } catch (e) {
                        if (
                          e?.error_messages[0] ===
                          "Course is already assigned for this user"
                        ) {
                          navigate(`/programs/${id}/learn`);
                        } else {
                          console.log(e);
                          CustomToast.error(e.error_messages[0]);
                        }
                      }
                    } else {
                      if (userDetails?.pm_signup_status === "overdue") {
                        navigate("/payments");
                      } else {
                        handleOpenPopup(PopupType.PROMEMBER);
                      }
                    }
                  }}
                />
                {userDetails?.is_pro_member && !hasStarted && (
                  <p className="text-sm leading-4 font-medium">
                    *Please click on the “Start Course” button to unlock and
                    begin the course.
                  </p>
                )}
              </div>
            ) : generalPaidCourseCategories?.indexOf(courseType) > -1 ? (
              <div className="flex flex-col space-y-4 px-4">
                {!hasStarted || !hasRegistered ? (
                  <p className="text-2xl font-bold text-theme-orange">
                    {coursePrice ? `₹${parseAmount(coursePrice)}` : null}
                  </p>
                ) : null}
                {contents?.map((feature) => (
                  <div
                    key={`feature-${feature?.id}`}
                    className="flex space-x-2 items-center"
                  >
                    <div className="w-2 h-2 rounded-full bg-theme-orange" />
                    <p className="text-sm text-theme-black-300">
                      {feature?.name}
                    </p>
                  </div>
                ))}
                <div className="flex flex-col gap-2">
                  <StyledButton
                    title={
                      hasRegistered
                        ? hasStarted
                          ? "Continue Course"
                          : "Start Course"
                        : "Register"
                    }
                    handleClick={() => {
                      if (hasRegistered) {
                        navigate(`/programs/${id}/learn`);
                      } else {
                        navigate(`/events/${eventId}/register`);
                      }
                    }}
                  />
                  {!hasStarted && hasRegistered && (
                    <p className="text-sm leading-4 font-medium">
                      *Please click on the “Start Course” button to unlock and
                      begin the course.
                    </p>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
CourseDetailsHeroSection.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  courseType: PropTypes.string,
  ratings: PropTypes.number,
  duration: PropTypes.string,
  level: PropTypes.oneOf(["beginner", "intermediate", "advance"]).isRequired,
  thumbnail: PropTypes.string,
  lastViewContentTitle: PropTypes.string,
  category: PropTypes.shape({
    id: PropTypes.number,
    order: PropTypes.number,
    value: PropTypes.string,
  }),
  hasStarted: PropTypes.bool,
  hasRegistered: PropTypes.bool,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  pricing: PropTypes.any,
  eventId: PropTypes.number,
};
export default CourseDetailsHeroSection;
