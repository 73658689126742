/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import Header from "../stories/Header";
import Sidebar from "../stories/Sidebar";
import { useNavigate } from "@reach/router";
import {
  ArrowsCounterClockwise,
  CalendarBlank,
  Certificate,
  Desktop,
  Folders,
  SignOut,
  UserGear,
  Users,
} from "@phosphor-icons/react";
import { User } from "../store";
import classNames from "classnames";
import AccountCredentialModal from "./Modals/AccountCredentialModal";
import { useProfile } from "../context/userProvider";
import { useSWRConfig } from "swr";
import Loader from "./Shimmer/Loader";
import MobileHeader from "../stories/MobileHeader";
import ProUpgradeCard from "../stories/ProUpgradeCard";
import ConfirmLogoutModal from "./Modals/ConfirmLogoutModal";
import { UserService } from "../services/UserService";
import SideNavMenu from "./Header/SideNavMenu";
import StyledButton from "./CommonComponents/StyledButton";

const AppSkeleton = ({
  pageLabel = "Dashboard",
  hideHeader,
  hideSidebar,
  hideHeaderLogo = false,
  hideProfileDetails = false,
  hideHeaderLabel = true,
  breadCrumbData,
  isLogoClickable = true,
  isLoading = false,
  hideMobileBackButton = false,
  children,
  bgColor,
  makeHeaderStickyForMobile = true,
  showMobileHeader = false,
  secondaryHeading,
  headerClassName = "",
  openLoginModal = false,
}) => {
  const isLoggedIn = !!User.getToken();
  const { userDetails } = useProfile();
  const { cache } = useSWRConfig();
  const [openAccountCreedPopup, setOpenAccountCreedPopup] = useState(false);
  useDocumentTitle(pageLabel ?? secondaryHeading);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const sidebarList = [
    {
      id: "dashboard",
      label: "Dashboard",
      normalIcon: <Desktop className="menu-icon" />,
      activeIcon: <Desktop className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/",
    },
    {
      id: "my_programs",
      label: "My Courses",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/programs",
    },
    {
      id: "qpfp_certificant",
      label: "My QPFP Certification",
      normalIcon: <Certificate className="menu-icon" />,
      activeIcon: <Certificate className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/qpfp-certificant/dashboard",
      hide: userDetails?.qpfp_certification_status === "inactive",
    },
    {
      id: "all_programs",
      label: "All Courses",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/all-courses",
    },
    {
      id: "nfp_toolbox",
      label: "NFP Toolbox",
      normalIcon: <Folders className="menu-icon" />,
      activeIcon: <Folders className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/toolbox",
    },
    {
      id: "nfp-calendar",
      label: "My Calendar",
      normalIcon: <CalendarBlank className="menu-icon" />,
      activeIcon: <CalendarBlank className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/nfp-calendar",
    },
    {
      id: "public_profile",
      label: "Public Profile",
      normalIcon: <Users className="menu-icon" />,
      activeIcon: <Users className="menu-icon" weight="fill" />,
      dropdownList: [],
      path: "/public-profile",
    },
    {
      id: "exchange-forum",
      label: "Exchange forum",
      normalIcon: (
        <ArrowsCounterClockwise className="menu-icon" weight="fill" />
      ),
      activeIcon: (
        <ArrowsCounterClockwise className="menu-icon" weight="fill" />
      ),
      dropdownList: [],
      path: "/exchange",
    },
  ];
  const navigate = useNavigate();
  const [defaultState, setDefaultState] = useState("");
  useEffect(() => {
    sidebarList.forEach((item) => {
      if (item.dropdownList?.length === 0) {
        if (item.path === window.location.pathname) {
          setDefaultState(item.id);
        }
      } else {
        item.dropdownList.forEach((e, i) => {
          if (e.path === window.location.pathname) {
            setDefaultState(e.id);
          }
        });
      }
    });
  }, [window?.location?.pathname]);

  return (
    <React.Fragment>
      <div className="flex bg-theme-gray-200">
        {isLoggedIn && (
          <SideNavMenu
            infoBottomCard={
              userDetails?.side_bar_promotion_cta && (
                <ProUpgradeCard
                  className="bg-theme-gray-200"
                  heading={userDetails?.side_bar_promotion_cta?.heading}
                  description={userDetails?.side_bar_promotion_cta?.description}
                  cta={
                    <StyledButton
                      title={userDetails?.side_bar_promotion_cta?.cta_label}
                      handleClick={() => {
                        navigate(userDetails?.side_bar_promotion_cta?.cta_link);
                      }}
                    />
                  }
                  content_label={userDetails?.side_bar_promotion_cta?.title}
                  benefits={userDetails?.side_bar_promotion_cta?.content?.split(
                    "\n"
                  )}
                />
              )
            }
          />
        )}
        <div className="relative flex flex-col w-full nfp-page-container">
          {!hideHeader && (
            <div className={classNames("top-0 z-20 w-full h-20 sticky")}>
              {showMobileHeader && (
                <MobileHeader
                  hideBackButton={hideMobileBackButton}
                  heading={secondaryHeading ?? pageLabel}
                  className={showMobileHeader && "flex lg:hidden"}
                />
              )}

              <Header
                title={pageLabel}
                hideProfileDetails={hideProfileDetails}
                hideHeaderLabel={hideHeaderLabel}
                hideHeaderLogo={hideHeaderLogo}
                hideSidebar={hideSidebar}
                isLogoClickable={isLogoClickable}
                className={classNames(
                  headerClassName,
                  showMobileHeader ? "hidden lg:block" : ""
                )}
                tabs={[
                  {
                    name: "Edit Profile",
                    icon: () => <UserGear size={24} />,
                    onClick: () => {
                      navigate("/public-profile");
                    },
                  },
                  {
                    name: "Edit Account",
                    icon: () => <UserGear size={24} />,
                    onClick: () => {
                      setOpenAccountCreedPopup(true);
                    },
                  },
                  {
                    name: "Logout",
                    href: "login",
                    icon: () => <SignOut size={24} />,
                    onClick: async () => {
                      setShowLogoutModal(true);
                    },
                  },
                ]}
                onLogoClick={
                  isLogoClickable
                    ? () => {
                        navigate("/");
                      }
                    : null
                }
                breadCrumbData={breadCrumbData}
                avatar={true}
                imageSrc={
                  "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                }
                mobileSideBarComponent={
                  <Sidebar
                    className="z-20 flex lg:hidden"
                    showRegisterCard={true}
                    isLogoClickable={true}
                    sidebarItems={sidebarList}
                    onMenuClick={async (item) => {
                      await navigate(item.path);
                      setDefaultState(item.id);
                    }}
                    infoBottomCard={
                      userDetails?.side_bar_promotion_cta && (
                        <ProUpgradeCard
                          className="bg-theme-gray-200"
                          heading={userDetails?.side_bar_promotion_cta?.heading}
                          description={
                            userDetails?.side_bar_promotion_cta?.description
                          }
                          cta={
                            <StyledButton
                              title={
                                userDetails?.side_bar_promotion_cta?.cta_label
                              }
                              handleClick={() => {
                                navigate(
                                  userDetails?.side_bar_promotion_cta?.cta_link
                                );
                              }}
                            />
                          }
                          content_label={
                            userDetails?.side_bar_promotion_cta?.title
                          }
                          benefits={userDetails?.side_bar_promotion_cta?.content?.split(
                            "\n"
                          )}
                        />
                      )
                    }
                    defaultState={defaultState}
                  />
                }
                openLoginModal={openLoginModal}
              />
            </div>
          )}
          <div
            className={classNames(isLoading ? "bg-theme-grey-200" : bgColor)}
          >
            {isLoading ? (
              <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
                <Loader />
              </div>
            ) : (
              <>{children}</>
            )}
          </div>
        </div>
      </div>
      {openAccountCreedPopup && (
        <AccountCredentialModal
          open={openAccountCreedPopup}
          onClose={() => setOpenAccountCreedPopup(false)}
        />
      )}
      {showLogoutModal && (
        <ConfirmLogoutModal
          open={showLogoutModal}
          onConfirm={async () => {
            setShowLogoutModal(false);
            const fcm_token = localStorage.getItem("fcm_token");
            if (fcm_token) {
              try {
                await UserService.deleteUserDevice(fcm_token);
              } catch (e) {
              } finally {
                localStorage.removeItem("fcm_token");
              }
            }
            navigate("/visitor");
            window.fcWidget.user
              .get()
              .then(async (res) => {
                window.fcWidget.user.clear();
              })
              .catch((e) => console.log(e));
            // await window.fcWidget.destroy();
            window.fcWidget.init({
              token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
              host: "https://wchat.in.freshchat.com",
            });
            User.clearToken();
            cache.clear();
          }}
          onClose={() => setShowLogoutModal(false)}
        />
      )}
    </React.Fragment>
  );
};

AppSkeleton.propTypes = {
  bgColor: PropTypes.string,
  breadCrumbData: PropTypes.any,
  children: PropTypes.any,
  headerClassName: PropTypes.string,
  hideHeader: PropTypes.bool,
  hideHeaderLabel: PropTypes.bool,
  hideHeaderLogo: PropTypes.bool,
  hideMobileBackButton: PropTypes.bool,
  hideProfileDetails: PropTypes.bool,
  hideSidebar: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLogoClickable: PropTypes.bool,
  makeHeaderStickyForMobile: PropTypes.bool,
  pageLabel: PropTypes.string,
  secondaryHeading: PropTypes.any,
  showMobileHeader: PropTypes.bool,
  openLoginModal: PropTypes.bool,
};

export default AppSkeleton;
