import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import CrossIcon from ".././assets/images/X.png";
import ProToolView from "./ProToolView";
import PropTypes from "prop-types";
import Button from "./Button";
import _ from "lodash";
import classNames from "classnames";
import { User } from "../store";
import { CustomToast } from "./Toast";

const PopupImage = ({
  type = "image",
  allowDownload = false,
  children,
  title,
  imgSrc,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = !!User.getToken();

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    isLoggedIn && setIsOpen(true);
    if (!isLoggedIn) {
      CustomToast.error("Please login to view the content");
    }
  };
  return (
    <>
      <div onClick={openModal}>{children}</div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex justify-between items-top">
                    <Dialog.Title
                      as="h3"
                      className="mr-6 text-lg font-medium leading-6 text-left text-gray-900"
                    >
                      {/* Certificate */}
                      <p>{title}</p>
                    </Dialog.Title>
                    <img
                      className="absolute z-10 w-6 h-6 cursor-pointer right-6 focus:outline-none"
                      size="xs"
                      src={CrossIcon}
                      onClick={closeModal}
                      alt="close"
                    />
                  </div>
                  <div className="mt-2 lg:h-96">
                    {type === "pdf" || type === "document" ? (
                      <ProToolView url={imgSrc} />
                    ) : (
                      <img
                        src={imgSrc}
                        alt=""
                        className="relative mx-auto md:h-96 "
                      />
                    )}
                  </div>
                  <Button
                    className={classNames("mt-4", !allowDownload && "hidden")}
                    onClick={() => {
                      const url = imgSrc;
                      const filename = _.last(
                        _.head(url.split("?")).split("/")
                      );
                      fetch(url)
                        .then((resp) => resp.blob())
                        .then((blob) => {
                          const blobURL = window.URL.createObjectURL(blob);
                          const a = document.createElement("a");
                          a.style.display = "none";
                          a.href = blobURL;
                          a.download = filename;
                          document.body.appendChild(a);
                          a.click();
                          window.URL.revokeObjectURL(blobURL);
                        })
                        .catch(() => {
                          const a = document.createElement("a");
                          a.style.display = "none";
                          a.href = url;
                          a.download = filename;
                          document.body.appendChild(a);
                          a.click();
                          window.URL.revokeObjectURL(url);
                        });
                    }}
                  >
                    Download
                  </Button>
                  <div className="mt-4" hidden>
                    <button
                      type="button"
                      className="flex items-center justify-center w-16 px-4 py-2 mx-auto text-xs font-medium leading-3 text-white capitalize rounded bg-red-color"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

PopupImage.propTypes = {
  allowDownload: PropTypes.bool,
  children: PropTypes.element,
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};
export default PopupImage;
