import React from "react";
import PropTypes from "prop-types";
import DownloadActionBar from "./DownloadActionBar";
import VideoPlayer from "./VideoPlayer";
import PopupImage from "./PopupImage";
import ProToolView from "./ProToolView";
import Text from "./Text";
import classNames from "classnames";

const CCImage = ({ src, newCard }) => {
  return (
    <PopupImage imgSrc={src}>
      <img
        src={src}
        alt=""
        className={classNames(
          "w-full",
          newCard ? "h-72" : " max-w-72 max-h-72"
        )}
      />
    </PopupImage>
  );
};

CCImage.propTypes = {
  src: PropTypes.string,
  newCard: PropTypes.bool,
};

const CCVideo = ({ src, newCard }) => (
  <div className={classNames("w-full", newCard ? "h-72" : "h-96 min-h-fit")}>
    <VideoPlayer
      src={src}
      className={classNames("w-full", newCard ? "h-72" : "h-96 min-h-fit")}
    />
  </div>
);

CCVideo.propTypes = {
  src: PropTypes.string,
  newCard: PropTypes.bool,
};

const CCPdf = ({ src, newCard }) => {
  return (
    <div className={classNames("w-full", newCard ? "h-72" : "h-96 min-h-fit")}>
      <ProToolView url={src} />
    </div>
  );
};

CCPdf.propTypes = {
  src: PropTypes.string,
  newCard: PropTypes.bool,
};
const CCText = ({ src }) => {
  return (
    <Text
      className="w-full text-sm text-left"
      isMultiline={true}
      content={src}
    />
  );
};

CCText.propTypes = {
  src: PropTypes.string,
};

export const ContentBox = ({ type, src, newCard = false }) => {
  switch (type) {
    case "image":
      return (
        <div className="flex flex-col items-center justify-center space-y-3 cursor-pointer">
          <CCImage src={src} newCard={newCard} />
        </div>
      );

    case "video":
      return (
        <div className="flex flex-col items-center justify-center w-full space-y-3 ">
          <CCVideo src={src} newCard={newCard} />
        </div>
      );
    default:
      return (
        <div className="flex flex-col items-center justify-center w-full space-y-3">
          <CCPdf src={src} newCard={newCard} />
        </div>
      );
  }
};

ContentBox.propTypes = {
  src: PropTypes.string,
  type: PropTypes.string,
  newCard: PropTypes.bool,
};
const CCContent = ({ textContent, attachments }) => {
  return (
    <div className="flex flex-col items-center justify-center p-3 space-y-3">
      {textContent && <CCText src={textContent} />}
      {attachments.map((attachment, index) => {
        return (
          <div
            key={attachment.id ?? index}
            className="flex flex-col items-center justify-center w-full space-y-3"
          >
            <ContentBox type={attachment.file_type} src={attachment.url} />
            {attachment.file_type !== "text" && (
              <DownloadActionBar
                type="client-connect"
                id={attachment.id}
                url={attachment.url}
              />
            )}
            {attachments.length > 0 && index !== attachments.length - 1 && (
              <div className="h-3" />
            )}
          </div>
        );
      })}
    </div>
  );
};
CCContent.propTypes = {
  attachments: PropTypes.array,
  textContent: PropTypes.string,
};
export default CCContent;
