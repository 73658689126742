import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import play_icon from "../assets/svgs/play.svg";
import avatar from "../assets/images/avatar.png";
import Card from "./Card";
import ProgressBar from "./ProgressBar";
import { Circle } from "@phosphor-icons/react";
import Button from "./Button";
import { useNavigate } from "@reach/router";
import ImageComponent from "./ImageComponent";
import _ from "lodash";
import PaymentModal from "../components/Modals/PaymentModal";

const StartCourseCard = ({ courseName, courseDescription, onClick }) => {
  return (
    <div className="flex col-span-full lg:col-span-1">
      <Card
        className="mr-0 bg-white lg:h-60 lg:bg-theme-gray-300"
        cardType="filled"
        header={
          <div
            className={classNames(
              "flex items-center ",
              "text-2xl font-semibold leading-none text-theme-black-color tracking-tighter"
            )}
          >
            {courseName}
          </div>
        }
        cta={
          <Button
            onClick={onClick}
            buttonStyle="primary"
            size="xl"
            width="100%"
          >
            START COURSE
          </Button>
        }
        cardBody={
          _.isArray(courseDescription) ? (
            <div className="flex flex-col mt-4 mb-4 min-h-[64px]">
              {courseDescription.map((e, index) => {
                return (
                  <div
                    key={index}
                    className={classNames(
                      "flex items-center  text-base tracking-tighter leading-none text-theme-black-300",
                      index + 1 !== courseDescription.length ? "mb-2" : ""
                    )}
                  >
                    <Circle
                      size={10}
                      className="w-2 h-2 mr-2"
                      weight="fill"
                      color="#f36c04"
                    />
                    {e}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col mt-4 mb-4 min-h-[64px] text-sm tracking-tight text-theme-black-300 line-clamp-5">
              {courseDescription}
            </div>
          )
        }
      />
    </div>
  );
};

const ContinueCourseCard = ({
  text = "CONTINUE COURSE",
  courseContent,
  onClick,
}) => {
  return (
    <div className="hidden col-span-full lg:col-span-1 lg:flex">
      <Card
        className="hidden min-w-full mt-6 lg:h-60 lg:flex lg:ml-6 lg:mt-0 "
        header={
          <div
            className={classNames(
              "flex items-center ",
              "text-base font-semibold leading-none text-theme-black-color tracking-tight"
            )}
          >
            Course Details
          </div>
        }
        cta={
          <Button
            onClick={onClick}
            buttonStyle="primary"
            size="xl"
            width="100%"
            className=""
          >
            {text}
          </Button>
        }
        cardType="outline"
        cardBody={
          <div className="flex flex-col my-4 min-h-[64px]">
            {courseContent.map((e, index) => {
              if (index > 2) return null;
              return (
                <div
                  key={index}
                  className={classNames(
                    "flex items-center text-base tracking-tighter leading-5 text-theme-black-color",
                    index + 1 !== courseContent.length ? "mb-4" : ""
                  )}
                >
                  <img alt="icon" src={play_icon} className="w-6 h-6 mr-3" />
                  {<span className="line-clamp-1">{e}</span>}
                </div>
              );
            })}
          </div>
        }
      />
    </div>
  );
};

const VideoThumbnail = ({ imageSrc, onClick = () => {}, className }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "relative h-full lg:h-60 mr-0 lg:mr-4 bg-white",
        className
      )}
    >
      <div className="relative flex items-center justify-center h-full">
        <ImageComponent
          errorComponent={
            <div className="w-full h-full min-h-[160px] bg-gray-200"></div>
          }
          src={imageSrc}
          className="z-10 object-contain !bg-white h-full aspect-video"
        />
        <img
          alt="icon"
          src={play_icon}
          className="absolute z-10 w-16 h-16 cursor-pointer"
        />
      </div>
      {/* linear-gradient(180deg, rgba(0, 0, 0, 0) 24.14%, rgba(0, 0, 0, 0.6) 100%) */}
      {/* <div className="absolute z-10 flex items-center bottom-3 left-3">
				<ImageComponent src={authorImageSrc} className="w-8 h-8 mr-3" />
				<div className="text-sm leading-none tracking-tight text-white">
					Navin Rao
				</div>
			</div> */}
    </div>
  );
};

const ProgramCourseDetails = ({
  isFreeCourse,
  courseTitle,
  courseDescription,
  courseId,
  courseName,
  courseFeature,
  courseCompletionProgress,
  className,
}) => {
  const [openCertificatPaymentModal, setOpenCertificatePaymentModal] =
    useState(false);
  const navigate = useNavigate();

  return (
    <div
      className={classNames("flex flex-col lg:h-60 justify-between", className)}
    >
      <div className={classNames("flex flex-col mt-4 lg:mt-0")}>
        <div
          className={classNames(
            "text-black text-base md:text-lg lg:text-md leading-5 tracking-tighter font-semibold"
          )}
        >
          {courseTitle}
        </div>
        <div className="hidden mt-2 text-sm leading-5 tracking-tight md:flex text-theme-black-300 lg:text-sm">
          <div
            className={classNames(
              "flex flex-col space-y-2",
              isFreeCourse && "line-clamp-5"
            )}
          >
            {isFreeCourse
              ? courseDescription
              : courseFeature.map((e, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(
                        "flex items-center text-sm tracking-tight leading-none text-theme-black-300"
                      )}
                    >
                      <Circle
                        className="w-2 h-2 mr-2"
                        weight="fill"
                        color="#f36c04"
                      />
                      {e}
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      {/* {_.toInteger(courseCompletionProgress) === 100 ? (
        <div className="flex">
          <div className="flex items-center justify-between w-full mt-1 space-x-3 rounded lg:mt-0">
            <div className="flex items-center">
              <CheckCircle
                weight="fill"
                className="min-w-[20px] w-5 h-5 text-theme-orange"
              />
              <div className="ml-2 text-xs text-theme-black-color">
                Course Completed
              </div>
            </div>

            <div
              className="text-xs cursor-pointer text-theme-orange"
              onClick={async () => {
                try {
                  await PaymentServices.fetchPricingDetails({
                    entity_type: "course",
                    entity_id: courseId,
                    part_payment_order: 0,
                  });
                  try {
                    await PaymentServices.addUserExternalPurchaseDetails({
                      user_external_purchase_detail: {
                        entity_type: "Course",
                        entity_id: courseId,
                      },
                    });
                    setOpenCertificatePaymentModal(true);
                  } catch (e) {
                    console.log(e);
                    if (e.error_code === "payment_already_made") {
                      // already paid
                      navigate("/certificates");
                    } else {
                      // something else
                      CustomToast.error("Something went wrong");
                    }
                  }
                } catch (e) {
                  if (e.error_code === "pricing_not_found") {
                    // free certification - no need to pay
                    navigate("/certificates");
                  }
                }
              }}
            >
              Get Certificate
            </div>
          </div>
        </div>
      )  */}
      <ProgressBar
        className="mt-4 lg:mt-5"
        showTopLeftLabel={isFreeCourse}
        topRightLabel={
          <>
            {/* <div className="flex text-xs leading-none tracking-tight text-neutral-400">
              Free Course
            </div> */}
          </>
        }
        showTopRightLabel={true}
        topLeftLabel={
          <span className="text-xs leading-none text-theme-black-color">
            {_.toInteger(courseCompletionProgress)}% Completed
          </span>
        }
        percent={_.toInteger(courseCompletionProgress)}
        showProgress={true}
      />

      {openCertificatPaymentModal && (
        <PaymentModal
          open={openCertificatPaymentModal !== -1}
          title={`Certification Fee for ${courseName}`}
          entityProps={{
            entity_type: "course",
            entity_id: courseId,
            part_payment_order: 0,
          }}
          availablePartPaymentOptions={[{ label: "", part_payment_order: 0 }]}
          onPaymentSuccessCallback={() => {
            setOpenCertificatePaymentModal(false);
            navigate("/certificates");
          }}
          onClose={() => {
            setOpenCertificatePaymentModal(false);
          }}
        />
      )}
    </div>
  );
};

const ProgramCard = ({
  isFreeCourse,
  courseName,
  courseTitle,
  courseBenefits,
  courseDescription,
  courseContent = [],
  courseFeature = [],
  courseType,
  course_icon,
  isHideOnlyComplete = false,
  courseCompletionProgress = 0,
  courseId,
  lastViewedSessionContentId,
}) => {
  const navigate = useNavigate();
  if (courseCompletionProgress === 100 && isHideOnlyComplete) return null;
  return (
    <div className="grid w-full grid-cols-3 p-3 bg-white gap-x-4 md:p-6">
      {/* video container */}
      <div className="grid grid-cols-1 p-4 col-span-full lg:col-span-2 lg:grid-cols-2 lg:p-0 relative">
        <VideoThumbnail
          imageSrc={course_icon ?? ""}
          authorImageSrc={avatar}
          className="col-span-1"
          onClick={() => navigate("/programs/" + courseId + "/")}
        />
        <ProgramCourseDetails
          isFreeCourse={isFreeCourse}
          courseId={courseId}
          courseTitle={courseTitle}
          courseDescription={courseDescription}
          courseName={courseName}
          courseFeature={courseFeature}
          courseCompletionProgress={courseCompletionProgress}
          className="col-span-1"
        />
      </div>
      {/* course description and time bar container */}
      {/* course content card */}

      <ContinueCourseCard
        text={
          courseCompletionProgress === 0
            ? "START COURSE"
            : courseCompletionProgress === 100
            ? "VIEW COURSE"
            : "CONTINUE COURSE"
        }
        courseContent={courseContent}
        onClick={() => navigate("/programs/" + courseId + "/")}
      />
    </div>
  );
};

ProgramCourseDetails.propTypes = {
  authorImageSrc: PropTypes.string,
  className: PropTypes.string,
  courseCompletionProgress: PropTypes.number,
  courseDescription: PropTypes.string,
  courseFeature: PropTypes.array,
  courseId: PropTypes.number,
  courseName: PropTypes.string,
  courseTitle: PropTypes.string,
  imageSrc: PropTypes.string,
  isFreeCourse: PropTypes.bool,
  isStarted: PropTypes.bool,
};
VideoThumbnail.propTypes = {
  authorImageSrc: PropTypes.string,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  onClick: PropTypes.func,
};
ContinueCourseCard.propTypes = {
  text: PropTypes.string,
  courseContent: PropTypes.array,
  onClick: PropTypes.func,
};
StartCourseCard.propTypes = {
  courseName: PropTypes.string,
  courseDescription: PropTypes.string,
  onClick: PropTypes.func,
};
ProgramCard.defaultProps = {
  isStarted: false,
  isFreeCourse: false,
  courseTitle: "",
  courseContent: [],
  courseFeature: [],
  courseCompletionProgress: 0,
};
ProgramCard.propTypes = {
  courseBenefits: PropTypes.array,
  courseCompletionProgress: PropTypes.number,
  courseContent: PropTypes.array,
  courseDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  courseFeature: PropTypes.array,
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  courseName: PropTypes.string.isRequired,
  courseTitle: PropTypes.string,
  courseType: PropTypes.string,
  course_icon: PropTypes.string,
  isFreeCourse: PropTypes.bool,
  isHideOnlyComplete: PropTypes.bool,
  isStarted: PropTypes.bool,
  lastViewedSessionContentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ProgramCard;
