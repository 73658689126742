import PropTypes from "prop-types";
import StyledButton from "./StyledButton";
import ImageComponent from "../../stories/ImageComponent";
import { User } from "@phosphor-icons/react";

const TopQuestionAsked = ({
  userAvatar,
  postedBy,
  postedOn,
  question,
  answerAction,
  isProMember,
  title,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-1.5 items-center justify-start ">
        {/* <div className="border border-black rounded-full w-10 h-10"></div> */}
        <ImageComponent
          src={userAvatar}
          alt="user"
          errorComponent={
            <div className="flex items-center justify-center min-w-[40px] w-10 h-10 bg-gray-200 rounded-full">
              <User weight="bold" className="w-6 h-6 text-gray-400" />
            </div>
          }
          className="object-cover w-10 h-10 rounded-full min-w-[40px]"
        />
        <div className="flex flex-col items-start justify-center gap-1.5">
          <div className="flex gap-1.5 items-center justify-start text-sm sm:text-base">
            <p className="font-medium">Posted by</p>
            <p className="text-theme-blue-200">{postedBy}</p>
          </div>
          <div className="flex gap-1.5 items-center justify-start text-theme-black-300 text-xs sm:text-sm">
            <p className="font-medium">ProMember</p>
            <div className="border border-black w-0.5 h-0.5"></div>
            <p className="">{postedOn}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-0.5">
        <p className="text-sm text-theme-black-500 line-clamp-1">{title}</p>
        <p className="text-xs text-theme-black-300 line-clamp-2">{question}</p>
      </div>
      <div className="max-w-52">
        <StyledButton title={"Answer question"} handleClick={answerAction} />
      </div>
    </div>
  );
};

TopQuestionAsked.propTypes = {
  userAvatar: PropTypes.string,
  postedBy: PropTypes.string,
  postedOn: PropTypes.string,
  question: PropTypes.string,
  title: PropTypes.string,
  answerAction: PropTypes.func,
  isProMember: PropTypes.bool,
};

export default TopQuestionAsked;
