import PropTypes from "prop-types";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { CaretDown } from "@phosphor-icons/react";
import React from "react";
import _ from "lodash";
import ModuleCard from "../../stories/ModuleCard";
import { CustomToast } from "../../stories/Toast";

const ShowModules = ({
  curriculum,
  modules,
  curriculumRef,
  eventDetails,
  isMembershipBenefitVisible,
  allowUserToSkipRenewal,
  hasMadeFullPayment,
  availablePartPaymentOptions,
  openLockPopup,
  remainingAmount,
  courseSessionStats,
  courseName,
  entityType,
  entityId,
  refreshCallback,
  sessionCountList,
  bgColorClassList = ["lg:bg-gray-100 bg-white drop-shadow-sm", "bg-white"],
  isCourseRegistered = true,
}) => {
  return (
    <>
      {modules?.map((module, m_index) => {
        const bgClasses =
          (isMembershipBenefitVisible && m_index % 2 === 0) ||
          (!isMembershipBenefitVisible && m_index % 2 !== 0)
            ? bgColorClassList[1]
            : bgColorClassList[0];

        return (
          <Disclosure key={m_index} defaultOpen={m_index === 0}>
            {({ open }) => (
              <div
                key={m_index}
                className={classNames(
                  "flex flex-col rounded-sm p-3 lg:p-6",
                  bgClasses
                )}
              >
                <Disclosure.Button
                  as="div"
                  className={classNames(
                    "flex justify-between text-lg font-bold leading-none bg-transparent focus-visible:outline-none text-theme-black-color",
                    open && "mb-3"
                  )}
                >
                  {module.name}
                  <CaretDown
                    size={20}
                    weight="fill"
                    className={classNames(
                      "transform duration-300 cursor-pointer",
                      open ? "rotate-180" : "rotate-0"
                    )}
                  />
                </Disclosure.Button>
                <Disclosure.Panel
                  className={classNames(
                    "mt-1 grid grid-cols-1 gap-3 lg:gap-6 md:grid-cols-2 xl:grid-cols-3"
                  )}
                >
                  {_.size(module?.sessions) === 0 && (
                    <span className="italic">No Sessions added</span>
                  )}
                  {module?.sessions?.map((session, s_index) => {
                    return (
                      <ModuleCard
                        curriculumRef={curriculumRef}
                        key={s_index}
                        eventDetails={eventDetails}
                        onClick={
                          session?.is_past_recording_session
                            ? false
                            : !_.isEmpty(eventDetails)
                            ? eventDetails.start_time &&
                              new Date(eventDetails.start_time) > new Date()
                              ? () => openLockPopup
                              : module.is_locked
                              ? () => {
                                  CustomToast.error(
                                    "Recordings are no more available"
                                  );
                                }
                              : null
                            : null
                        }
                        allowUserToSkipRenewal={allowUserToSkipRenewal}
                        isLocked={
                          (module?.is_locked ?? false) || !isCourseRegistered
                        }
                        hasRegistered={isCourseRegistered}
                        availablePartPaymentOptions={
                          availablePartPaymentOptions
                        }
                        hasCompletedUnlockedModule={
                          // curriculum.course_type === "qpfp"
                          //   ? course_lock_details?.has_completed_unlock_module ??
                          //     false
                          //   :
                          true
                        }
                        hasMadeFullPayment={hasMadeFullPayment}
                        courseName={courseName}
                        entityType={entityType}
                        entityId={entityId}
                        refreshCallback={refreshCallback}
                        remainingAmount={remainingAmount}
                        percentCompleted={
                          courseSessionStats?.[session?.id] ?? 0
                        }
                        firstSessionContentId={
                          session?.content_ids?.[0] ?? ""
                          // _.head(session?.content_ids ?? [])?.[0]
                        }
                        session_thumnail_src={
                          session?.session_thumbnail?.url ?? ""
                        }
                        courseDate={
                          curriculum.course_type === "new_user"
                            ? `Episode ${
                                sessionCountList[m_index] + s_index + 1
                              }`
                            : session?.protool || session?.session_date
                        }
                        cummulativeIndex={
                          _.isEmpty(sessionCountList)
                            ? null
                            : sessionCountList[m_index] + s_index + 1
                        }
                        courseTitle={session?.name ?? session?.title}
                        sessionNo={session?.session}
                        course_id={curriculum.id}
                        moduleIndex={s_index}
                        proToolNo={session?.protool}
                        status={
                          (courseSessionStats?.[session?.id] ?? 0) === 0
                            ? "WATCH NOW"
                            : (courseSessionStats?.[session?.id] ?? 0) >= 100
                            ? "COMPLETED"
                            : "RESUME"
                        }
                        authorName={
                          curriculum.course_type !== "qpfp" &&
                          session?.presenter
                        }
                      />
                    );
                  })}
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        );
      })}
    </>
  );
};

ShowModules.propTypes = {
  allowUserToSkipRenewal: PropTypes.bool,
  availablePartPaymentOptions: PropTypes.array,
  courseName: PropTypes.string,
  courseSessionStats: PropTypes.any,
  curriculum: PropTypes.object,
  curriculumRef: PropTypes.any,
  entityId: PropTypes.number,
  entityType: PropTypes.string,
  eventDetails: PropTypes.object,
  hasMadeFullPayment: PropTypes.bool,
  isMembershipBenefitVisible: PropTypes.bool,
  modules: PropTypes.array,
  openLockPopup: PropTypes.bool,
  refreshCallback: PropTypes.func,
  remainingAmount: PropTypes.number,
  sessionCountList: PropTypes.array,
  bgColorClassList: PropTypes.array,
  isCourseRegistered: PropTypes.bool,
};

export default ShowModules;
