/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CaretLeft, CaretRight, Check, Trophy } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { CourseService } from "../../../services/CourseService";
import CurriculumAccordion from "../../../stories/CurriculumAccordion";
import Tabs from "../../../stories/Tabs";
import AppSkeleton from "../../AppSkeleton";
import CommentsTab from "./Tabs/CommentsTab";
import OverviewTab from "./Tabs/OverviewTab";
import ResourcesTab from "./Tabs/ResourcesTab";
import ReviewsTab from "./Tabs/ReviewsTab";
import { useNavigate } from "@reach/router";
import SessionContentComponent from "./SessionContentComponent";
import QuizIntroModal from "../../../stories/QuizIntroModal";
import QuizSummary from "../../Quiz/QuizSummary";
import _ from "lodash";
import ImageComponent from "../../../stories/ImageComponent";
import networkfp_logo from "../../../assets/images/nfp.png";
import { CustomToast } from "../../../stories/Toast";
import Loader from "../../Shimmer/Loader";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import {
  useCourseLockDetail,
  useMyCoursesessionStats,
  useMyCoursestats,
  useQPFPProgressTracker,
  useRatingsStats,
} from "../../../services/CourseDetails";
import QuizTab from "./Tabs/QuizTab";
import classNames from "classnames";
import { useProfile } from "../../../context/userProvider";
import mixpanel from "mixpanel-browser";
import UserProfileAvatar from "../../../stories/UserProfileAvatar";
import { QPFPModuleWiseProgressBar } from "../QPFPProgressTracker";
import PropTypes from "prop-types";

const ViewCourse = (props) => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [course, setCourse] = useState({});
  const [eventDetails, setEventDetails] = useState({});
  const [showQuizIntroPopup, setShowQuizIntroPopup] = useState(false);
  const [comments, setComments] = useState([]);
  const [resources, setResources] = useState([]);
  const [usefulLinks, setUsefulLinks] = useState([]);
  const [currentSessionContent, setCurrentSessionContent] = useState({});
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(true);
  const [sessionCountList, setSessionCountList] = useState([0]);
  const navigate = useNavigate();
  const [currentPlayingState, setCurrentPlayingState] = useState({});
  const [isMarkingCompleted, setIsMarkingCompleted] = useState(false);
  const [showCurriculum, setShowCurriculum] = useState(false);
  const [totalCompletedModules, setTotalCompletedModules] = useState(0);
  const [breadCrumbData, setBreadCrumbData] = useState([]);
  const { userDetails } = useProfile();

  const courseRef = useRef(props.location?.state?.curriculumData ?? null);

  const { course_lock_details, mutate: mutateLockDetails } =
    useCourseLockDetail(props.course_id, {
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    });

  const { ratings_stats, mutate: mutateRatingsStats } = useRatingsStats(
    props.course_id,
    {
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    }
  );

  const {
    course_stats,
    zoom_session_details,
    mutate: mutateCourseStats,
  } = useMyCoursestats(props.course_id, {
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });

  const { course_session_stats, mutate: mutateCourseSessionStats } =
    useMyCoursesessionStats(props.course_id, {
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    });

  const { qpfpProgressTracker, loading: isProgressTrackerLoading } =
    useQPFPProgressTracker(course.course_type, props.course_id, {
      revalidateOption: {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
      },
    });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await fetchCourse();
      await fetchComments();
      await fetchReviews();
      if (!isFirstLoad) {
        mutateLockDetails(props.course_id);
        mutateRatingsStats(props.course_id);
        mutateCourseSessionStats(props.course_id);
        mutateCourseStats(props.course_id);
      } else {
        setIsFirstLoad(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(userDetails)) {
      setShowCurriculum(false);
      fetchData();
    }
  }, [props.session_content_id, userDetails]);

  useEffect(() => {
    if (currentPlayingState?.currentSessionId) {
      fetchResources();
    }
  }, [currentPlayingState?.currentSessionId]);

  useEffect(() => {
    const tempModuleCompleted = [];
    if (!_.isEmpty(course) && !_.isEmpty(course_session_stats)) {
      courseRef.current.course.modules?.forEach((module, m_index) => {
        let isModuleCompleted = true;
        module?.sessions?.forEach((session, s_index) => {
          if ((course_session_stats[session.id] ?? 0) < 100) {
            isModuleCompleted = false;
          }
        });
        if (isModuleCompleted) {
          tempModuleCompleted.push(module.id);
        }
      });
    }
    setTotalCompletedModules(tempModuleCompleted.length);
  }, [course, course_session_stats]);

  const fetchCourse = async () => {
    try {
      let res;
      if (_.isEmpty(props.location?.state?.curriculumData)) {
        res = await CourseService.fetchCourseDetails({
          course_id: props.course_id,
        });
      } else {
        res = props.location?.state?.curriculumData;
      }
      courseRef.current = res;
      if (
        res.course.modules.filter((m) => m.is_locked !== true).length === 0 &&
        res.course.course_type !== "pro_member"
      ) {
        CustomToast.error("Content is not available to watch");
        navigate(`/programs/${props.course_id}`, { replace: true });
        return;
      }
      if (!props.session_content_id) {
        navigate(
          `/programs/${props.course_id}/learn/${
            _.head(
              _.compact(
                _.flatMapDeep(res.course.modules, (e) =>
                  e.sessions?.map((e1) => e1.session_content)
                )
              )
            ).id ?? ""
          }`,
          {
            replace: true,
            state: { curriculumData: courseRef.current },
          }
        );
        return;
      }
      // const tempModuleCompleted = [];
      res.course.modules?.forEach((module, m_index) => {
        // let isModuleCompleted = true;
        module?.sessions?.forEach((session, s_index) => {
          // if ((course_session_stats[session.id] ?? 0) < 100) {
          //   isModuleCompleted = false;
          // }
          session?.session_content?.forEach((s_content, s_c_index) => {
            if (s_content.id.toString() === props.session_content_id) {
              setCurrentPlayingState({
                currentModuleId: module.id,
                currentSessionId: session.id,
                currentSessionContentId: s_content.id,
                currentModuleIndex: m_index,
                currentSessionIndex: s_index,
                currentSessionContentIndex: s_c_index,
              });
              setCurrentSessionContent({
                ...s_content,
                session_venue: session.venue,
              });
            }
          });
        });
        // if (isModuleCompleted) {
        //   tempModuleCompleted.push(module.id);
        // }
      });
      // setTotalCompletedModules(tempModuleCompleted);

      let tempSessionCountList = [0];
      res.course?.modules?.forEach((module, index) => {
        tempSessionCountList = [
          ...tempSessionCountList,
          _.last(tempSessionCountList) + (module.sessions?.length ?? 0),
        ];
      });
      setSessionCountList(tempSessionCountList);
      setCourse(res.course);
      setEventDetails(res.event_details ?? {});
      setError(false);

      if (res.course.course_type === "qpfp_courseware") {
        setBreadCrumbData([
          { name: "QPFP Dashboard", to: "/qpfp-certificant/dashboard" },
          {
            name: res.course.name,
            to: "/qpfp-certificant/courseware",
          },
          {
            name: "Sessions",
            to: `/programs/${props.course_id}/learn`,
            current: true,
          },
        ]);
      } else if (res.course.course_type === "qpfp_ace_program") {
        setBreadCrumbData([
          { name: "QPFP Dashboard", to: "/qpfp-certificant/dashboard" },
          {
            name: res.course.name,
            to: `/qpfp-certificant/ace-program-and-tests/${props.course_id}`,
          },
          {
            name: "Sessions",
            to: `/programs/${props.course_id}/learn`,
            current: true,
          },
        ]);
      } else {
        setBreadCrumbData([
          { name: "My Programs", to: "/programs" },
          {
            name: res.course.name,
            to: `/programs/${props.course_id}`,
          },
          {
            name: "Sessions",
            to: `/programs/${props.course_id}/learn`,
            current: true,
          },
        ]);
      }
    } catch (e) {
      if (e.error_code === "course_not_registered") {
        CustomToast.error(e.error_messages[0]);
        navigate("/");
      }
      setError(true);
    }
  };

  const fetchResources = async () => {
    const res = await CourseService.fetchAllResources({
      course_session_id: currentPlayingState?.currentSessionId,
    });
    setResources(res.resources);
    // setUsefulLinks(res.useful_links ?? []);  // uncomment this to show usefull links on portal
  };

  const fetchComments = async () => {
    const res1 = await CourseService.fetchCommentsForSession({
      entity_id: props.session_content_id,
    });
    setComments(res1?.comments ?? []);
  };

  const fetchReviews = async () => {
    const res2 = await CourseService.fetchReviewsForSession({
      entity_id: props.session_content_id,
      reviewer_id: userDetails.id,
    });
    setReviews(res2 ?? {});
  };

  const onPostComment = async ({ replied_to_comment_id, content }) => {
    const response = await CourseService.postCommentForSession({
      replied_to_comment_id: replied_to_comment_id,
      content: content,
      entity_id: props.session_content_id,
    });
    mixpanel.track("User Comment on Session Content", {
      distinct_id: userDetails.email,
      "Course Session Content ID": props.session_content_id,
      Comment: content,
    });
    setComments((prev) => [...prev, response.comment]);
  };

  const onPostReview = async ({ reviews, rating_value }) => {
    const response = await CourseService.postReviewsForSession({
      review: reviews,
      rating_value: rating_value,
      entity_id: props.session_content_id,
      user_id: userDetails.id,
    });
    setReviews((prev) => ({
      ...prev,
      has_rated: true,
      ratings: [response.rating, ...prev.ratings],
    }));
    mixpanel.track("User Rating on Session Content", {
      distinct_id: userDetails.email,
      "Course Session Content ID": props.session_content_id,
      Ratings: rating_value,
      Review: reviews,
    });
    setTimeout(() => {
      document
        .querySelector(`div[data-review-id='${response.rating.id}']`)
        .scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
    }, 1000);
  };

  const startPreviousSessionContent = () => {
    const newContentList = _.compact(
      _.flatMapDeep(course.modules, (e) =>
        e.sessions?.map((e1) => e1.session_content)
      )
    );
    const newCurrentIndex = _.findIndex(
      newContentList,
      (e) => e.id === currentPlayingState.currentSessionContentId
    );
    if (newCurrentIndex === 0) {
      console.log("You are at the beginning of the course.");
    } else {
      navigate(
        `/programs/${props.course_id}/learn/${
          newContentList[newCurrentIndex - 1].id
        }`,
        { replace: !isDesktop, state: { curriculumData: courseRef.current } }
      );
    }
  };

  const startNextSessionContent = () => {
    const newContentList = _.compact(
      _.flatMapDeep(course.modules, (e) =>
        e.sessions?.map((e1) => e1.session_content)
      )
    );
    const newCurrentIndex = _.findIndex(
      newContentList,
      (e) => e.id === currentPlayingState.currentSessionContentId
    );
    if (newCurrentIndex === _.size(newContentList) - 1) {
      if (
        course.modules.length > currentPlayingState.currentModuleIndex + 1 &&
        course.modules[currentPlayingState.currentModuleIndex + 1].is_locked
      ) {
        CustomToast.error(
          "Please renew your subscription to continue learning"
        );
        return;
      }
      console.log("You reached end of the course");
    } else {
      navigate(
        `/programs/${props.course_id}/learn/${
          newContentList[newCurrentIndex + 1].id
        }`,
        { replace: !isDesktop, state: { curriculumData: courseRef.current } }
      );
    }
  };

  const shouldAllowReview = () => {
    if (currentSessionContent.kind === "protool") return true;
    if (currentSessionContent.kind === "video") {
      if (currentSessionContent.external_link_details) {
        if (currentSessionContent?.external_link_details?.details?.start_time) {
          return (
            new Date(
              currentSessionContent?.external_link_details?.details?.start_time
            ) < new Date()
          );
        } else if (
          currentSessionContent?.external_link_details.status === "completed"
        ) {
          return true;
        } else return false;
      }
      return true;
    }
    if (currentSessionContent.kind === "quiz")
      return currentSessionContent?.progress?.status === "completed";
    return true;
  };

  const markAsCompleted = async () => {
    if (isMarkingCompleted) return;

    // setIsMarkingCompleted(true);
    await CourseService.updateCourseProgress({
      course_id: props.course_id,
      last_viewed_content: {
        course_session_content_id: props.session_content_id,
        last_seen_in_sec: 0,
      },
      progress: {
        course_content_id: `${parseInt(props.session_content_id)}`,
        value: { progress_in_sec: 0, status: "completed" },
      },
    });
    mutateCourseStats();
    setIsMarkingCompleted(false);
  };

  const tabList = [
    {
      heading: "Curriculum",
      hide: width > 1024,
      buttonId: "curriculum-btn",
      component: (
        <CurriculumAccordion
          courseDataRef={courseRef}
          curriculumData={course.modules ?? []}
          currentState={currentPlayingState}
          initialProgress={totalCompletedModules}
          courseName={course.name}
          entityType={course.course_type}
          entityId={course.entity_id}
          courseId={props.course_id}
          refreshCallback={async () => await fetchCourse()}
          ratingStats={ratings_stats ?? {}}
          courseCompletionPercentage={course.course_completion_percentage ?? 0}
          courseStats={course_stats ?? {}}
          courseSessionStats={course_session_stats ?? {}}
          className="block h-fit lg:px-4 lg:hidden"
          unlockCourse={
            course_lock_details.has_expired && course_lock_details.grace_period
          }
          isMarkingCompleted={isMarkingCompleted}
          markCurrentAsCompleted={markAsCompleted}
          sessionCountList={sessionCountList}
          hasCompletedUnlockedModule={
            // course.course_type === "qpfp"
            //   ? course_lock_details?.has_completed_unlock_module ?? false
            //   :
            true
          }
          availablePartPaymentOptions={
            course_lock_details.available_part_payment_option
          }
          hasMadeFullPayment={
            course.course_type === "qpfp"
              ? course_lock_details?.has_made_full_payment ?? false
              : !course_lock_details?.has_expired
          }
        />
      ),
    },
    // {
    //   heading: "Overview",
    //   component: (
    //     <OverviewTab
    //       courseTitle={course.name}
    //       programHighlightsData={course.highlights}
    //       courseDescription={course.description}
    //     />
    //   ),
    // },
    {
      heading: "Description",
      buttonId: "description-btn",
      hide: _.isEmpty(currentSessionContent?.description),
      component: (
        <OverviewTab
          courseTitle={currentSessionContent.title}
          programHighlightsData={[]}
          courseDescription={currentSessionContent?.description ?? ""}
        />
      ),
    },
    {
      heading: "Resources",
      component: (
        <ResourcesTab
          programResourcesData={resources}
          programUsefulLinksData={usefulLinks ?? []}
        />
      ),
    },
    {
      heading:
        "Questions (" +
        comments?.filter((e) => _.isEmpty(e.reply_of_comment)).length +
        ")",
      buttonId: "qna-btn",
      component: (
        <CommentsTab
          allowCreateComment={true}
          comments={comments}
          onPostComment={onPostComment}
        />
      ),
      hide:
        currentSessionContent.kind === "quiz" ||
        currentSessionContent.kind === "protool",
    },
    {
      heading: "Learnings (" + (reviews?.ratings?.length ?? 0) + ")",
      buttonId: "reviews-btn",
      component: (
        <ReviewsTab
          reviews={[...(reviews?.ratings ?? [])]}
          onPostReview={(props) => onPostReview(props)}
          shouldAllowReview={shouldAllowReview()}
          hasRatedBefore={reviews?.has_rated}
        />
      ),
      hide:
        currentSessionContent.kind === "quiz" ||
        currentSessionContent.kind === "protool",
    },
    {
      heading: "Quiz",
      buttonId: "quiz-btn",
      component: (
        <QuizTab
          quizCourseSessionContentList={(
            course?.modules?.[currentPlayingState.currentModuleIndex]?.sessions[
              currentPlayingState.currentSessionIndex
            ]?.session_content ?? []
          )
            .filter((e) => e.kind === "quiz")
            .map((e) => ({ ...e, progress: course_stats?.[e.id] ?? {} }))}
          courseId={props.course_id}
          courseDataRef={courseRef}
          currentState={currentPlayingState}
        />
      ),
    },
  ];

  if (isLoading) {
    return (
      <AppSkeleton
        hideSidebar={true}
        bgColor="bg-white"
        pageLabel="Course"
        showMobileHeader={true}
        secondaryHeading="Course"
      >
        <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
          <Loader />
        </div>
      </AppSkeleton>
    );
  }

  if (error)
    return (
      <AppSkeleton
        hideSidebar={true}
        bgColor="bg-white"
        pageLabel="Course"
        showMobileHeader={true}
        secondaryHeading="Course"
      >
        <ErrorScreen />
      </AppSkeleton>
    );

  const Header = () => {
    return (
      <div className="hidden space-y-3 lg:flex lg:flex-col">
        <div className="flex justify-between">
          <div className="flex flex-col text-2xl font-semibold tracking-tight text-theme-black-color">
            <span>{currentSessionContent.title ?? ""}</span>
            <span className="text-base tracking-tight text-theme-black-300">
              {course?.modules[currentPlayingState.currentModuleIndex]
                ?.sessions[currentPlayingState.currentSessionIndex].name ?? ""}
            </span>
          </div>
          <div className="flex items-center ml-3 space-x-6 h-fit">
            <div className="text-sm tracking-tight min-w-fit text-theme-black-color">
              SESSION &nbsp;
              {sessionCountList[currentPlayingState.currentModuleIndex || 0] ||
                0 + currentPlayingState.currentSessionIndex ||
                0 + 1}
              /{_.last(sessionCountList) ?? 0}
            </div>
            <div className="flex h-fit">
              <div
                className="py-1.5 px-2.5 cursor-pointer border border-r-0 border-gray-200 rounded-l-sm"
                onClick={startPreviousSessionContent}
              >
                <CaretLeft
                  size={24}
                  className="text-theme-black-color"
                  weight="fill"
                />
              </div>
              <div
                className="py-1.5 px-2.5  cursor-pointer border border-l-0 rounded-r-sm bg-theme-orange border-theme-orange"
                onClick={startNextSessionContent}
              >
                {<CaretRight size={24} className="text-white" weight="fill" />}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mt-1.5 lg:mt-0.5 ">
          <div className="flex items-center text-sm font-semibold leading-none text-theme-black-color justify-start gap-2.5">
            <div className="text-sm leading-none text-theme-black-color">
              {course.name}
            </div>
            <div className="w-1 h-1 rounded-full bg-theme-gray-400"></div>
            <div className="w-4 h-4 bg-red-700 rounded-full">
              <ImageComponent
                src={networkfp_logo}
                className="object-cover min-w-[16px] w-full h-full rounded-full"
              />
            </div>
            <span>Network FP</span>
          </div>
          {currentSessionContent.kind === "quiz" ||
          course.course_type === "qpfp" ? (
            <div></div>
          ) : (
            <button
              className="flex items-center px-2 py-1 text-base border rounded-md cursor-pointer text-theme-orange hover:bg-gray-100"
              disabled={
                isMarkingCompleted ||
                course_stats?.[currentSessionContent.id]?.status === "completed"
              }
              onClick={async () => await markAsCompleted()}
            >
              <span className="flex items-center">
                <Check size={20} className="mr-1" />
                {course_stats?.[currentSessionContent.id]?.status !==
                "completed"
                  ? "Mark as completed"
                  : "Completed"}
              </span>
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <AppSkeleton
      hideSidebar={true}
      bgColor={
        currentSessionContent.kind === "quiz" &&
        currentSessionContent?.progress?.status === "completed"
          ? ""
          : "bg-white"
      }
      pageLabel={course.name}
      breadCrumbData={breadCrumbData}
      showMobileHeader={true}
      secondaryHeading={
        currentSessionContent.kind === "quiz" &&
        currentSessionContent?.progress?.status === "completed" ? (
          "Quiz Result"
        ) : (
          <div className="">
            <span className="text-base font-bold tracking-tight line-clamp-1 text-theme-black-color">
              {currentSessionContent.title ?? ""}
            </span>
            <span className="text-sm font-bold tracking-tight line-clamp-1 text-theme-black-300">
              {course.modules?.[currentPlayingState.currentModuleIndex]
                ?.sessions[currentPlayingState.currentSessionIndex].name ?? ""}
            </span>
          </div>
        )
      }
    >
      <main
        className={classNames(
          "flex flex-col w-full h-full lg:bg-white",
          currentSessionContent.kind === "quiz" &&
            course_stats?.[currentSessionContent.id]?.status === "completed"
            ? "bg-theme-gray-200"
            : "bg-white"
        )}
      >
        <div className="relative flex px-0 lg:p-6 flex-col w-full overflow-hidden lg:h-full lg:max-w-[65%]">
          {currentSessionContent.kind === "quiz" &&
          course_stats?.[currentSessionContent.id]?.status === "completed" ? (
            <QuizSummary
              quizId={currentSessionContent.quiz_id}
              course_id={props.course_id}
              course_name={course.name}
              onClickShowCurriculum={() => setShowCurriculum(true)}
              session_content_id={currentSessionContent.id}
              startNextSessionContent={startNextSessionContent}
            />
          ) : (
            <React.Fragment>
              {course.course_type === "qpfp" && (
                <div className="lg:flex hidden sticky top-0 w-full h-[124px] gap-8 items-center">
                  <div className="flex gap-3 items-center">
                    <UserProfileAvatar
                      imageUrl={userDetails.profile_picture?.signed_url}
                      isLoggedInUser
                    />
                    <div className="flex flex-col gap-1">
                      <div className="font-medium leading-none line-clamp-1 whitespace-nowrap text-lg">
                        {userDetails.display_name}
                      </div>
                      <div className="text-xs leading-none text-theme-black-300">
                        {userDetails.city_name}
                      </div>
                    </div>
                  </div>
                  <QPFPModuleWiseProgressBar
                    modules={qpfpProgressTracker?.modules ?? []}
                    courseCompletionPercentage={
                      qpfpProgressTracker?.course_completion_percentage ?? 0
                    }
                    isFinalExamCompleted={
                      userDetails.qpfp_certification_status !== "inactive"
                    }
                  />
                </div>
              )}
              <Header />
              <SessionContentComponent
                courseName={course.name}
                course_id={props.course_id}
                session_content_id={props.session_content_id}
                currentPlayingState={currentPlayingState}
                startNextSessionContent={startNextSessionContent}
                currentSessionContent={{
                  ...currentSessionContent,
                  progress: course_stats?.[currentSessionContent.id] ?? {},
                  has_zoom_sesssion:
                    zoom_session_details?.[currentSessionContent.id] ?? false,
                }}
                mutateCourseStats={() => mutateCourseStats()}
                courseDataRef={courseRef}
                onPostReview={onPostReview}
                eventDetails={eventDetails}
                hasRatedBefore={reviews?.has_rated}
              />
            </React.Fragment>
          )}
          <div
            className={classNames(
              "border-l bg-white fixed z-10 px-6 right-0 w-full lg:max-w-[35%] bottom-0 overflow-y-scroll",
              showCurriculum ? "block " : "hidden lg:block",
              "top-[88px]"
            )}
          >
            <CurriculumAccordion
              courseDataRef={courseRef}
              curriculumData={course.modules ?? []}
              currentState={currentPlayingState}
              initialProgress={totalCompletedModules}
              courseName={course.name}
              entityType={course.course_type}
              courseCompletionPercentage={
                course.course_completion_percentage ?? 0
              }
              entityId={course.entity_id}
              courseId={props.course_id}
              refreshCallback={async () => await fetchCourse()}
              ratingStats={ratings_stats}
              courseStats={course_stats}
              courseSessionStats={course_session_stats ?? {}}
              className={classNames(
                "h-fit",
                showCurriculum ? "block" : "hidden lg:block"
              )}
              showCloseButton={
                currentSessionContent.kind === "quiz" &&
                course_stats?.[currentSessionContent.id]?.status === "completed"
              }
              onCloseShowButton={() => setShowCurriculum(false)}
              unlockCourse={
                course_lock_details.has_expired &&
                course_lock_details.grace_period
              }
              sessionCountList={sessionCountList}
              hasCompletedUnlockedModule={
                // course.course_type === "qpfp"
                //   ? course_lock_details?.has_completed_unlock_module ?? false
                //   :
                true
              }
              isMarkingCompleted={isMarkingCompleted}
              markCurrentAsCompleted={markAsCompleted}
              availablePartPaymentOptions={
                course_lock_details.available_part_payment_option
              }
              hasMadeFullPayment={
                course.course_type === "qpfp"
                  ? course_lock_details?.has_made_full_payment ?? false
                  : !course_lock_details?.has_expired
              }
            />
          </div>
          {!(
            currentSessionContent.kind === "quiz" &&
            course_stats?.[currentSessionContent.id]?.status === "completed"
          ) && (
            <div className="px-4 mb-5 lg:px-0">
              <Tabs tabList={tabList} stickyTabList={true} />
            </div>
          )}
        </div>
        {showQuizIntroPopup && (
          <QuizIntroModal
            open={showQuizIntroPopup}
            quiz_id={currentSessionContent.quiz_id}
            dismissButtonOnClick={() => setShowQuizIntroPopup(false)}
            secondaryOnClick={() => setShowQuizIntroPopup(false)}
            primaryOnClick={async () => {
              navigate(
                `/programs/${props.course_id}/quiz/${currentSessionContent.quiz_id}/start`,
                { state: { course_name: course.name } }
              );
            }}
          />
        )}
      </main>
    </AppSkeleton>
  );
};

ViewCourse.propTypes = {
  location: PropTypes.object,
  course_id: PropTypes.string,
  session_content_id: PropTypes.string,
};

export default ViewCourse;
