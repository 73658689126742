import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import { useField } from "formik";
import { Check } from "@phosphor-icons/react";
import ShowFormError from "./ShowFormError";

const CheckBox = ({
  inputLabel,
  labelClassName,
  errorMessage,
  disabled = false,
  errorColor,
  className,
  isCustom = true,
  ...props
}) => {
  const [field] = useField(props);
  return (
    <div className={className}>
      <label
        htmlFor={props.name}
        className={classNames(
          "flex items-center text-sm leading-4 text-theme-black-300",
          labelClassName
        )}
      >
        <div
          className={classNames(
            "flex items-center justify-center border-[1.5px] w-4 h-4 rounded-sm ",
            field.value
              ? isCustom
                ? "bg-theme-red/10 border-theme-red focus:ring-theme-red"
                : "bg-theme-blue-200 border-theme-blue-200 focus:ring-theme-blue-200"
              : "bg-white focus:ring-gray-900 border-theme-gray-400",
            inputLabel && "mr-2",
            disabled && "opacity-40 bg-theme-gray-400"
          )}
        >
          <Check
            weight="bold"
            className={classNames(
              "transition-all ",
              field.value ? "w-3 h-3" : "w-0 h-0",
              isCustom ? "text-theme-red-300" : "text-white"
            )}
          />
        </div>
        <input
          id={props.name}
          name={props.name}
          type="checkbox"
          checked={field.value}
          disabled={disabled}
          className="hidden w-4 h-4 text-gray-900 bg-white rounded-sm focus:ring-gray-900 border-theme-gray"
          {...field}
          {...props}
        />
        {inputLabel && <span className={labelClassName}> {inputLabel}</span>}
      </label>
      <ShowFormError name={props.name} />
    </div>
  );
};

CheckBox.defaultProps = {
  inputLabel: "",
  labelClassName: "text-gray-900",
  errorMessage: "",
  errorColor: "text-red-600",
  isCustom: true,
};

CheckBox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorColor: PropTypes.string,
  errorMessage: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  isCustom: PropTypes.bool,
};
export default CheckBox;
