import PropTypes from "prop-types";
import CommunityAndForum from "../CommonComponents/CommunityAndForum";
import YourRelationManager from "../CommonComponents/YourRelationManager";

const CommunityAndRelations = ({ name, email, phone, whatsAppPhone, img }) => {
  return (
    <div
      className={`flex items-center justify-center w-full py-10 px-5 bg-theme-gray-25`}
    >
      <div className="flex max-w-7xl w-full">
        <div className="grid items-center justify-center md:grid-cols-2 md:items-stretch w-full gap-4">
          <CommunityAndForum />
          {name && (
            <YourRelationManager
              name={name}
              email={email}
              phone={phone}
              whatsAppLink={whatsAppPhone}
              profileImg={img}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CommunityAndRelations.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  whatsAppPhone: PropTypes.string,
  img: PropTypes.string,
};
export default CommunityAndRelations;
