import { useProfile } from "../../context/userProvider";
import ProfileDetailsCard from "./ProfileDetailsCard";
import banner from "../../assets/images/dashboard-hero-banner.png";
const HeroSection = () => {
  const { userDetails } = useProfile();
  return (
    <div className="dashboard-banner-gradience relative md:min-h-[562px] bg-gradient-to-r flex md:items-center md:justify-center w-full mb-80 md:mb-0">
      <img
        src={banner}
        alt="Hero Banner"
        className="hidden md:block absolute top-0 left-0 w-full h-full object-cover -z-10"
      />
      <div className="relative max-w-7xl w-full flex flex-col md:flex-row items-center justify-center lg:justify-between gap-5 xl:gap-28 p-4 lg:p-16">
        <div className="flex flex-col items-start justify-center text-white gap-3 sm:gap-5 w-full md:max-w-lg pb-10">
          <p className="font-bold text-[28px] sm:text-4xl">
            Welcome {userDetails?.first_name ?? "NFP User"}
          </p>
          <p className="sm:text-xl font-medium">
            {userDetails?.is_pro_member
              ? "Welcome to your NFP ProMembership Dashboard. You can access all your membership benefits, resources and courses here."
              : "Welcome to your NFP Basic Account Dashboard. You can access all your account benefits, resources and courses here."}
          </p>
          <p className="text-xs sm:text-sm">
            Gain competence and credibility to grow as a Personal Finance
            Professional (PFP)
          </p>
        </div>
        <ProfileDetailsCard />
      </div>
    </div>
  );
};
export default HeroSection;
