import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Redirect } from "@reach/router";
import { User } from "../../store";

const PublicRoute = ({ component: Component, ...restOfProps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    const token = await User.getToken();
    setToken(token);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? null : !token ? (
        <Component {...restOfProps} />
      ) : (
        <Redirect to="/?guide_user=true" noThrow />
      )}
    </>
  );
};

PublicRoute.propTypes = {
  component: PropTypes.any,
};

export default PublicRoute;
