import searchIcon from "../../assets/svgs/no-result-search-icon.svg";
import PropTypes from "prop-types";

const NoDataFound = ({ title, subTitle, icon }) => {
  return (
    <div className="w-full min-h-[450px] h-full flex flex-col items-center justify-center gap-1">
      <img src={icon ?? searchIcon} alt="search-icon" className="w-24 h-24" />
      <p className="text-3xl font-bold mt-7">
        {title ?? "No matching results found"}
      </p>
      <p className="text-2xl text-theme-black-500">
        {subTitle ?? "Please try applying different filters"}
      </p>
    </div>
  );
};

NoDataFound.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
};

export default NoDataFound;
