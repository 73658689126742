import { useNavigate } from "@reach/router";
import React from "react";
import Joyride from "react-joyride";
import { useTourContext } from "../context/tourProvider";
import _ from "lodash";

export const tourSteps = [
  {
    target: "#tour",
    content: (
      <>
        <div className="text-lg font-bold">
          Welcome to Dashboard of your learn account
        </div>
        <div>Edit and update your Network FP Profile from here</div>
      </>
    ),
    placement: "top",
    disableBeacon: true,
  },
  {
    target: "#live-courses",
    content: (
      <>
        <div className="text-lg font-bold">Live Courses</div>
        <div className="sm:min-w-96">
          Explore our newly released courses on the platform{" "}
        </div>
      </>
    ),
    placement: "top",
    disableBeacon: true,
  },
  {
    target: "#my-programs",
    content: (
      <>
        <div className="text-lg font-bold">My Courses</div>
        <div>
          Please click on the “Start Course” button to unlock and begin the
          course.
        </div>
      </>
    ),
    placement: "top",
    disableBeacon: true,
  },
  {
    target: "#all-programs",
    content: (
      <>
        <div className="text-lg font-bold">All Courses</div>
        <div>
          Explore a diverse range of courses designed to empower your learning
          and growth
        </div>
      </>
    ),
    placement: "top",
    disableBeacon: true,
  },
  {
    target: "#nfp-toolbox",
    content: (
      <>
        <div className="text-lg font-bold">NFP Toolbox</div>
        <div>
          Download & Access ready-to-use ProTools (Excel Templates), Resources,
          and calculators to simplify and elevate your practice.
        </div>
      </>
    ),
    placement: "top",
    disableBeacon: true,
  },
];

const Tour = () => {
  const navigate = useNavigate();
  const {
    setState,
    state: { run, stepIndex, tourActive },
  } = useTourContext();

  const handleCallback = (callbackData) => {
    const { action, index, step, type } = callbackData;
    const isPreviousAction = action === "prev";
    const { data = {} } = step;
    const { next, previous } = data;

    if (action === "close" || action === "skip") {
      setState({ run: false, stepIndex: 0, tourActive: false });
      navigate("/", { replace: true });
      return;
    }

    if (type === "step:after") {
      if (index === 0 && isPreviousAction) {
        setState({ run: false, stepIndex: 0, tourActive: false });
        return;
      }

      if (index < tourSteps.length - 1) {
        setState((prev) => ({
          ...prev,
          stepIndex: isPreviousAction ? index - 1 : index + 1,
        }));
        if (!_.isEmpty(isPreviousAction ? previous : next)) {
          setState((prev) => ({
            ...prev,
            run: false,
          }));
          navigate(isPreviousAction ? previous : next);
        }
        return;
      }
      if (index === tourSteps.length - 1 && !isPreviousAction) {
        setState({ run: false, stepIndex: 0, tourActive: false });
        navigate("/", { replace: true });
        return;
      }
      if (index === tourSteps.length - 1 && isPreviousAction) {
        setState((prev) => ({
          ...prev,
          stepIndex: isPreviousAction ? index - 1 : index + 1,
        }));
        return;
      }
    }
  };
  return (
    <Joyride
      callback={handleCallback}
      continuous
      run={run && tourActive}
      stepIndex={stepIndex}
      steps={tourSteps}
      hideCloseButton
      showSkipButton
      locale={{ last: "Close", skip: "Skip Tour" }}
      scrollOffset={100}
      disableCloseOnEsc
      disableOverlayClose
      styles={{
        options: {
          arrowColor: "#FFF",
          backgroundColor: "#FFF",
          beaconSize: 36,
          overlayColor: "rgba(0, 0, 0, 0.5)",
          primaryColor: "#E10000",
          spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
          textColor: "#141414",
          width: undefined,
          zIndex: 100,
        },
      }}
    />
  );
};

export default Tour;
