import { useAPI } from "./SWRService";
import ApiConfig from "../api/config";
import _ from "lodash";

export const useMyCourses = (config = {}, page, perPage) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.fetch_courses + `?page=${page}&per_page=${perPage}`,
    config
  );
  return {
    courses: data?.courses || [],
    pagination: data?.pagination ?? {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useCoursesComingSoon = (config = {}, page, perPage) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.fetch_courses_coming_soon +
      `?page=${page}&per_page=${perPage}`,
    config
  );
  return {
    courses: data?.courses || [],
    pagination: data?.pagination ?? {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useAllCourses = (config = {}, filters = {}, page, perPage) => {
  // Build the query string
  const queryParams = new URLSearchParams();

  // Add pagination parameters
  queryParams.append("page", page);
  queryParams.append("per_page", perPage);

  // Add filter parameters
  Object.entries(filters).forEach(([key, value]) => {
    if (
      Array.isArray(value) &&
      value.length > 0 &&
      value[0] !== "" &&
      key !== "search"
    ) {
      // Append each value individually for keys with multiple values
      value.forEach((item) => queryParams.append(`${key}[]`, item));
    } else if (
      value !== "" &&
      value !== null &&
      value !== undefined &&
      !(Array.isArray(value) && value.length === 0)
    ) {
      queryParams.append(key, value);
    }
  });

  const { data, error, loading, mutate, isValidating } = useAPI(
    `${ApiConfig.urls.courses.fetch_all_courses}?${queryParams.toString()}`,
    config
  );

  return {
    courses: data?.courses || [],
    pagination: data?.pagination ?? {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useAllCoursesFilters = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.all_course_filters,
    config
  );

  return {
    filters: data?.values || [],
    pagination: data?.pagination ?? {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useCourseCurriculum = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null && course_id !== undefined
      ? ApiConfig.urls.courses.course_details(course_id)
      : null,
    config
  );
  return {
    curriculum: data?.course || {},
    eventDetails: data?.event_details || {},
    lastViewContentDetails: data?.last_viewed_content,
    error,
    loading: course_id === null || course_id === undefined ? false : loading,
    mutate,
    isValidating,
  };
};

export const useVisitorCourseCurriculum = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null && course_id !== undefined
      ? ApiConfig.urls.courses.visitor_course_details(course_id)
      : null,
    config
  );
  return {
    curriculum: data?.course || {},
    courseFees: data?.course_fees,
    eventDetails: data?.event_details || {},
    lastViewContentDetails: data?.last_viewed_content,
    hasStarted: data?.has_started,
    hasRegistered: data?.has_registered,
    hasReviewed: data?.has_rated,
    ratingCount: data?.rating_count,
    error,
    loading: course_id === null || course_id === undefined ? false : loading,
    mutate,
    isValidating,
  };
};

export const useAcePrograms = (config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.ace_programs,
    config
  );
  return {
    programs: data?.ace_programs ?? [],
    allowRegistration: data?.allow_registration,
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useCourseLockDetail = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null
      ? ApiConfig.urls.courses.course_lock_details(course_id)
      : null,
    config
  );
  return {
    course_lock_details: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useRatingsStats = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null ? ApiConfig.urls.courses.rating_stats(course_id) : null,
    config
  );
  return {
    ratings_stats: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useMyCoursestats = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null
      ? ApiConfig.urls.courses.course_content_stats(course_id)
      : null,
    config
  );
  let newData = {};
  if (_.isEmpty(data)) {
    newData = {};
  } else {
    newData = _.mapKeys(data?.data?.progress ?? {}, (__, key) => {
      return key.replace("_", "");
    });
  }

  return {
    course_stats: newData,
    zoom_session_details: _.isEmpty(data) ? {} : data.zoom_session_detail,
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useMyCoursesessionStats = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_id !== null
      ? ApiConfig.urls.courses.course_session_stats(course_id)
      : null,
    config
  );
  return {
    course_session_stats: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useSessionContentReview = (course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    ApiConfig.urls.courses.curriculum(course_id),
    config
  );
  return {
    curriculum: data?.course || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useQPFPQuizTracker = (course_type, course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_type === "qpfp"
      ? ApiConfig.urls.courses.qpfp_quiz_tracker(course_id)
      : null,
    config
  );

  return {
    qpfpQuizTracker: data?.modules || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};
export const useQPFPProgressTracker = (course_type, course_id, config = {}) => {
  const { data, error, loading, mutate, isValidating } = useAPI(
    course_type === "qpfp" && _.isInteger(_.parseInt(course_id))
      ? ApiConfig.urls.courses.qpfp_progress_tracker(course_id)
      : null,
    config
  );

  return {
    qpfpProgressTracker: data || {},
    error,
    loading,
    mutate,
    isValidating,
  };
};
