import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "./Button";
import { useWindowSize } from "../hooks/useWindowSize";
import {
  CaretDown,
  CaretUp,
  CircleNotch,
  DotsThreeVertical,
  IdentificationCard,
  PencilSimpleLine,
} from "@phosphor-icons/react";
import { useProfile } from "../context/userProvider";
import PromemberMessage from "../components/PublicProfile/PromemberMessage";

const PublicProfileCard = ({
  heading,
  subHeading,
  trailingActions = [],
  promemberStatus,
  submitButtonRef,
  cancelButtonRef,
  isProMemberCard = false,
  promemberValidationMessage = "",
  isEditing,
  setIsEditing,
  isEmpty,
  lockedIfCorporateAccount = false,
  isLoading = false,
  cardBody,
  id = "",
}) => {
  const [open, setOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useWindowSize();
  const isDesktop = width >= 1024;
  const { userDetails } = useProfile();
  const isProMember = true; // Updating value to true from ""userDetails.is_pro_member"" to allow users to add education details
  const isCorporateAccount = userDetails.is_corporate_account;

  const PublicProfileCardHeader = () => {
    return (
      <div
        className={classNames(
          "flex w-full flex-row items-start justify-between"
        )}
      >
        <div className={classNames("flex flex-col flex-grow items-start")}>
          <div
            className={classNames(
              "flex text-lg leading-5 lg:text-xl lg:leading-6 text-left text-theme-black-color font-bold"
            )}
          >
            {heading}
          </div>{" "}
          <div
            className={classNames(
              "flex text-xs lg:text-sm leading-5 text-left text-theme-black-300 tracking-tight "
            )}
          >
            {subHeading}
          </div>
        </div>
        {!isEditing ? (
          <div className="relative flex items-center rounded-lg w-fit h-fit">
            {trailingActions.length === 0 ? (
              !isEmpty && (
                <>
                  <PencilSimpleLine
                    className="hidden cursor-pointer lg:flex text-theme-red-300 "
                    onClick={() => setIsEditing(true)}
                    size={24}
                    weight="fill"
                  />
                  <Button
                    className={`${
                      isExpanded ? "flex" : "hidden"
                    } lg:hidden mr-1 lg:mr-0 `}
                    buttonStyle="transparent-textonly-primary"
                    onClick={() => setIsEditing(true)}
                  >
                    Edit
                  </Button>
                </>
              )
            ) : (
              <>
                <DotsThreeVertical
                  onClick={() => {
                    setOpen((value) => !value);
                  }}
                  className="w-6 h-6 mr-3 lg:mr-0"
                />

                {open ? (
                  <div className="absolute top-0 flex flex-col border rounded-lg shadow-sm w-max right-full border-theme-gray-300">
                    {trailingActions.map((action, index) => {
                      return (
                        <div key={index} className="flex flex-col w-full">
                          <div
                            className={classNames(
                              "cursor-default flex items-center w-full py-2 px-4 text-sm text-theme-black-color leading-5 tracking-tight  hover:bg-theme-gray-300",
                              index === trailingActions.length - 1
                                ? ""
                                : "border-b border-theme-gray-300",
                              index === 0 ? "rounded-t-lg" : "",
                              index === trailingActions.length - 1
                                ? "rounded-b-lg"
                                : ""
                            )}
                          >
                            {action["label"] ?? ""}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {isExpanded ? (
              <CaretUp
                onClick={() => {
                  setIsExpanded((value) => !value);
                }}
                className="z-10 block w-6 h-6 lg:hidden"
                weight="fill"
              />
            ) : (
              <CaretDown
                onClick={() => {
                  setIsExpanded((value) => !value);
                }}
                className="z-10 block w-6 h-6 lg:hidden"
                weight="fill"
              />
            )}
          </div>
        ) : (
          <div className="flex-row items-end hidden lg:flex h-fit">
            <CancelButton isDesktop={true} />
            <SaveButton isDesktop={true} />
          </div>
        )}
      </div>
    );
  };

  const CancelButton = ({ isDesktop }) => {
    return (
      <Button
        className={isDesktop ? "p-0 ml-3 font-semibold" : ""}
        width="50%"
        size="no-size"
        height={!isDesktop ? "40px" : null}
        buttonStyle={isDesktop ? "transparent-textonly-secondary" : "secondary"}
        onClick={() => {
          setIsEditing(false);
          cancelButtonRef.current.click();
        }}
      >
        <span className="text-sm">CANCEL</span>
      </Button>
    );
  };
  CancelButton.propTypes = { isDesktop: PropTypes.bool };

  const SaveButton = ({ isDesktop }) => {
    return (
      <Button
        className={isDesktop ? "p-0 ml-3 font-semibold" : ""}
        width="50%"
        size="no-size"
        height={!isDesktop ? "40px" : null}
        buttonStyle={isDesktop ? "transparent-textonly-primary" : "primary"}
        onClick={async () => {
          try {
            await submitButtonRef.current.click();
          } catch (e) {
            console.log("error while subitting form", e);
          }
        }}
      >
        <span className="text-sm">SAVE</span>
      </Button>
    );
  };
  SaveButton.propTypes = { isDesktop: PropTypes.bool };

  const PublicProfileCardBody = ({ className }) => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center mt-5">
          <CircleNotch
            className="mx-auto animate-spin"
            size={20}
            weight="fill"
          />
        </div>
      );

    if (isDesktop)
      return (
        <div
          className={classNames(
            "hidden lg:flex w-full opacity-100 max-h-[10000px] transition-all duration-700",
            className
          )}
        >
          {cardBody}
        </div>
      );
    return (
      <div
        className={classNames(
          "flex lg:hidden w-full accordion-content transition-all duration-700",
          isExpanded || isEditing ? "expanded" : "collapsed",
          className
        )}
      >
        {(isExpanded || isEditing) && cardBody}
      </div>
    );
  };
  PublicProfileCardBody.propTypes = { className: PropTypes.string };

  const PublicProfileMobileFooter = () => {
    if (isEditing) {
      return (
        <div className="flex justify-between mt-3 space-x-4 lg:hidden">
          <CancelButton isDesktop={false} />
          <SaveButton isDesktop={false} />
        </div>
      );
    }
    return null;
  };

  return (
    <div
      id={id}
      className={classNames(
        "relative rounded-sm group shadow-sm",
        isProMemberCard && !isProMember
          ? isDesktop
            ? "bg-white lg:hover:!bg-gray-400 lg:bg-opacity-100 lg:overflow-hidden lg:hover:!bg-opacity-50"
            : isExpanded
            ? "!bg-gray-400 overflow-hidden !bg-opacity-50"
            : "bg-white"
          : "bg-white"
      )}
      onClick={() => {
        if (!isDesktop && !isExpanded) {
          setIsExpanded(true);
        }
      }}
    >
      <div
        className={classNames(
          "w-full relative flex flex-col transition-all duration-700",
          isEditing ? "p-6 lg:p-6" : `pt-6 px-6 ${!isExpanded && "pb-6"} lg:p-6`
        )}
      >
        <PublicProfileCardHeader />
        <PublicProfileCardBody
          className={
            isProMemberCard && !isProMember
              ? isDesktop
                ? "lg:group-hover:blur-sm"
                : isExpanded
                ? "blur-sm"
                : ""
              : ""
          }
        />
        {isProMemberCard &&
          !isProMember &&
          ((!isDesktop && isExpanded) || isDesktop) && (
            <div
              className={classNames(
                "absolute z-10 items-center justify-center hidden w-[calc(100%-48px)] h-full overflow-hidden lg:group-hover:flex",
                !isDesktop && isExpanded && "!flex"
              )}
            >
              <PromemberMessage
                message={promemberValidationMessage}
                className="h-fit w-fit"
              />
            </div>
          )}
        {lockedIfCorporateAccount && isCorporateAccount && (
          <div
            className={classNames(
              "absolute z-10 items-center justify-center hidden w-[calc(100%-48px)] h-full overflow-hidden lg:group-hover:flex",
              !isDesktop && isExpanded && "!flex"
            )}
          >
            <PromemberMessage
              message={promemberValidationMessage}
              className="h-fit w-fit"
              imageComponent={
                <div className="w-8 h-8 flex justify-center items-center min-w-[32px] rounded-full bg-theme-red">
                  <IdentificationCard className="w-4 h-4 text-white menu-icon" />
                </div>
              }
            />
          </div>
        )}
        <PublicProfileMobileFooter />
      </div>
      {isExpanded && !isEditing && (
        <div className="flex flex-col w-full mt-4 ">
          <div className="flex w-full h-[1px] my-1 bg-theme-gray-300"></div>
          <div
            onClick={() => setIsExpanded((value) => !value)}
            className="flex flex-col items-center justify-center w-full py-1 mb-1 text-sm leading-4 font-semi-bold text-theme-black-color"
          >
            HIDE DETAILS
          </div>
        </div>
      )}
    </div>
  );
};

PublicProfileCard.propTypes = {
  cancelButtonRef: PropTypes.object,
  cardBody: PropTypes.element,
  heading: PropTypes.string,
  id: PropTypes.string,
  isEditing: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isProMemberCard: PropTypes.bool,
  lockedIfCorporateAccount: PropTypes.bool,
  promemberStatus: PropTypes.string,
  promemberValidationMessage: PropTypes.string,
  setIsEditing: PropTypes.func,
  subHeading: PropTypes.string,
  submitButtonRef: PropTypes.object,
  trailingActions: PropTypes.array,
};

export default PublicProfileCard;
