import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";

import React from "react";

const Card = ({
  className,
  header,
  cta,
  cardType,
  cardBody,
  backgroundColor,
  hintBelowCard = false,
  ...props
}) => {
  return (
    <>
      <div
        className={classNames(
          "flex flex-col w-full items-start rounded-sm p-3",
          cardType === "outline" && "border !bg-white border-theme-gray-300",
          className
        )}
      >
        {header}
        {cardBody}
        <div className="flex flex-col justify-end flex-grow w-full">{cta}</div>
      </div>
      {hintBelowCard && (
        <div className="mt-1 text-xs font-normal text-theme-gray-500">
          {hintBelowCard}
        </div>
      )}
    </>
  );
};

Card.defaultProps = {
  buttonStyle: "primary",
  onButtonClick: () => {},
  cardType: "filled",
};

Card.propTypes = {
  backgroundColor: PropTypes.string,
  buttonStyle: PropTypes.string,
  cardBody: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cardType: PropTypes.oneOf(["filled", "outline"]).isRequired,
  className: PropTypes.string,
  cta: PropTypes.element,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  hintBelowCard: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onButtonClick: PropTypes.func,
};
export default Card;
